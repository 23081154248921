import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { blue, grey } from "@mui/material/colors";
import { formatCnpj } from "../utils/tools";
import { formatCEP } from "./UpdateInvestor";
import { IoIosDownload } from "react-icons/io";

const useStyles = makeStyles({
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
    color: grey[500],
  },
  detailText: {
    marginBottom: 10,
  },
  modalTitle: {
    fontWeight: "bold",
    color: blue[600],
    textAlign: "center",
    padding: "16px 24px",
    position: "relative",
  },
  modalContent: {
    padding: 24,
  },
  button: {
    marginTop: 20,
    backgroundColor: blue[600],
    color: "#fff",
    "&:hover": {
      backgroundColor: blue[700],
    },
  },
});

function InvestidorModal({ selectedInvestidor, handleSave, onClose }) {
  console.log(selectedInvestidor, "ertyuiop[");
  const classes = useStyles();
  let isLinkPresente = "";
  let isLinkPresente2 = "";
  // console.log(selectedEmissor,'selectedEmissor')
  if (selectedInvestidor && selectedInvestidor.realFaturamento) {
    isLinkPresente = selectedInvestidor.realFaturamento !== undefined;
  }
  if (selectedInvestidor && selectedInvestidor.conSocial) {
    isLinkPresente2 = selectedInvestidor.realFaturamento !== undefined;
  }
  return (
    <Dialog
      open={!!selectedInvestidor}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle className={classes.modalTitle}>
        Detalhes do Investidor Selecionado
        <Button onClick={onClose} className={classes.closeButton}>
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent dividers className={classes.modalContent}>
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.detailText}>
            <Typography variant="subtitle1">Razão Social:</Typography>
            <Typography variant="body1">
              {selectedInvestidor?.razaoSocial}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.detailText}>
            <Typography variant="subtitle1">CNPJ:</Typography>
            <Typography variant="body1">
              {formatCnpj(selectedInvestidor?.cnpj)}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.detailText}>
            <Typography variant="subtitle1">Representantes:</Typography>
            {selectedInvestidor?.representantes?.map((representante) => (
              <Typography variant="body1">
                {representante?.nome} {representante?.email}
              </Typography>
            ))}
          </Grid>
          <Grid item xs={12} className={classes.detailText}>
            <Typography variant="subtitle1">Endereço:</Typography>
            <Box>
              <Typography variant="body1" component="span">
                CEP: {formatCEP(selectedInvestidor?.cep)}
                <br />
              </Typography>
              <Typography variant="body1" component="span">
                Rua: {selectedInvestidor?.rua}
                <br />
              </Typography>
              <Typography variant="body1" component="span">
                Bairro: {selectedInvestidor?.bairro}
                <br />
              </Typography>
              <Typography variant="body1" component="span">
                Cidade: {selectedInvestidor?.cidade}
                <br />
              </Typography>
              <Typography variant="body1" component="span">
                UF: {selectedInvestidor?.estado}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <div className="investidorField">
              <a
                href={isLinkPresente ? selectedInvestidor.realFaturamento : "#"}
                className={`customFileInput2 ${
                  isLinkPresente ? "" : "opacoButton"
                }`}
                download={isLinkPresente ? "NomeDoArquivoParaDownload" : ""}
              >
                <IoIosDownload
                  style={{ marginLeft: "20px" }}
                  className="downloadIcon"
                />
                <span className="downloadText">
                  {`Baixar Relatório de Faturamento`}{" "}
                </span>
              </a>
            </div>
            <div className="investidorField">
              <a
                href={isLinkPresente2 ? selectedInvestidor.conSocial : "#"}
                className={`customFileInput2 ${
                  isLinkPresente2 ? "" : "opacoButton"
                }`}
              >
                <IoIosDownload
                  style={{ marginLeft: "20px" }}
                  className="downloadIcon"
                />
                <span className="downloadText">
                  {`Baixar Contrato Social`}{" "}
                </span>
              </a>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <Box textAlign="center" mb={2}>
        <Button
          variant="contained"
          className={classes.button}
          onClick={() => handleSave(selectedInvestidor)}
        >
          Selecionar Investidor para Nota Comercial
        </Button>
      </Box>
    </Dialog>
  );
}

export default InvestidorModal;
