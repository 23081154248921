import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Investidores from "./Investidores";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Grid,
  Input,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { formatCEP } from "../Modals/UpdateInvestor";
import { BsArrowLeft } from "react-icons/bs";
import { formatCnpj, formatFileSize } from "../utils/tools";
import { IoIosCloudUpload } from "react-icons/io";

export const formatBankAccount = (text) => {
  if (text) {
    const accountNumber = text.replace(/[^0-9a-zA-Z]/g, "").toUpperCase();

    if (accountNumber.length <= 1) {
      return accountNumber;
    }

    const lastCharacter = accountNumber.slice(-1);
    const otherCharacters = accountNumber.slice(0, -1);

    const formattedAccount =
      otherCharacters.replace(/(.{1,4})(?=(.{3})+(?!.))/g, "$1 ") +
      "-" +
      lastCharacter;

    return formattedAccount;
  }
};

export const formatarTelefoneGeral = (input) => {
  if (!input) return "";

  const cleanedValue = input.replace(/\D/g, "");
  let formattedNumber = "";

  if (cleanedValue.length === 11) {
    formattedNumber = cleanedValue.replace(
      /(\d{2})(\d{5})(\d{4})/,
      "($1) $2-$3"
    );
  } else if (cleanedValue.length === 10) {
    formattedNumber = cleanedValue.replace(
      /(\d{2})(\d{4})(\d{4})/,
      "($1) $2-$3"
    );
  } else {
    formattedNumber = cleanedValue; // No formatting for other lengths
  }

  return formattedNumber;
};

export const formatarTelefone = (input) => {
  // Remove todos os caracteres não numéricos do input
  if (input) {
    const numeros = input.replace(/\D/g, "");

    // Aplica a máscara (xx) xxxxx-xxxx enquanto o usuário digita
    let telefoneFormatado = "";
    for (let i = 0; i < numeros.length; i++) {
      if (i === 0) {
        telefoneFormatado = "(";
      } else if (i === 2) {
        telefoneFormatado += ") ";
      } else if (i === 7) {
        telefoneFormatado += "-";
      }
      telefoneFormatado += numeros[i];
    }

    return telefoneFormatado;
  }
};
const Investidor = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const [available, setAvailable] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [razaoSocial, setRazaoSocial] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [credorNumero, setCredorNumero] = useState("");
  const [credorRua, setCredorRua] = useState("");
  const [credorCidade, setCredorCidade] = useState("");
  const [credorBairro, setCredorBairro] = useState("");
  const [credorEstado, setCredorEstado] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [cep, setCep] = useState("");
  const [dadosBancarios, setDadosBancarios] = useState("");
  const [conta, setConta] = useState("");
  const [agency, setAgency] = useState("");
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState("");
  const [bankNumber, setBankNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [credorComplemento, setCredorComplemento] = useState("");
  const [tipoDeInstituicao, setTipoDeInstituicao] = useState("");
  const [investidores, setInvestidores] = useState(false);
  const [representantes, setRepresentantes] = useState([
    { nome: "", email: "" },
  ]);
  const [escriturador, setEscriturador] = useState({
    nome: "",
    cnpj: "",
  });
  const [depositaria, setDepositaria] = useState({
    nome: "",
    cnpj: "",
  });
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [successMessage, setSuccessMessage] = useState(null);
  const [realFaturamento, setRealFaturamento] = useState("");
  const [conSocial, setConSocial] = useState("");

  const toggleInvestidores = () => {
    setInvestidores(!investidores);
  };

  const validateInputs = () => {
    if (!razaoSocial || razaoSocial === "") {
      alert("Por favor, insira uma Razão Social válida.");
      return false;
    }

    if (!cnpj || cnpj.replace(/\D/g, "").length !== 14) {
      alert("Por favor, insira um CNPJ válido.");
      return false;
    }

    if (!cep || cep.replace(/\D/g, "").length !== 8) {
      alert("Por favor, insira um CEP válido.");
      return false;
    }

    if (!credorRua || credorRua.trim() === "") {
      alert("Por favor, insira uma Rua válida.");
      return false;
    }

    if (!credorNumero) {
      alert("Por favor, insira um Número válido.");
      return false;
    }

    if (!credorBairro) {
      alert("Por favor, insira um Bairro válido.");
      return false;
    }

    if (!credorCidade) {
      alert("Por favor, insira uma Cidade válida.");
      return false;
    }

    if (!credorEstado) {
      alert("Por favor, insira um Estado válido.");
      return false;
    }

    if (!representantes || representantes.length === 0) {
      alert("Por favor, insira um Nome de Usuário válido.");
      return false;
    }

    if (!telefone) {
      alert("Por favor, insira um Número de Telefone válido.");
      return false;
    }

    if (!selectedBank) {
      alert("Por favor, selecione um Banco.");
      return false;
    }

    if (!bankNumber) {
      alert("Por favor, insira um Número de Banco válido.");
      return false;
    }

    if (!tipoDeInstituicao) {
      alert("Por favor, selecione um tipo de instituição.");
      return false;
    }

    if (!agency) {
      alert("Por favor, insira uma Agência válida.");
      return false;
    }

    if (!conta) {
      alert("Por favor, insira um Número de Conta válido.");
      return false;
    }

    if (!escriturador.nome) {
      alert("Por favor, insira um nome de escriturador válido.");
      return false;
    }

    if (
      !escriturador.cnpj ||
      escriturador.cnpj.replace(/\D/g, "").length !== 14
    ) {
      alert("Por favor, insira um CNPJ válido no escriturador.");
      return false;
    }

    if (depositaria.cnpj && depositaria.cnpj.replace(/\D/g, "").length !== 14) {
      alert("Por favor, insira um CNPJ válido na depositária.");
      return false;
    }

    return true; // Todos os campos estão válidos
  };

  const handleSearch = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
      console.log("====================================");
      console.log(response.data, "response.data");
      console.log("====================================");
      setCredorComplemento(response.data.complemento);
      setCredorCidade(response.data.localidade);
      setCredorBairro(response.data.bairro);
      setCredorEstado(response.data.uf);
      setCredorRua(response.data.logradouro);
    } catch (error) {
      console.error("Error fetching address:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    // Esta função será executada após cada renderização do componente
    // Role a página para o topo
    window.scrollTo(0, 0);
  }, []);

  const keywordsToPrioritize = [
    "caixa eco",
    "itaú uni",
    "bco do brasil",
    "bradesco s",
    "santander",
    "original",
    "banco inter",
    "bdigital",
  ];

  const sortedBanks = [...banks].sort((a, b) => {
    const bankNameA = a.name ? a.name.toLowerCase() : "";
    const bankNameB = b.name ? b.name.toLowerCase() : "";

    const priorityA = getPriority(bankNameA);
    const priorityB = getPriority(bankNameB);

    if (priorityA !== priorityB) {
      return priorityA - priorityB; // Coloque o banco com prioridade maior primeiro
    } else {
      return bankNameA.localeCompare(bankNameB); // Ordem alfabética padrão
    }
  });

  function getPriority(name) {
    for (let i = 0; i < keywordsToPrioritize.length; i++) {
      if (name.includes(keywordsToPrioritize[i])) {
        return i; // Retorna a posição da primeira correspondência encontrada
      }
    }
    return keywordsToPrioritize.length; // Se não houver correspondências, use a prioridade máxima
  }

  useEffect(() => {
    fetch("https://brasilapi.com.br/api/banks/v1")
      .then((response) => response.json())
      .then((data) => setBanks(data))
      .catch((error) =>
        console.error("Ocorreu um erro ao buscar os bancos:", error)
      );
    setDadosBancarios(
      `Banco: ${selectedBank}-${bankNumber}/Agencia: ${agency}/Conta: ${conta}`
    );
  }, [available]);

  const handleBankChange = (event) => {
    const selectedBankName = event.target.value;
    setSelectedBank(selectedBankName);
    const selectedBankData = banks.find(
      (bank) => bank.name === selectedBankName
    );

    if (selectedBankData) {
      setBankNumber(selectedBankData.code);
    } else if (selectedBankName === "BNK Digital") {
      setBankNumber(547);
    } else {
      setBankNumber("");
    }
  };

  const handleBankNumberChange = (event) => {
    const bankNumber = event.target.value;
    setBankNumber(bankNumber);

    const selectedBankData = banks.find((bank) => bank.code == bankNumber);

    console.log("bankNumber:", bankNumber);
    console.log("selectedBankData:", selectedBankData);
    console.log("bankNumber:", bankNumber);
    console.log("Banks:", banks);

    if (selectedBankData) {
      setSelectedBank(selectedBankData.name);
    } else {
      setSelectedBank(""); // Limpar o campo "Dados Bancários" se o número do banco não corresponder a nenhum banco na lista.
    }
  };

  const handlePdf = async (event) => {
    event.preventDefault();
    validateInputs();
    if (!validateInputs()) {
      return; // Evitar a navegação se as validações não passarem
    }

    let dados = {
      userRef: user.data.email,
      razaoSocial: razaoSocial,
      cnpj: cnpj,
      cep: cep,
      rua: credorRua,
      bairro: credorBairro,
      numero: credorNumero,
      complemento: credorComplemento,
      cidade: credorCidade,
      estado: credorEstado,
      representantes: representantes,
      telefone: telefone,
      banco: selectedBank,
      numeroDoBanco: bankNumber,
      conta: conta,
      agencia: agency,
      tipoDeInstituicao,
      participantes: {
        escriturador,
        depositaria: depositaria?.nome?.length > 0 ? depositaria : null,
      },
    };

    try {
      // Fazer uma chamada Axios para a rota de criação do investidor
      console.log(dados);
      console.log(dados.representantes);
      const headers = {};
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/investidor",
        dados,
        headers
      );

      if (response.status === 201) {
        localStorage.setItem("investidorPreenchido", true);
        localStorage.setItem("investidor", JSON.stringify(dados));
        window.alert("Investidor Cadastrado!");
        navigate("/Emissor");
      } else {
        window.alert("Erro ao cadastrar investidor!");
      }
    } catch (error) {
      console.error("Erro ao criar investidor:", error);
      // Lida com erros de requisição, se necessário
    }
  };

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  useEffect(() => {
    handleExpand();
  }, []);

  const addRepresentante = () => {
    setRepresentantes([...representantes, { nome: "", email: "" }]); // Adiciona um novo representante vazio
  };

  const removeRepresentante = (index) => {
    const updatedRepresentantes = [...representantes];
    updatedRepresentantes.splice(index, 1);
    setRepresentantes(updatedRepresentantes);
  };

  const handleRepresentanteChange = (index, field, value) => {
    const updatedRepresentantes = [...representantes];
    updatedRepresentantes[index][field] = value;
    setRepresentantes(updatedRepresentantes);
  };

  const handleEscrituradorChange = (event) => {
    const { name, value } = event.target;
    setEscriturador((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDepositariaChange = (event) => {
    const { name, value } = event.target;
    setDepositaria((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e, fileType) => {
    const selectedFiles = e.target.files;

    const file = selectedFiles[0];
    //  console.log(file,'file')
    //if (selectedFiles.length === 1) {
    fileType === "faturamento" ? setRealFaturamento(file) : setConSocial(file);
    setUploadedFiles([...uploadedFiles, { fileType, file }]);
    setSuccessMessage(true);
    console.log(realFaturamento, "realFaturamento");
    //  console.log(file);
    // };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    validateInputs();
    if (!validateInputs()) {
      return; // Evitar a navegação se as validações não passarem
    }
    //Dados para enviar para o storage
    let dados = {
      userRef: user.data.email,
      razaoSocial: razaoSocial,
      cnpj: cnpj,
      cep: cep,
      rua: credorRua,
      bairro: credorBairro,
      numero: credorNumero,
      complemento: credorComplemento,
      cidade: credorCidade,
      estado: credorEstado,
      representantes: representantes,
      telefone: telefone,
      banco: selectedBank,
      numeroDoBanco: bankNumber,
      conta: conta,
      agencia: agency,
      tipoDeInstituicao,
      participantes: {
        escriturador,
        depositaria: depositaria?.nome?.length > 0 ? depositaria : null,
      },
    };
    //Para enviar servidor coomo arquivo
    const formData = new FormData();
    formData.append("userRef", user.data.email);
    formData.append("razaoSocial", razaoSocial);
    formData.append("cnpj", cnpj);
    formData.append("cep", cep);
    formData.append("rua", credorRua);
    formData.append("bairro", credorBairro);
    formData.append("numero", credorNumero);
    formData.append("complemento", credorComplemento);
    formData.append("cidade", credorCidade);
    formData.append("estado", credorEstado);
    // Adicione representantes (supondo que representantes é um array de objetos)
    representantes.forEach((representante, index) => {
      formData.append(`representantes[${index}][nome]`, representante.nome);
      formData.append(`representantes[${index}][email]`, representante.email);
    });

    formData.append("telefone", telefone);
    formData.append("banco", selectedBank);
    formData.append("numeroDoBanco", bankNumber);
    formData.append("conta", conta);
    formData.append("agencia", agency);
    // Adicione os arquivos
    formData.append("realFaturamentoFile", realFaturamento);
    formData.append("conSocialFile", conSocial);
    formData.append("tipoDeInstituicao", tipoDeInstituicao);
    formData.append(
      "participantes[escriturador]",
      JSON.stringify(escriturador)
    );
    formData.append(
      "participantes[depositaria]",
      depositaria ? JSON.stringify(depositaria) : null
    );

    try {
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + "/investidor/new",
        {
          method: "POST",
          body: formData,
        }
      );
      if (response.ok) {
        localStorage.setItem("investidorPreenchido", true);
        localStorage.setItem("investidor", JSON.stringify(dados));
        window.alert("Investidor Cadastrado!");
        navigate("/Emissor");
      } else {
        // Tratar erros
        console.error("Erro ao enviar o formulário para o servidor");
      }
    } catch (error) {
      window.alert("Erro ao cadastrar investidor!");
    }
  };
  const handleRemoverArquivo = (fileType) => {
    fileType === "faturamento" ? setRealFaturamento(null) : setConSocial(null);
    // setRealFaturamento(null); // Define realFaturamento como null para indicar que não há mais arquivo
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <Header selected={"investidor"} />
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingY: "20px",
        }}
      >
        {!investidores && (
          <Button
            onClick={toggleInvestidores}
            sx={{
              background:
                "linear-gradient(to right, rgb(103, 220, 159), rgb(31, 45, 99))", // Gradiente de verde para azul
              borderRadius: "20px", // Bordas arredondadas
              color: "white", // Cor do texto
              "&:hover": {
                background: "linear-gradient(to right, #00CC00, #000099)", // Gradiente de verde mais escuro para azul mais escuro ao passar o mouse
              },
              alignSelf: "center",
            }}
          >
            {"Cadastrar novo investidor"}
          </Button>
        )}
        {investidores ? (
          <FormControl>
            <Button
              sx={{
                color: "#000",
                alignSelf: "flex-start",
              }}
              className="divTitleDoc33"
              onClick={() => {
                toggleInvestidores();
              }}
            >
              <Typography variant="h5" display="flex" alignItems="center">
                <BsArrowLeft style={{ marginRight: "0.5rem" }} />
                Cadastrar novo investidor
              </Typography>
            </Button>{" "}
            <FormLabel htmlFor="">Razão Social do Investidor:</FormLabel>
            <TextField
              variant="outlined"
              type="text"
              placeholder="Insira a Razão Social"
              value={razaoSocial}
              onChange={(e) => {
                setRazaoSocial(e.target.value);
              }}
              maxLength={120}
            />
            <FormLabel htmlFor="" className="labelInputsDoc">
              CNPJ:
            </FormLabel>
            <TextField
              variant="outlined"
              placeholder="Apenas Números"
              value={formatCnpj(cnpj)}
              onChange={(e) => {
                setCnpj(e.target.value);
              }}
              maxLength={18}
            />
            <FormLabel htmlFor="" className="labelInputsDoc">
              CEP:
            </FormLabel>
            <TextField
              variant="outlined"
              placeholder="Apenas Números"
              value={formatCEP(cep)}
              onChange={(e) => {
                setCep(e.target.value);
              }}
              onBlur={handleSearch}
            />
            {loading && <p width="10%">Buscando...</p>}
            <FormLabel htmlFor="" className="labelInputsDoc">
              Rua:
            </FormLabel>
            <TextField
              variant="outlined"
              placeholder="Rua Av."
              value={credorRua}
              onChange={(e) => {
                setCredorRua(e.target.value);
              }}
            />
            <FormLabel htmlFor="" className="labelInputsDoc">
              Número:
            </FormLabel>
            <TextField
              variant="outlined"
              placeholder="Apenas Números"
              value={credorNumero}
              onChange={(e) => {
                setCredorNumero(e.target.value);
              }}
            />
            <FormLabel htmlFor="" className="labelInputsDoc">
              Complemento:
            </FormLabel>
            <TextField
              variant="outlined"
              placeholder="Complemento"
              value={credorComplemento}
              onChange={(e) => {
                setCredorComplemento(e.target.value);
              }}
            />
            <FormLabel htmlFor="" className="labelInputsDoc">
              Bairro:
            </FormLabel>
            <TextField
              variant="outlined"
              placeholder="Bairro"
              value={credorBairro}
              onChange={(e) => {
                setCredorBairro(e.target.value);
              }}
            />
            <FormLabel htmlFor="" className="labelInputsDoc">
              Cidade:
            </FormLabel>
            <TextField
              variant="outlined"
              placeholder="Cidade"
              value={credorCidade}
              onChange={(e) => {
                setCredorCidade(e.target.value);
              }}
            />
            <FormLabel htmlFor="" className="labelInputsDoc">
              Estado:
            </FormLabel>
            <TextField
              variant="outlined"
              placeholder="Estado"
              value={credorEstado}
              onChange={(e) => {
                setCredorEstado(e.target.value);
              }}
            />
            <div className="divTitleDoc">
              <Typography variant="h5" color="#1976d2">
                REPRESENTANTES
              </Typography>
            </div>
            {representantes.map((representante, index) => (
              <div
                key={index}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <FormLabel>Representante {index + 1}:</FormLabel>
                <TextField
                  type="text"
                  className={`inputsRep`}
                  value={representante.nome}
                  onChange={(e) =>
                    handleRepresentanteChange(index, "nome", e.target.value)
                  }
                  maxLength={60}
                />
                <FormLabel htmlFor="" className="labelInputsDoc">
                  E-mail:
                </FormLabel>
                <TextField
                  type="text"
                  className={`inputsRep`}
                  value={representante.email}
                  onChange={(e) =>
                    handleRepresentanteChange(index, "email", e.target.value)
                  }
                />
                {index >= 0 && ( // Impede a remoção do primeiro campo
                  <Button
                    type="button"
                    onClick={() => removeRepresentante(index)}
                    className="buttonRep"
                  >
                    Remover Representante
                  </Button>
                )}
              </div>
            ))}
            <Button sx={{ width: "100%" }} onClick={addRepresentante}>
              + Adicionar Representante
            </Button>
            <div className="divTitleDoc">
              <Typography variant="h5" color="#1976d2">
                PARTICIPANTES
              </Typography>
            </div>
            <FormLabel>Escriturador:</FormLabel>
            <TextField
              type="text"
              className={`inputsRep`}
              value={escriturador.nome}
              onChange={handleEscrituradorChange}
              placeholder="Insira o Escriturador"
              name="nome"
              maxLength={60}
            />
            <FormLabel htmlFor="" className="labelInputsDoc">
              CNPJ:
            </FormLabel>
            <TextField
              type="text"
              className={`inputsRep`}
              value={formatCnpj(escriturador.cnpj)}
              onChange={handleEscrituradorChange}
              placeholder="Apenas Números"
              name="cnpj"
            />
            <FormLabel>Depositária:</FormLabel>
            <TextField
              type="text"
              className={`inputsRep`}
              value={depositaria.nome}
              onChange={handleDepositariaChange}
              placeholder="Insira a Depositária"
              maxLength={60}
              name="nome"
            />
            <FormLabel htmlFor="" className="labelInputsDoc">
              CNPJ:
            </FormLabel>
            <TextField
              type="text"
              className={`inputsRep`}
              value={formatCnpj(depositaria.cnpj)}
              onChange={handleDepositariaChange}
              placeholder="Apenas Números"
              name="cnpj"
            />
            <div className="divTitleDoc">
              <Typography variant="h5" color="#1976d2">
                INFORMAÇÕES COMPLEMENTARES
              </Typography>
            </div>
            <FormLabel htmlFor="" className="labelInputsDoc">
              Telefone:
            </FormLabel>
            <TextField
              variant="outlined"
              value={formatarTelefoneGeral(telefone)}
              onChange={(e) => {
                setTelefone(e.target.value);
              }}
              maxLength={15}
            />
            <FormLabel htmlFor="" className="labelInputsDoc">
              Banco
            </FormLabel>
            <Select
              value={selectedBank}
              onChange={handleBankChange}
              className="selectDoc"
            >
              <MenuItem value="" className="optionInputsDoc">
                Selecione um banco
              </MenuItem>
              <MenuItem value="BNK Digital" className="optionInputsDoc">
                BNK Digital
              </MenuItem>
              {sortedBanks.map((bank, index) => (
                <MenuItem
                  key={index}
                  value={bank.name}
                  className="optionInputsDoc"
                >
                  {bank.name}
                </MenuItem>
              ))}
            </Select>
            <FormLabel htmlFor="" className="labelInputsDoc">
              Numero do Banco
            </FormLabel>
            <TextField
              variant="outlined"
              name="Número do banco"
              placeholder="Selecione um banco"
              value={bankNumber}
              onChange={handleBankNumberChange}
              maxLength={20}
            />
            <FormLabel htmlFor="" className="labelInputsDoc">
              Agência:
            </FormLabel>
            <TextField
              variant="outlined"
              placeholder="12345"
              type="text"
              value={agency}
              onChange={(e) => {
                setAgency(e.target.value);
              }}
              maxLength={8}
            />
            <FormLabel htmlFor="" className="labelInputsDoc">
              Conta
            </FormLabel>
            <TextField
              variant="outlined"
              placeholder="123 456-X"
              type="text"
              value={formatBankAccount(conta)}
              onChange={(e) => {
                setConta(e.target.value);
              }}
              maxLength={13}
            />
            <FormLabel htmlFor="" className="labelInputsDoc">
              Tipo de Instituição:
            </FormLabel>
            <Select
              className="selectDoc"
              value={tipoDeInstituicao}
              onChange={(e) => setTipoDeInstituicao(e.target.value)}
            >
              <MenuItem value="FIDC">FIDC</MenuItem>
              <MenuItem value="Factoring">Factoring</MenuItem>
              <MenuItem value="Securitizadora">Securitizadora</MenuItem>
            </Select>
            <FormLabel htmlFor="faturamento" className="labelInputsDoc">
              Contrato de faturamento:
            </FormLabel>
            <FormControl>
              <Input
                onClick={(event) => {
                  event.target.value = "";
                }}
                onChange={(e) => {
                  handleFileChange(e, "faturamento");
                }}
                id="faturamento"
                type="file"
                sx={{
                  display: "none",
                }}
              />
              <label htmlFor="faturamento" style={{ cursor: "pointer" }}>
                <Typography
                  component="span"
                  variant="body2"
                  className="uploadText"
                  textAlign="center"
                  align="center"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "6px 16px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    backgroundColor: "#f9f9f9",
                  }}
                >
                  <IoIosCloudUpload
                    className="uploadIcon"
                    sx={{ marginRight: "4px" }}
                  />
                  Selecionar contrato de faturamento
                </Typography>
              </label>
              {realFaturamento ? (
                <div className="uploadedFileItem" style={{ marginTop: "2vh" }}>
                  <span>{"Relatório"}</span>
                  <span>{realFaturamento.name}</span>
                  <span>{formatFileSize(realFaturamento.size)}</span>
                  <Button
                    onClick={() => handleRemoverArquivo("faturamento")}
                    className="button11"
                  >
                    Remover
                  </Button>
                </div>
              ) : (
                <div>{/* <span>Nenhum arquivo enviado ainda.</span>*/}</div>
              )}
            </FormControl>
            <FormLabel htmlFor="contract" className="labelInputsDoc">
              Contrato Social:
            </FormLabel>
            <FormControl>
              <Input
                onClick={(event) => {
                  event.target.value = "";
                }}
                onChange={(e) => {
                  handleFileChange(e, "contratos");
                }}
                id="contract"
                type="file"
                sx={{
                  display: "none",
                }}
              />
              <label htmlFor="contract" style={{ cursor: "pointer" }}>
                <Typography
                  component="span"
                  variant="body2"
                  className="uploadText"
                  textAlign="center"
                  align="center"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "6px 16px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    backgroundColor: "#f9f9f9",
                  }}
                >
                  <IoIosCloudUpload
                    className="uploadIcon"
                    sx={{ marginRight: "4px" }}
                  />
                  Selecionar contrato social
                </Typography>
              </label>
              {conSocial ? (
                <div className="uploadedFileItem" style={{ marginTop: "2vh" }}>
                  <span>{"Contrato"}</span>
                  <span>{conSocial.name}</span>
                  <span>{formatFileSize(conSocial.size)}</span>
                  <Button
                    onClick={() => handleRemoverArquivo("contrato")}
                    className="button11"
                  >
                    Remover
                  </Button>
                </div>
              ) : (
                <div>{/* <span>Nenhum arquivo enviado ainda.</span>*/}</div>
              )}
            </FormControl>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Button className="buttonDoc" onClick={handleSubmit}>
                Cadastrar Investidor
              </Button>
              <Button className="buttonDoc" onClick={toggleInvestidores}>
                Voltar
              </Button>
            </Grid>
          </FormControl>
        ) : (
          <Investidores />
        )}
      </Container>
    </Box>
  );
};

export default Investidor;
