import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { formatCnpj, formatCpf } from "../utils/tools";
import { formatCEP } from "../Modals/UpdateInvestor";

const DevedorSolidario = ({ updateFormData }) => {
  const emissor = JSON.parse(localStorage.getItem("emissor"));
  const [tipo, setTipo] = useState("PF"); // Inicialmente, define como pessoa física (PF)
  const [solidarioName, setSolidarioName] = useState(
    emissor ? emissor?.devedorSolidario?.solidarioName : ""
  );
  const [solidarioOcupacao, setSolidarioOcupacao] = useState(
    emissor ? emissor?.devedorSolidario?.solidarioOcupacao : ""
  );
  const [solidarioCep, setSolidarioCep] = useState(
    emissor ? emissor?.devedorSolidario?.solidarioCep : ""
  );
  const [solidarioRua, setSolidarioRua] = useState(
    emissor ? emissor?.devedorSolidario?.solidarioRua : ""
  );
  const [solidarioCidade, setSolidarioCidade] = useState(
    emissor ? emissor?.devedorSolidario?.solidarioCidade : ""
  );
  const [solidarioEstado, setSolidarioEstado] = useState(
    emissor ? emissor?.devedorSolidario?.solidarioEstado : ""
  );
  const [solidarioNumero, setSolidarioNumero] = useState(
    emissor ? emissor?.devedorSolidario?.solidarioNumero : ""
  );
  const [solidarioComplemento, setSolidarioComplemento] = useState(
    emissor ? emissor?.devedorSolidario?.solidarioComplemento : ""
  );
  const [solidarioCpf, setSolidarioCpf] = useState(
    emissor ? emissor?.devedorSolidario?.solidarioCpf : ""
  );
  const [solidarioCnpj, setSolidarioCnpj] = useState(
    emissor ? emissor?.devedorSolidario?.solidarioCnpj : ""
  );
  const [solidarioEmail, setSolidarioEmail] = useState(
    emissor ? emissor?.devedorSolidario?.solidarioEmail : ""
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch2 = async (cep) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
      const data = response.data;

      setSolidarioRua(data.logradouro || "");
      setSolidarioNumero("");
      setSolidarioComplemento("");
      setSolidarioCidade(data.localidade || "");
      setSolidarioEstado(data.uf || "");
    } catch (error) {
      console.error("Erro ao buscar endereço:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleCepChange2 = (e) => {
    const newCep = e.target.value.replace(/\D/g, "");
    setSolidarioCep(newCep);

    if (newCep.length === 8) {
      handleSearch2(newCep);
    }
  };
  const toggleTipo = () => {
    setTipo(tipo === "PF" ? "PJ" : "PF");
  };

  useEffect(() => {
    // Create a formDataObject with all the form field values
    const formDataObject = {
      solidarioName,
      solidarioOcupacao,
      solidarioCep,
      solidarioRua,
      solidarioCidade,
      solidarioEstado,
      solidarioNumero,
      solidarioComplemento,
      solidarioCpf,
      solidarioCnpj,
      solidarioEmail,
    };

    // Call the updateFormData function from props to update the form data in the parent component
    updateFormData(formDataObject);
  }, [
    tipo,
    solidarioName,
    solidarioOcupacao,
    solidarioCep,
    solidarioRua,
    solidarioCidade,
    solidarioEstado,
    solidarioNumero,
    solidarioComplemento,
    solidarioCpf,
    solidarioCnpj,
    solidarioEmail,
    isLoading,
  ]);

  return (
    <Box>
      <Typography variant="h5" color="#1976d2">
        DEVEDOR SOLIDÁRIO
      </Typography>
      <Button type="button" onClick={toggleTipo}>
        {tipo === "PF" ? "Pessoa Física (CPF)" : "Pessoa Jurídica (CNPJ)"}
      </Button>
      <Grid container spacing={2} mt={2}>
        {tipo === "PF" ? (
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Nome:"
              value={solidarioName}
              onChange={(e) => {
                setSolidarioName(e.target.value);
              }}
            />
          </Grid>
        ) : (
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Razão Social:"
              value={solidarioName}
              onChange={(e) => {
                setSolidarioName(e.target.value);
              }}
            />
          </Grid>
        )}

        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Ocupação:"
            value={solidarioOcupacao}
            onChange={(e) => {
              setSolidarioOcupacao(e.target.value);
            }}
            maxLength={40}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="CEP"
            value={formatCEP(solidarioCep)}
            onChange={(e) => {
              handleCepChange2(e);
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Rua"
            value={solidarioRua}
            onChange={(e) => setSolidarioRua(e.target.value)}
            maxLength={40}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Número:"
            value={solidarioNumero}
            onChange={(e) => setSolidarioNumero(e.target.value)}
            maxLength={40}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Complemento"
            value={solidarioComplemento}
            onChange={(e) => setSolidarioComplemento(e.target.value)}
            maxLength={40}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Cidade"
            value={solidarioCidade}
            onChange={(e) => setSolidarioCidade(e.target.value)}
            maxLength={40}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Estado"
            value={solidarioEstado}
            onChange={(e) => setSolidarioEstado(e.target.value)}
            maxLength={40}
          />
        </Grid>
        {tipo === "PF" ? (
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="CPF"
              value={formatCpf(solidarioCpf)}
              onChange={(e) => {
                setSolidarioCpf(e.target.value);
              }}
              maxLength={14}
            />
          </Grid>
        ) : (
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="CNPJ"
              value={formatCnpj(solidarioCnpj)}
              onChange={(e) => {
                setSolidarioCnpj(e.target.value);
              }}
              maxLength={18}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="E-mail"
            value={solidarioEmail}
            onChange={(e) => {
              setSolidarioEmail(e.target.value);
            }}
            maxLength={40}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DevedorSolidario;
