import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../styles/Update.css";
import { MdLockOutline, MdPersonOutline } from "react-icons/md";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import { BsArrowLeft } from "react-icons/bs";

const Update = () => {
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleValidation = () => {
    if (!email || !newPassword || !confirmPassword) {
      window.alert("Por favor, preencha todos os campos.");
      return false;
    }
    if (newPassword !== confirmPassword) {
      window.alert("As senhas digitadas não coincidem.");
      return false;
    }
    return true;
  };

  const handleSaveChanges = () => {
    if (!handleValidation()) {
      return;
    }
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const apiUrl = process.env.REACT_APP_BASE_URL + "/master/";
    const requestBody = {
      email: email,
      password: newPassword,
    };

    axios
      .put(apiUrl, headers, requestBody)
      .then((response) => {
        console.log("Senha atualizada com sucesso!");
        window.alert("Senha atualizada com sucesso!");
        navigate("/");
      })
      .catch((error) => {
        window.alert("Erro ao atualizar a senha");
        console.error("Erro ao atualizar a senha:", error);
      });
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleForgotPassword = async () => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    try {
      await axios.post(process.env.REACT_APP_BASE_URL + "/forgot-password", {
        email,
        linkProducao: "https://abrafesc.c2ss.com.br",
        headers,
      });
      alert("Email enviado com sucesso. Verifique sua caixa de entrada.");
    } catch (error) {
      console.error(error);
      console.log(error, "error");
      alert("Erro ao enviar o email. Verifique o console para mais detalhes.");
    }
  };
  const voltar = () => {
    navigate("/");
  };

  return (
    <div className="bodyUpdt">
      <div className="divCad">
        <div className="containerWeb" style={{ marginTop: "15vh" }}>
          <div>
            <div className="returnArrow" onClick={voltar}>
              <BsArrowLeft size={25} style={{ marginRight: "10px" }} />
              Esqueci minha senha
            </div>
            <h5 className="otherTitle">
              Por favor, informe seu endereço de e-mail para receber o link de
              redefinição de senha.
            </h5>
          </div>
          <p className="labelForgot">E-mail:</p>
          <input
            className="input-container-input"
            value={email}
            placeholder="Insira seu e-mail"
            onChange={(e) => setEmail(e.target.value)}
          />
          {/*<p className="labelForgot">E-mail:</p>
                    <div className="input-password">
                        <MdPersonOutline className="icon" />
                        <input
                            className="input-container-input"
                            maxLength={40}
                            value={email}
                            placeholder="Insira seu e-mail"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <p className="labelForgot">Nova senha:</p>
                    <div className="input-password">
                        <MdLockOutline className="icon" />
                        <input
                            className="input-container-input"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            placeholder="Insira sua senha"
                            type={showPassword ? "text" : "password"}
                        />
                        <div onClick={handleShowPassword} className="buttonEye">
                            {showPassword ? <RiEyeFill /> : <RiEyeOffFill />}
                        </div>
                    </div>
                    <p className="labelForgot">Confirme sua nova senha:</p>
                    <div className="input-password">
                        <MdLockOutline className="icon" />
                        <input
                            className="input-container-input"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            placeholder="Insira sua senha"
                            type={showPassword ? "text" : "password"}
                        />
                        <div onClick={handleShowPassword} className="buttonEye">
                            {showPassword ? <RiEyeFill /> : <RiEyeOffFill />}
                        </div>
                    </div>*/}
          <div>
            <button className="buttonEntrar" onClick={handleForgotPassword}>
              Atualizar senha
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Update;
