import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  Select,
  FormLabel,
  FormControl,
  MenuItem,
} from "@mui/material";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";
import SideHeader from "./Header";
import { pdfStyle } from "../utils/pdfStyles";
import { chunkArray } from "../utils/chunkArray";

import { GrDocument } from "react-icons/gr";
import { formatCnpj } from "../utils/tools";
import PostNote from "./PostNote";
import { Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Minuta = () => {
  const [serie, setSerie] = useState("");
  const [emissionControl, setEmissionControl] = useState("");
  const [emissionNumber, setEmissionNumber] = useState(null);
  const [sendingNote, setSendingNote] = useState(false); // Estado para controlar o envio da nota
  const navigate = useNavigate();

  Font.registerHyphenationCallback((word) => [word]);

  const series = [
    "Única",
    "Duas",
    "Três",
    "Quatro",
    "Cinco",
    "Seis",
    "Sete",
    "Oito",
    "Nove",
    "Dez",
    "Onze",
    "Doze",
    "Treze",
    "Catorze",
    "Quinze",
    "Dezesseis",
    "Dezessete",
    "Dezoito",
    "Dezenove",
    "Vinte",
    "Vinte e Um",
    "Vinte e Dois",
    "Vinte e Três",
    "Vinte e Quatro",
  ];

  const emissionControls = [
    "Primeira",
    "Segunda",
    "Terceira",
    "Quarta",
    "Quinta",
    "Sexta",
    "Sétima",
    "Oitava",
    "Nona",
    "Décima",
    "Décima Primeira",
    "Décima Segunda",
    "Décima Terceira",
    "Décima Quarta",
    "Décima Quinta",
    "Décima Sexta",
    "Décima Sétima",
    "Décima Oitava",
    "Décima Nona",
    "Vigésima",
    "Vigésima Primeira",
    "Vigésima Segunda",
    "Vigésima Terceira",
    "Vigésima Quarta",
  ];

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [minutaData, setMinutaData] = useState(null);
  const [showPDF, setShowPDF] = useState(false);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleSaveMinutaBlob = (blob) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      localStorage.setItem("minutaBlobString", event.target.result);
    };

    reader.readAsDataURL(blob);
  };

  const calcData = JSON.parse(localStorage.getItem("calcData"));
  const now = new Date();
  const formData = JSON.parse(localStorage.getItem("formData"));

  const styles = pdfStyle;
  const chunkedCalcData = chunkArray(calcData, 9);
  let isFirstPage = true;
  const emissor = JSON.parse(localStorage.getItem("emissor"));
  const investidor = JSON.parse(localStorage.getItem("investidor"));

  const lastInstallment = calcData[calcData.length - 1];

  const month = now.getUTCMonth() + 1; // months from 1-12
  const day = now.getUTCDate();
  const year = now.getUTCFullYear();
  const pMonth = month.toString().padStart(2, "0");
  const pDay = day.toString().padStart(2, "0");

  useEffect(() => {
    console.log(emissor);
    console.log(investidor);
  }, []);

  const getNominalUnitValue = () => {
    return formData.total / (series.indexOf(serie) + 1);
  };

  const handleSerieChange = (event) => {
    const selectedSerieName = event.target.value;
    setSerie(selectedSerieName);
  };

  const handleEmissionControlChange = (event) => {
    const selectedEmissionControlName = event.target.value;
    setEmissionControl(selectedEmissionControlName);
  };

  const handleShowPdf = () => {
    if (!emissor) {
      return alert("Selecione um emissor, antes de prosseguir!");
    }

    if (!investidor) {
      return alert("Selecione um investidor, antes de prosseguir!");
    }

    if (!formData) {
      return alert("Faça uma simulação, antes de prosseguir!");
    }

    if (!serie.length) {
      return alert("Selecione uma série, antes de prosseguir!");
    }

    if (!emissionControl.length) {
      return alert("Selecione um controle de emissão, antes de prosseguir!");
    }

    if (serie.length && emissionControl.length) {
      const url = process.env.REACT_APP_BASE_URL;

      fetch(`${url}/minuta/sequence`, {
        method: "POST",
      })
        .then((response) => response.json())
        .then((data) => setEmissionNumber(data.jsonDados.emissionNumber))
        .then(() => {
          setShowPDF(true);
        })
        .catch((error) =>
          console.error(
            "Ocorreu um erro ao gerar a sequência de emissão",
            error
          )
        );
    }
  };

  useEffect(() => {
    if (calcData && formData && investidor && emissor) {
      setMinutaData({
        emissionControl: emissionControl,
        serie: serie,
        dataEmissao: new Date(),
        lastInstallment: calcData[calcData.length - 1],
        totalValue: formData.total,
        nominalUnitValue: getNominalUnitValue(),
        taxa: formData.taxa,

        emissor: {
          razaoSocial: emissor.razaoSocial,
          cnpj: emissor.cnpj,
          cidade: emissor.cidade,
          estado: emissor.estado,
          rua: emissor.rua,
          numero: emissor.numero,
          complemento: emissor.complemento,
          cep: emissor.cep,
          meioPagamento: emissor.meioPagamento,
          banco: emissor.banco,
          bankNumber: emissor.bankNumber,
          agency: emissor.agency,
          conta: emissor.conta,
        },
        investidor: {
          banco: investidor.banco,
          numeroDoBanco: investidor.numeroDoBanco,
          agencia: investidor.agencia,
          conta: investidor.conta,
        },
      });
    }
  }, []);

  const handleClose = () => {
    setShowPDF(false);
    navigate("/Emissor");
  };

  // TODO: SOLICITAR ENVIO DE NC

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#fff",
      }}
    >
      <SideHeader selected="minuta" />
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column", // Alterei de 'row' para 'column' para empilhar verticalmente
          height: "72%",
          backgroundColor: "#fff",
          borderRadius: "15px",
          paddingBottom: "20px",
        }}
      >
        <FormControl
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            gap: 2,
          }}
          className="formDoc1"
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <FormLabel htmlFor="" className="labelInputsDoc">
              Série
            </FormLabel>
            <Select
              required
              sx={{ minWidth: "200px" }}
              value={serie}
              onChange={handleSerieChange}
              className="selectDoc"
            >
              <MenuItem value="" className="optionInputsDoc">
                Selecione uma série
              </MenuItem>
              {series.map((doc, index) => (
                <MenuItem key={index} value={doc} className="optionInputsDoc">
                  {doc}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <FormLabel htmlFor="" className="labelInputsDoc">
              Controle de emissão
            </FormLabel>
            <Select
              required
              sx={{ minWidth: "200px" }}
              value={emissionControl}
              onChange={handleEmissionControlChange}
              className="selectDoc"
            >
              <MenuItem value="" className="optionInputsDoc">
                Selecione um controle de emissão
              </MenuItem>
              {emissionControls.map((doc, index) => (
                <MenuItem key={index} value={doc} className="optionInputsDoc">
                  {doc}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </FormControl>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 3,
          }}
        >
          <Button
            onClick={() => handleShowPdf()}
            sx={{
              background:
                "linear-gradient(to right, rgb(103, 220, 159), rgb(31, 45, 99))", // Gradiente de verde para azul
              borderRadius: "20px", // Bordas arredondadas
              color: "white", // Cor do texto
              "&:hover": {
                background: "linear-gradient(to right, #00CC00, #000099)", // Gradiente de verde mais escuro para azul mais escuro ao passar o mouse
              },
              marginY: "20px",
              fontSize: 20,
            }}
          >
            Gerar Minuta
            <GrDocument style={{ marginLeft: "10px" }} />
          </Button>
          {showPDF && (
            <Box onClick={handleClose}>
              <Close
                size={40}
                style={{
                  display: "flex",
                  backgroundColor: "#e74c3c",
                  color: "white",
                  transition: "background-color 0.3s",
                  borderRadius: "10px",
                  border: "none",
                  height: "16px",
                  padding: "16px",
                  width: "16px",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                sx={{
                  "&:hover": {
                    backgroundColor: "#e5402f",
                  },
                }}
                color="#f83939"
              />
            </Box>
          )}
        </Box>
        {showPDF && (
          <PDFViewer width="1000" height="600">
            <Document
              onLoadSuccess={onDocumentLoadSuccess}
              onRender={(props) => handleSaveMinutaBlob(props.blob)}
            >
              <Page style={styles.page}>
                <Text style={[styles.textBold, { fontSize: 10 }]}>
                  TERMO CONSTITUTIVO DE NOTA COMERCIAL DA{" "}
                  {emissionControls.indexOf(emissionControl) + 1}ª (
                  {emissionControls[
                    emissionControls.indexOf(emissionControl)
                  ].toUpperCase()}
                  ) EMISSÃO DE NOTAS COMERCIAIS, EM SÉRIE {serie.toUpperCase()},
                  PARA COLOCAÇÃO PRIVADA, DO(A){" "}
                  {emissor.razaoSocial.toUpperCase()}.
                </Text>
                <View style={styles.section}>
                  <Text style={styles.h1}>I. EMISSORA</Text>
                  <View style={styles.tableContainer}>
                    <View style={styles.row}>
                      <Text style={styles.cellRight}>
                        <Text style={styles.headerCell}>Razão Social</Text>{" "}
                        {"\n"}
                        {emissor.razaoSocial}
                      </Text>
                      <Text style={styles.cell}>
                        <Text style={styles.headerCell}>CNPJ/ME</Text>
                        {"\n"}
                        {formatCnpj(emissor.cnpj)}
                      </Text>
                    </View>
                  </View>
                  <Text style={styles.text3}>
                    A Emissão e a celebração deste Termo Constitutivo de Nota
                    Comercial da {emissionControls.indexOf(emissionControl) + 1}
                    ª (
                    {
                      emissionControls[
                        emissionControls.indexOf(emissionControl)
                      ]
                    }
                    ) Emissão de Notas Comerciais, em Série {serie}, para
                    Colocação Privada, da {emissor.razaoSocial} são realizadas
                    com base nas deliberações dos administradores da Emissora,
                    conforme previsto no Parágrafo único, artigo 46 da Lei
                    nº14.195, de 26 de agosto de 2021 (“Lei 14.195”) e no
                    [contrato] OU [estatuto] social da Emissora.
                  </Text>
                  <Text style={styles.h1}>II. RESUMO DA EMISSÃO</Text>
                  <View style={styles.tableContainer}>
                    <View style={styles.row}>
                      <Text style={styles.cell2}>
                        <Text style={styles.textBold}>Número da Emissão</Text>
                        {"\n"}
                        {emissionControl} {emissionNumber}
                      </Text>
                      <Text style={styles.cell2}>
                        <Text style={styles.textBold}>Série</Text>
                        {"\n"}
                        {serie}
                      </Text>
                      <Text style={styles.cell2}>
                        <Text style={styles.textBold}>
                          Local de Emissão{"\n"}
                        </Text>
                        {emissor.cidade}
                      </Text>
                      <Text style={[styles.cell2, { borderRightWidth: 0 }]}>
                        <Text style={styles.textBold}>Data de Emissão</Text>
                        {"\n"}
                        {now.getDate()}/{now.getMonth() + 1}/{now.getFullYear()}
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Text style={[styles.cell2]}>
                        <Text style={styles.textBold}>Data de Vencimento</Text>
                        {"\n"}
                        {String(lastInstallment[1]).padStart(2, "0")}/
                        {lastInstallment[2]}/{String(lastInstallment[3])}
                      </Text>

                      <Text style={styles.cell2}>
                        <Text style={styles.textBold}>
                          Valor Total da Emissão
                        </Text>
                        {"\n"}
                        {formData.total.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </Text>
                      <Text style={styles.cell2}>
                        <Text style={styles.textBold}>
                          Valor Nominal Unitário
                        </Text>
                        {"\n"}
                        {getNominalUnitValue().toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </Text>
                      <Text style={[styles.cell2, { borderRightWidth: 0 }]}>
                        <Text style={styles.textBold}>Quantidade</Text>
                        {"\n"}
                        {series.indexOf(serie) + 1} (
                        {series[series.indexOf(serie)]})
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Text style={[styles.cell2]}>
                        <Text style={styles.textBold}>Conversibilidade</Text>
                        {"\n"}
                        <Text style={styles.textBold}></Text>Simples, ou seja,
                        não conversíveis em participação societária da Emissora.
                      </Text>
                      <Text style={styles.cell2}>
                        <Text style={styles.textBold}>Custo da Emissão</Text>
                        {"\n"}
                        R$ 750,00 (Setecentos e Ciquenta Reais) a ser pago
                        pela(o) [Emissor OU Titular em nome do Emissor] à
                        Cred2Cards Serviços de Tecnologia LTDA, na conta
                        corrente abaixo indicada, [na data da emissão] das Notas
                        Comerciais. Banco: Inter (077) Agência: 00001 Conta
                        Corrente: 23203346-3
                      </Text>
                      <Text style={styles.cell2}>
                        <Text style={styles.textBold}>Garantias</Text>
                        {"\n"}As Notas Comerciais contarão com garantias,
                        fidejussórias.
                      </Text>
                      <Text style={[styles.cell2, { borderRightWidth: 0 }]}>
                        <Text style={styles.textBold}>Moeda da Emissão</Text>
                        {"\n"}
                        Reais (R$)
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Text style={[styles.cell2]}>
                        <Text style={styles.textBold}>Repactuação</Text>
                        {"\n"}
                        As Notas Comerciais não serão objeto de repactuação
                        programada.
                      </Text>

                      <Text style={styles.cell2}>
                        <Text style={styles.textBold}>
                          Classificação de Risco
                        </Text>
                        {"\n"}
                        Não será contratada agência de classificação de risco da
                        Emissora ou das Notas Comerciais no âmbito da Oferta
                        Restrita.
                      </Text>
                      <Text style={styles.cell2}>
                        <Text style={styles.textBold}>Tipo de Oferta</Text>
                        {"\n"}Privada, isto é: não será realizada oferta pública
                        de valores mobiliários para fins de colocação das Notas
                        Comerciais.
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Text style={[styles.cell2]}>
                        <Text style={styles.textBold}>Local de Pagamento</Text>
                        {"\n"}
                        {investidor.cidade}-{investidor.estado}, em conta do
                        Titular das Notas Comerciais, indicado no Boletim de
                        Subscrição.
                      </Text>

                      <Text style={styles.cell2}>
                        <Text style={styles.textBold}>
                          Atualização Monetária
                        </Text>
                        {"\n"}
                        As Notas Comerciais não serão atualizadas
                        monetariamente.
                      </Text>
                      <Text style={styles.cell2}>
                        <Text style={styles.textBold}>
                          Juros Remuneratórios
                        </Text>
                        {"\n"}
                        {formData.taxa}% a.m. ({formData.taxa} ao mês), base 30
                        (trinta) dias, sendo certo que a rentabilidade apenas se
                        inicia após a efetiva liquidação financeira.
                      </Text>
                      <Text style={[styles.cell2, { borderRightWidth: 0 }]}>
                        <Text style={styles.textBold}>
                          Datas de Pagamento dos Juros Remuneratórios
                        </Text>
                        {"\n"}
                        Mensal, conforme fluxo de pagamento constante no Anexo
                        II.
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Text style={[styles.cellRight]}>
                        <Text style={styles.textBold}>
                          Data de Pagamento dos Valor Nominal Unitário
                        </Text>
                        {"\n"}
                        Mensal, conforme fluxo de pagamento constante no Anexo
                        II.
                      </Text>

                      <Text style={styles.cellRight}>
                        <Text style={styles.textBold}>
                          Amortização Extraordinária
                        </Text>
                        {"\n"}
                        Não haverá possibilidade de amortização extraordinária
                        das Notas Comerciais.
                      </Text>
                      <Text style={styles.cellRight}>
                        <Text style={styles.textBold}>
                          Resgate Antecipado Facultativo Total e Oferta de
                          Resgate Antecipado
                        </Text>
                        {"\n"}Mediante o pagamento dos juros incorridos no
                        respectivo período.
                      </Text>
                      <Text style={[styles.cellRight, { borderRightWidth: 0 }]}>
                        <Text style={styles.textBold}>
                          Resgate Antecipado Compulsório Total
                        </Text>
                        {"\n"}
                        As Notas Comerciais deverão ser resgatadas integralmente
                        caso seja decretado o Vencimento Antecipado das Notas
                        Comerciais, conforme abaixo definido.
                      </Text>
                    </View>
                  </View>
                  <Text style={styles.h1}>III. PARTICIPANTES</Text>
                  <View style={styles.tableContainer}>
                    <View style={styles.row}>
                      <Text style={styles.cell2}>
                        <Text style={styles.textBold}>Escriturador</Text>
                        {"\n"}
                        {investidor.participantes?.escriturador &&
                          JSON.parse(investidor.participantes.escriturador)
                            ?.nome}
                      </Text>
                      <Text style={styles.cell2}>
                        <Text style={styles.textBold}>CNPJ/ME</Text>
                        {"\n"}
                        {investidor.participantes?.escriturador &&
                          formatCnpj(
                            JSON.parse(investidor.participantes.escriturador)
                              ?.cnpj
                          )}
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Text style={styles.cell2}>
                        <Text style={styles.textBold}>
                          Central Depositária de Valores Mobiliários
                        </Text>
                        {"\n"}
                        {investidor.participantes?.depositaria &&
                          JSON.parse(investidor.participantes.depositaria)
                            ?.nome}
                      </Text>
                      <Text style={styles.cell2}>
                        <Text style={styles.textBold}>CNPJ/ME</Text>
                        {"\n"}
                        {investidor.participantes?.depositaria &&
                          formatCnpj(
                            JSON.parse(investidor.participantes.depositaria)
                              ?.cnpj
                          )}
                      </Text>
                    </View>
                  </View>
                  <Text style={styles.h1}>IV. COMUNICAÇÕES</Text>
                  <View style={styles.tableContainer}>
                    <View style={styles.row}>
                      <Text style={styles.cell2}>
                        <Text style={styles.textBold}>Se para a Emissora</Text>
                        {"\n"}
                        {emissor.rua}, nº{emissor.numero}, {emissor.complemento}
                        ,Bairro {emissor.complemento}, Cidade de{" "}
                        {emissor.cidade}, Estado do {emissor.estado}, CEP{" "}
                        {emissor.cep}
                      </Text>
                      <Text style={styles.cell2}>
                        Att. Sr.(a): {emissor.representante}
                        {"\n"}Telefone: {emissor.telefone}
                        {"\n"}E-mail: {emissor.email}
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Text style={styles.cell2}>
                        <Text style={styles.textBold}>
                          Se para o Escriturador
                        </Text>
                        {"\n"}
                        {investidor.rua}, nº {investidor.numero},{" "}
                        {investidor.complemento},Bairro {investidor.bairro},
                        Cidade de {investidor.cidade}, Estado do{" "}
                        {investidor.estado}, CEP {investidor.cep}
                      </Text>
                      <Text style={styles.cell2}>
                        Att. Sr.(a): {investidor.representantes[0].nome}
                        {"\n"}Telefone: {investidor.telefone}
                        {"\n"}E-mail: {investidor.representantes[0].email}
                      </Text>
                    </View>
                    {emissor.custodiante && (
                      <View style={styles.row}>
                        <Text style={styles.cell2}>
                          <Text style={styles.textBold}>
                            Se para o Custodiante
                          </Text>
                          {"\n"}
                          {emissor.custodiante.rua}, nº{" "}
                          {emissor.custodiante.numero},
                          {emissor.custodiante.complemento}, Bairro{" "}
                          {emissor.custodiante.bairro}, Cidade de{" "}
                          {emissor.custodiante.cidade}, Estado do{" "}
                          {emissor.custodiante.estado}, CEP{" "}
                          {emissor.custodiante.cep}
                        </Text>
                        <Text style={styles.cell2}>
                          Att. Sr.(a): {emissor.custodiante.nome}
                          {"\n"}Telefone: {emissor.custodiante.telefone}
                          {"\n"}E-mail: {emissor.custodiante.email}
                        </Text>
                      </View>
                    )}
                    <View style={styles.row}>
                      <Text style={styles.cell2}>
                        <Text style={styles.textBold}>
                          Se para a Central Depositária de Valores Mobiliários{" "}
                        </Text>
                        {"\n"}
                        Avenida Pedroso de Morais, nº 433, conjunto 52, Cidade
                        de São Paulo, Estado de São Paulo, CEP 05419-90
                      </Text>
                      <Text style={styles.cell2}>
                        Att. Srs: Rodrigo M. Amato e Carlos A. Roveran{"\n"}
                        Telefone: (11) 3522 4022{"\n"}E-mail:
                        rodrigo.amato@laqus.com.br e carlos.roveran@laqus.com.br
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Text style={styles.cell2}>
                        As notificações, instruções e comunicações serão
                        consideradas entregues quando recebidas sob protocolo ou
                        com “aviso de recebimento” expedido pela Empresa
                        Brasileira de Correios, ou por telegrama nos endereços
                        acima. As comunicações enviadas por correio eletrônico
                        serão consideradas recebidas na data de seu envio, desde
                        que seu recebimento seja confirmado por meio de recibo
                        emitido pelo remetente (recibo emitido pela máquina
                        utilizada pelo remetente). A mudança de qualquer dos
                        endereços acima deverá ser imediatamente comunicada às
                        demais Partes pela Parte que tiver seu endereço
                        alterado.
                      </Text>
                    </View>
                  </View>
                  <Text style={styles.h1}>1 OBJETO</Text>
                  <Text style={styles.text3}>
                    O presente Termo Constitutivo tem por objeto a{" "}
                    {emissionControls.indexOf(emissionControl) + 1}ª (
                    {emissionControl}) Emissão de Notas Comerciais, em Série{" "}
                    {serie}, para colocação [Privada], da Emissora (“Emissão” e
                    “Notas Comerciais”, respectivamente), representativas de
                    promessa de pagamento em dinheiro, de acordo com as
                    características, termos e condições estabelecidos neste
                    Termo Constitutivo, nos termos da Lei nº 6.385, de 7 de
                    dezembro de 1976, conforme alterada e da Lei 14.195.
                  </Text>
                  <Text style={styles.h1}>2 CONDIÇÕES PRECEDENTES</Text>
                  <Text style={styles.text3}>
                    A Emissão, está condicionada, nos termos do artigo 125 da
                    Lei 10.406, de 10 de janeiro de 2002, conforme alterada, à
                    verificação do cumprimento dos seguintes atos (“Condições
                    Precedentes”):
                    {"\n\n"}
                  </Text>
                  <View style={styles.row}>
                    <Text style={styles.text1}>a.</Text>
                    <Text style={styles.text5}>
                      Perfeita formalização, pela Emissora e demais partes
                      signatárias, do Termo Constitutivo e do Boletim de
                      Subscrição (conjuntamente, os “Documentos da Operação”),
                      bem como a verificação dos poderes dos representantes
                      dessas partes e eventuais aprovações societárias
                      necessárias à celebração desses documentos; e{"\n\n"}
                    </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.text1}>b. </Text>
                    <Text style={styles.text5}>
                      Recebimento, pela Emissora, dos Boletins de Subscrição
                      devidamente formalizados, cujo modelo segue anexo a este
                      Termo Constituivo no Anexo I.
                    </Text>
                  </View>
                  <Text style={styles.h1}>3 VENCIMENTO ANTECIPADO</Text>
                  <Text style={styles.text3}>
                    Observado o disposto a seguir, o titular das Notas
                    Comerciais poderá declarar antecipadamente vencidas todas as
                    obrigações constantes deste Termo Constitutivo (“Vencimento
                    Antecipado”) na ocorrência de qualquer uma das hipóteses
                    abaixo:
                    {"\n\n"}
                  </Text>
                  <View style={styles.row}>
                    <Text style={styles.text1}>a.</Text>
                    <Text style={styles.text5}>
                      Se ocorrer inadimplemento de qualquer obrigação assumida
                      pela Emissora em consonância com as cláusulas e condições
                      aqui estabelecidas, principalmente no que tange ao
                      pagamento das parcelas devidas em decorrências da
                      celebração deste Termo Constitutivo e emissão das Notas
                      Comerciais;{"\n\n"}
                    </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.text1}>b.</Text>
                    <Text style={styles.text5}>
                      Se for comprovada a falsidade de qualquer declaração,
                      informação ou documento que houver sido respectivamente
                      firmada, prestada ou entregue pela Emissora;{"\n\n"}
                    </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.text1}>c.</Text>
                    <Text style={styles.text5}>
                      Se a Emissora sofrer quaisquer medidas judiciais ou
                      extrajudiciais, que por qualquer forma, possam afetar
                      negativamente os créditos das Notas Comerciais;{"\n\n"}
                    </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.text1}>d.</Text>
                    <Text style={styles.text5}>
                      Se, sem o expresso consentimento dos credores das Notas
                      Comerciais ocorrer a transferência a terceiros dos
                      direitos e obrigações da Emissora previstos neste Termo
                      Constitutivo; e{"\n\n"}
                    </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.text1}>e.</Text>
                    <Text style={styles.text5}>
                      Se ocorrer a transferência a terceiros dos direitos e
                      obrigações da Emissora, previstos neste Termo Constitutivo
                      {"\n\n"}
                    </Text>
                  </View>
                  <Text style={styles.h1}>4 DEPÓSITO CENTRALIZADO</Text>
                  <View style={styles.row}>
                    <Text style={styles.text1}>a.</Text>
                    <Text style={styles.text5}>
                      As Notas Comerciais serão depositadas perante a Laqus
                      Depositária de Valores Mobiliários S.A., inscrita no
                      CNPJ/MF sob o nº 33.268.302/0001-02 (“Depositária”), que
                      fará o depósito centralizado e custódia eletrônica das
                      Notas Comerciais, com as atribuições indicadas pelo artigo
                      49 da Lei 14.195/21, sendo admissível, no entanto, a
                      substituição ou interoperabilidade das Notas Comerciais
                      para outra central depositária de valores mobiliários,
                      desde que a instituição esteja devidamente autorizada pela
                      CVM, nos termos da Resolução CVM nº 31.{"\n\n"}
                    </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.text1}>b.</Text>
                    <Text style={styles.text5}>
                      A solicitação será realizada pelo Titular em relação às
                      suas quantidades de Notas Comerciais, observando, para
                      tanto, as regras previstas pelo Manual de
                      Interoperabilidade da Laqus, na qualidade de Depositária
                      de Origem, bem como os procedimentos indicados pela
                      central depositária de destino. Em razão do depósito, as
                      Notas Comerciais estão habilitadas para distribuição em
                      oferta primária, bem como negociação no mercado
                      secundário, conforme o caso.{"\n\n"}
                    </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.text1}>c.</Text>
                    <Text style={styles.text5}>
                      A Emissora declara estar ciente e de acordo com todos os
                      termos, procedimentos e condições do Regulamento de Acesso
                      e Operações e demais Regulamentos e Manuais Operacionais
                      da Depositária, disponíveis em seu website
                      (https://www.laqus.com.br/imf-digital/documentacao/), a
                      eles aderindo formalmente, em caráter irrevogável e
                      irretratável, obrigando-se a respeitá-los e a cumpri-los,
                      fielmente, comprometendo-se com todos os termos, condições
                      e direitos neles previstos e, inclusive, em suas eventuais
                      alterações e aditamentos, complementos ou novas versões.
                      {"\n\n"}
                    </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.text1}>d.</Text>
                    <Text style={styles.text5}>
                      Será concedido à Emissora o direito de acesso à Plataforma
                      IMF Digital, na qualidade de Participante, mediante a
                      criação de usuários master a serem disponibilizados aos
                      seus representantes legais, bem como aos demais usuários
                      indicados no momento da solicitação de cadastro, em
                      consonância aos procedimentos e normativos vigentes à
                      época.{"\n\n"}
                    </Text>
                  </View>
                  <Text style={styles.h1}>
                    5 DIREITO AO RECEBIMENTO DOS PAGAMENTOS
                  </Text>
                  <Text style={styles.text3}>
                    Terá direito ao recebimento dos valores devidos em relação
                    às Notas Comerciais, seja o pagamento realizado em caráter
                    ordinário ou extraordinário, os Titulares das Notas
                    Comerciais constituídos no encerramento do dia útil
                    imediatamente anterior à respectiva data de pagamento.
                    {"\n\n"}
                  </Text>
                  <Text style={styles.h1}>6 DAS DECLARAÇÕES</Text>
                  <Text style={styles.text3}>
                    A Emissora declara que:
                    {"\n\n"}
                  </Text>
                  <View style={styles.row}>
                    <Text style={styles.text1}>a.</Text>
                    <Text style={styles.text5}>
                      Possui plena capacidade e legitimidade para celebrar o
                      presente Termo Constitutivo, realizar todas as operações e
                      cumprir todas as obrigações assumidas tendo tomado todas
                      as medidas de natureza societária, conforme aplicável, e
                      outras eventualmente necessárias para autorizar a sua
                      celebração, implementação e cumprimento de todas as
                      obrigações constituídas;{"\n\n"}
                    </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.text1}>b.</Text>
                    <Text style={styles.text5}>
                      A celebração deste Termo Constitutivo e o cumprimento das
                      obrigações nele dispostos: (a) não violam qualquer
                      disposição contida nos seus documentos societários,
                      conforme aplicável; (b) não violam qualquer lei,
                      regulamento, decisão judicial, administrativa ou arbitral,
                      aos quais a respectiva parte esteja vinculada; (c) não
                      exigem qualquer consentimento ação ou autorização, prévia
                      ou posterior, de terceiros;{"\n\n"}
                    </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.text1}>c.</Text>
                    <Text style={styles.text5}>
                      Este Termo Constitutivo é validamente celebrado e
                      constitui obrigação legal, válida, vinculante e exequível,
                      de acordo com os seus termos;
                      {"\n\n"}
                    </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.text1}>d.</Text>
                    <Text style={styles.text5}>
                      Está apta a cumprir as obrigações ora previstas neste
                      Termo Constitutivo e agirá em relação às mesmas de boa-fé
                      e com lealdade;{"\n\n"}
                    </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.text1}>e.</Text>
                    <Text style={styles.text5}>
                      Não se encontra em estado de necessidade ou sob coação
                      para celebrar este Termo Constitutivo e/ou quaisquer
                      contratos e compromissos a ela relacionados e acessórios;
                      e{"\n\n"}
                    </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.text1}>f.</Text>
                    <Text style={styles.text5}>
                      É devidamente estruturada, qualificada e capacitada para
                      entender a estrutura financeira e jurídica objeto deste
                      Termo Constitutivo.{"\n\n"}
                    </Text>
                  </View>
                  <Text style={styles.h1}>
                    7 DO ATRASO NO PAGAMENTO E ENCARGOS MORATÓRIOS
                  </Text>
                  <Text style={styles.text3}>
                    Na hipótese de inadimplemento ou mora dos valores devidos no
                    âmbito deste Termo Constitutivo, a Emissora estará obrigada
                    a pagar ao titular, cumulativamente, além da quantia
                    correspondente à dívida em aberto, os seguintes encargos:{" "}
                    {"\n\n"}
                  </Text>
                  <View style={styles.row}>
                    <Text style={styles.text1}>a.</Text>
                    <Text style={styles.text5}>
                      Juros remuneratórios nos mesmos percentuais das taxas
                      constantes neste Termo Constitutivo, calculados a partir
                      do vencimento das parcelas em aberto até a data do efetivo
                      pagamento;{"\n\n"}
                    </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.text1}>b.</Text>
                    <Text style={styles.text5}>
                      Juros de mora à razão de 2% a.m. (ao mês), calculados a
                      partir do vencimento das parcelas em aberto até a data do
                      efetivo pagamento pela Emissora;
                      {"\n\n"}
                    </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.text1}>c.</Text>
                    <Text style={styles.text5}>
                      Multa contratual, de natureza não compensatória, de 10%
                      incidente sobre o montante total atualizado (incluindo
                      juros remuneratórios e juros de mora) do valor devido e
                      não pago; e{"\n\n"}
                    </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.text1}>d.</Text>
                    <Text style={styles.text5}>
                      Na hipótese do Titular vir a ser compelido a recorrer a
                      meios administrativos ou judiciais para receber os valores
                      devidos no âmbito deste Termo Constitutivo, as despesas de
                      cobrança administrativa, limitadas a 10% (dez por cento)
                      sobre o valor do saldo devedor deste Termo Constitutivo e,
                      havendo procedimento judicial, custas processuais e
                      honorários advocatícios, estes fixados judicialmente.
                      {"\n\n"}
                    </Text>
                  </View>
                  <Text style={styles.h1}>8 DISPOSIÇÕES GERAIS</Text>
                  <Text style={styles.text3}>
                    Na hipótese de inadimplemento ou mora dos valores devidos no
                    âmbito deste Termo Constitutivo, a Emissora estará obrigada
                    a pagar ao titular, cumulativamente, além da quantia
                    correspondente à dívida em aberto, os seguintes encargos:{" "}
                    {"\n\n"}
                    <View style={styles.row}>
                      <Text style={styles.textBoldUnderline}>
                        Renúncia
                        {"\n\n"}
                      </Text>
                      <Text style={styles.text3}>
                        Não se presume a renúncia a qualquer dos direitos
                        decorrentes da Nota Comercial. Desta forma, nenhum
                        atraso, omissão ou liberalidade no exercício de qualquer
                        direito, faculdade ou prerrogativa que caiba ao Titular,
                        em razão de qualquer inadimplemento da Emissora,
                        prejudicará o exercício de tais direitos, faculdades ou
                        remédios, ou será interpretado como constituindo uma
                        renúncia aos mesmos ou concordância com tal
                        inadimplemento, nem constituirá novação ou modificação
                        de quaisquer outras obrigações assumidas pela Emissora
                        neste instrumento, ou precedente no tocante a qualquer
                        outro inadimplemento ou atraso.{"\n\n"}
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Text style={styles.textBoldUnderline}>
                        Título Executivo Extrajudicial e Execução Específica
                        {"\n\n"}
                      </Text>
                      <Text style={styles.text3}>
                        Este instrumento e as Notas Comerciais constituem
                        títulos executivos extrajudiciais, nos termos dos
                        incisos I e III do artigo 784 do Código de Processo
                        Civil, reconhecendo as Partes desde já que,
                        independentemente de quaisquer outras medidas cabíveis,
                        as obrigações assumidas nos termos deste instrumento e
                        com relação às Notas Comerciais estão sujeitas à
                        execução específica, submetendo-se às disposições dos
                        artigos 815 e seguintes do Código de Processo Civil, sem
                        prejuízo do direito de declarar o vencimento antecipado
                        das Notas Comerciais, nos termos deste instrumento.
                        {"\n\n"}
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Text style={styles.textBoldUnderline}>
                        Da Outorga de Poderes
                        {"\n\n"}
                      </Text>
                      <Text style={styles.text3}>
                        A Emissora, neste ato, em caráter irrevogável e
                        irretratável, autoriza a central depositária de valores
                        mobiliários a cadastrar o presente Termo Constitutivo na
                        Plataforma IMF Digital, para fins de cumprimento do
                        cadastro de instrumento financeiro, bem como criar
                        eventuais usuários, se e conforme solicitados de maneira
                        formalizada, para acesso a citada plataforma, em
                        consonância aos seus normativos, bem como representá-la
                        perante os demais prestadores de serviço envolvidos na
                        Emissão, inclusive contratá-los em seu nome, desde que a
                        obrigatoriedade de pagamento seja do grupo Laqus{"\n\n"}
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Text style={styles.textBoldUnderline}>
                        Irrevogabilidade
                        {"\n\n"}
                      </Text>
                      <Text style={styles.text3}>
                        As Notas Comerciais e o presente instrumento são
                        celebrados em caráter irrevogável e irretratável,
                        obrigando as partes e seus sucessores a qualquer título.
                        {"\n\n"}
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Text style={styles.textBoldUnderline}>
                        Cômputo do Prazo
                        {"\n\n"}
                      </Text>
                      <Text style={styles.text3}>
                        Exceto se de outra forma especificamente disposto neste
                        instrumento, os prazos estabelecidos no presente
                        instrumento serão computados de acordo com a regra
                        prescrita no artigo 132 do Código Civil, sendo excluído
                        o dia do começo e incluído o do vencimento.
                        {"\n\n"}
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Text style={styles.textBoldUnderline}>
                        Assinatura Eletrônica
                        {"\n\n"}
                      </Text>
                      <Text style={styles.text3}>
                        Para os fins do artigo 10, parágrafo 2º, da Medida
                        Provisória nº 2.200-2, de 24 de agosto de 2001, as
                        Partes acordam e aceitam que este instrumento e qualquer
                        aditamento podem ser assinados eletronicamente por meio
                        de plataforma de assinatura, com certificados digitais
                        emitidos pela ICP-Brasil, e tais assinaturas eletrônicas
                        serão legítimas e suficientes para comprovar (i) a
                        identidade de cada representante legal, (ii) a vontade
                        de cada Parte em firmar este instrumento e qualquer
                        aditamento, e (iii) a integridade deste instrumento e
                        qualquer alteração.
                        {"\n\n"}
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Text style={styles.textBoldUnderline}>
                        Boa-fé e Equidade
                        {"\n\n"}
                      </Text>
                      <Text style={styles.text3}>
                        As Partes declaram, mútua e expressamente, que este
                        instrumento foi celebrado respeitando-se os princípios
                        de probidade e de boa-fé, por livre, consciente e firme
                        manifestação de vontade das Partes e em perfeita relação
                        de equidade.
                        {"\n\n"}
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Text style={styles.textBoldUnderline}>
                        Lei Aplicável
                        {"\n\n"}
                      </Text>
                      <Text style={styles.text3}>
                        Este instrumento é regido pelas Leis da República
                        Federativa do Brasil.
                        {"\n\n"}
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Text style={styles.textBoldUnderline}>
                        Foro
                        {"\n\n"}
                      </Text>
                      <Text style={styles.text3}>
                        Fica eleito o foro da Comarca da Cidade de São Paulo,
                        Estado de São Paulo, para dirimir quaisquer dúvidas ou
                        controvérsias oriundas deste instrumento, com renúncia a
                        qualquer outro, por mais privilegiado que seja.{"\n\n"}
                        São Paulo/SP, {pDay} de {pMonth} de {year}.{"\n\n"}
                      </Text>
                    </View>
                  </Text>
                  <Text style={styles.h1}>
                    PÁGINA DE ASSINATURAS DO TERMO CONSTITUTIVO DE NOTA
                    COMERCIAL DA {emissionControls.indexOf(emissionControl) + 1}
                    ª ({emissionControl.toUpperCase()}) EMISSÃO DE NOTAS
                    COMERCIAIS, EM SÉRIE {serie.toUpperCase()}, PARA COLOCAÇÃO
                    PRIVADA, DO(A) {String(emissor.razaoSocial).toUpperCase()}.
                    {"\n\n"}
                    <Text style={styles.centerH1}>
                      <View style={styles.row}>
                        <Text style={styles.textBold}>
                          {"\n\n"}
                          ________________________________________________________________
                          {"\n\n"}
                        </Text>
                        <Text style={styles.textBold}>
                          {String(emissor.razaoSocial).toUpperCase()}
                          {"\n"}
                          <Text style={styles.text3}>
                            Na qualidade de Emissora
                          </Text>
                        </Text>
                      </View>
                    </Text>
                  </Text>
                  <Text style={styles.h1}>
                    TESTEMUNHAS:
                    {"\n\n"}
                    <Text style={styles.centerH1}></Text>
                  </Text>
                  <View style={styles.view3}>
                    <View>
                      <Text style={styles.textBold}>
                        ___________________________________
                        {"\n\n"}
                      </Text>
                      <Text style={styles.text6}>
                        Nome: [--]{"\n"}
                        <Text style={styles.text6}>CPF/ME nº.: [--]</Text>
                      </Text>
                    </View>
                    <View>
                      <Text style={styles.textBold}>
                        ___________________________________
                        {"\n\n"}
                      </Text>
                      <Text style={styles.text6}>
                        Nome: [--]{"\n"}
                        <Text style={styles.text6}>CPF/ME nº.: [--]</Text>
                      </Text>
                    </View>
                  </View>
                </View>
              </Page>
              {chunkedCalcData.map((chunk, pageIndex) => (
                <>
                  <Page style={styles.page}>
                    <View style={styles.section}>
                      {isFirstPage && (
                        <View>
                          <Text
                            style={[
                              styles.textBold,
                              {
                                textAlign: "center",
                                textDecoration: "underline",
                                marginBottom: "20px",
                                fontSize: 12,
                              },
                            ]}
                          >
                            ANEXO I - MODELO DE BOLETIM DE SUBSCRIÇÃO DAS NOTAS
                            COMERCIAIS
                          </Text>{" "}
                          <View style={styles.tableContainer}>
                            <View style={styles.row}>
                              <Text style={styles.cell2}>
                                <Text style={styles.centerH1}>
                                  BOLETIM DE SUBSCRIÇÃO DA{" "}
                                  {emissionControls.indexOf(emissionControl) +
                                    1}
                                  ª ({emissionControl.toUpperCase()}) EMISSÃO DE
                                  NOTAS COMERCIAIS, EM SÉRIE{" "}
                                  {serie.toUpperCase()}, PARA COLOCAÇÃO PRIVADA,
                                  DO(A){" "}
                                  {String(emissor.razaoSocial).toUpperCase()}.
                                </Text>
                              </Text>
                            </View>
                            <View style={styles.row}>
                              <Text style={styles.cell2}>
                                <Text style={styles.centerH1}>
                                  CARACTERÍSTICAS DA EMISSÃO
                                </Text>
                              </Text>
                            </View>
                            <View style={styles.row}>
                              <Text style={styles.cell2}>
                                <Text style={styles.text3}>
                                  Emissão, pela {emissor.razaoSocial}, pessoa
                                  jurídica com sede na Cidade de{" "}
                                  {emissor.cidade}, {emissor.estado}, na(o){" "}
                                  {emissor.rua}, nº {emissor.numero},{" "}
                                  {emissor.complemento}, CEP {emissor.cep},
                                  inscrita no CNPJ/ME sob o nº{" "}
                                  {formatCnpj(emissor.cnpj)} ("Companhia"), de{" "}
                                  {series.indexOf(serie) + 1} quantidades, com
                                  valor nominal unitário de{" "}
                                  {getNominalUnitValue().toLocaleString(
                                    "pt-BR",
                                    {
                                      style: "currency",
                                      currency: "BRL",
                                    }
                                  )}{" "}
                                  e vencimento em{" "}
                                  {String(lastInstallment[1]).padStart(2, "0")}{" "}
                                  de {lastInstallment[2]} de{" "}
                                  {String(lastInstallment[3])}, da sua{" "}
                                  {emissionControls.indexOf(emissionControl) +
                                    1}
                                  ª ({emissionControl}) emissão ("Notas
                                  Comerciais" e "Emissão")
                                </Text>
                              </Text>
                            </View>
                            <View style={styles.row}>
                              <Text style={styles.cell2}>
                                <Text style={styles.centerH1}>
                                  QUALIFICAÇÃO DO SUBSCRITOR{" "}
                                </Text>
                              </Text>
                            </View>
                            <View style={styles.row}>
                              <Text style={styles.cell2}>
                                <Text style={styles.text3}>
                                  Nome / Razão Social: {investidor.razaoSocial}
                                </Text>
                              </Text>
                              <Text style={styles.cell2}>
                                <Text style={styles.text3}>
                                  CNPJ /ME ou CPF/ME nº{" "}
                                  {formatCnpj(investidor.cnpj)}
                                </Text>
                              </Text>
                            </View>
                            <View style={styles.row}>
                              <Text style={styles.cell2}>
                                <Text style={styles.text3}>
                                  Endereço: {investidor.rua}
                                </Text>
                              </Text>
                              <Text style={styles.cell2}>
                                <Text style={styles.text3}>
                                  Nº {investidor.numero}
                                </Text>
                              </Text>
                              <Text style={styles.cell2}>
                                <Text style={styles.text3}>
                                  Complemento: {investidor.complemento}
                                </Text>
                              </Text>
                            </View>
                            <View style={styles.row}>
                              <Text style={styles.cell2}>
                                <Text style={styles.text3}>
                                  Bairro: {investidor.bairro}
                                </Text>
                              </Text>
                              <Text style={styles.cell2}>
                                <Text style={styles.text3}>
                                  CEP: {investidor.cep}
                                </Text>
                              </Text>
                              <Text style={styles.cell2}>
                                <Text style={styles.text3}>
                                  Cidade: {investidor.cidade}
                                </Text>
                              </Text>
                              <Text style={styles.cell2}>
                                <Text style={styles.text3}>
                                  U.F.: {investidor.estado}
                                </Text>
                              </Text>
                              <Text style={styles.cell2}>
                                <Text style={styles.text3}>País: Brasil</Text>
                              </Text>
                              <Text style={styles.cell2}>
                                <Text style={styles.text3}>
                                  Telefone: {investidor.telefone}
                                </Text>
                              </Text>
                            </View>
                            <View style={styles.row}>
                              <Text style={styles.cell2}>
                                <Text style={styles.centerH1}>
                                  NOTAS COMERCIAIS SUBSCRITAS:{" "}
                                </Text>
                              </Text>
                            </View>
                            <View style={styles.row}>
                              <Text style={styles.cell2}>
                                <Text style={styles.centerH1}>QUANTIDADE</Text>
                              </Text>
                              <Text style={styles.cell2}>
                                <Text style={styles.centerH1}>
                                  PREÇO TOTAL UNITÁRIO
                                </Text>
                              </Text>
                              <Text style={styles.cell2}>
                                <Text style={styles.centerH1}>VALOR EM R$</Text>
                              </Text>
                            </View>
                            <View style={styles.view4}>
                              <Text style={styles.cell2}>
                                <Text style={styles.text3}>
                                  {series.indexOf(serie) + 1} (
                                  {series[series.indexOf(serie)]})
                                </Text>
                              </Text>
                              <Text style={styles.cell2}>
                                <Text style={styles.text3}>
                                  {getNominalUnitValue().toLocaleString(
                                    "pt-BR",
                                    {
                                      style: "currency",
                                      currency: "BRL",
                                    }
                                  )}
                                </Text>
                              </Text>
                              <Text style={styles.cell2}>
                                <Text style={styles.text7}>
                                  {formData.total.toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  })}
                                </Text>
                              </Text>
                            </View>
                            <View style={styles.view4}>
                              <Text style={styles.cell2}>
                                <Text style={styles.text3}>
                                  O Preço Total Unitário será mantido em cada
                                  data de integralização, caso distintas. [A
                                  rentabilidade se inicia apenas após a efetiva
                                  integralização.]
                                </Text>
                              </Text>
                            </View>
                          </View>
                          <Text
                            style={[
                              styles.textBold,
                              {
                                textAlign: "center",
                                textDecoration: "underline",
                                marginBottom: "20px",
                                fontSize: 12,
                              },
                            ]}
                          >
                            FORMA SUBSCRIÇÃO E INTEGRALIZAÇÃO
                          </Text>{" "}
                          <View style={styles.tableContainer}>
                            <View style={styles.row}>
                              <Text style={styles.cell2}>
                                <Text style={styles.centerH1}>
                                  MEIO DE INTEGRALIZAÇÃO:{" "}
                                </Text>
                                {"\n\n"}
                                <Text style={styles.text3}>
                                  {emissor.meioPagamento}
                                </Text>
                              </Text>
                              <Text style={styles.cell2}>
                                <Text style={styles.centerH1}>
                                  DATA DE INTEGRALIZAÇÃO:{" "}
                                </Text>
                                {"\n\n"}
                                <Text style={styles.text3}>
                                  A efetiva integralização deverá ser realizada
                                  em até 30 (trinta) dias corridos, contados da
                                  data de assinatura do presente Boletim de
                                  Subscrição.
                                </Text>
                              </Text>
                            </View>
                          </View>
                          <Text
                            style={[
                              styles.textBold,
                              {
                                textAlign: "center",
                                textDecoration: "underline",
                                marginBottom: "20px",
                                fontSize: 12,
                              },
                            ]}
                          >
                            CONTA BANCÁRIA DA EMISSORA{" "}
                          </Text>{" "}
                          <View style={styles.tableContainer}>
                            <View style={styles.row}>
                              <Text style={styles.cell2}>
                                <Text style={styles.centerH1}>Banco</Text>
                              </Text>
                              <Text style={styles.cell2}>
                                <Text style={styles.centerH1}>
                                  Nº do Banco{" "}
                                </Text>
                              </Text>
                              <Text style={styles.cell2}>
                                <Text style={styles.centerH1}>
                                  Nº da Agência
                                </Text>
                              </Text>
                              <Text style={styles.cell2}>
                                <Text style={styles.centerH1}>Nº da Conta</Text>
                              </Text>
                            </View>
                            <View style={styles.view4}>
                              <Text style={styles.cell2}>
                                <Text style={styles.text3}>
                                  {emissor.banco}
                                </Text>
                              </Text>
                              <Text style={styles.cell2}>
                                <Text style={styles.text3}>
                                  {emissor.bankNumber}
                                </Text>
                              </Text>
                              <Text style={styles.cell2}>
                                <Text style={styles.text7}>
                                  {emissor.agency}
                                </Text>
                              </Text>
                              <Text style={styles.cell2}>
                                <Text style={styles.text7}>
                                  {emissor.conta}
                                </Text>
                              </Text>
                            </View>
                          </View>
                          <Text
                            style={[
                              styles.textBold,
                              {
                                textAlign: "center",
                                textDecoration: "underline",
                                marginBottom: "20px",
                                fontSize: 12,
                              },
                            ]}
                          >
                            CONTA BANCÁRIA DA CENTRAL DEPOSITÁRIA
                          </Text>
                          <View style={styles.tableContainer}>
                            <View style={styles.row}>
                              <Text style={styles.cell2}>
                                <Text style={styles.centerH1}>Banco</Text>
                              </Text>
                              <Text style={styles.cell2}>
                                <Text style={styles.centerH1}>
                                  Nº do Banco{" "}
                                </Text>
                              </Text>
                              <Text style={styles.cell2}>
                                <Text style={styles.centerH1}>
                                  Nº da Agência
                                </Text>
                              </Text>
                              <Text style={styles.cell2}>
                                <Text style={styles.centerH1}>Nº da Conta</Text>
                              </Text>
                            </View>
                            <View style={styles.view4}>
                              <Text style={styles.cell2}>
                                <Text style={styles.text3}>Itaú</Text>
                              </Text>
                              <Text style={styles.cell2}>
                                <Text style={styles.text3}>341</Text>
                              </Text>
                              <Text style={styles.cell2}>
                                <Text style={styles.text7}>9170</Text>
                              </Text>
                              <Text style={styles.cell2}>
                                <Text style={styles.text7}>11533-2</Text>
                              </Text>
                            </View>
                          </View>
                          <Text
                            style={[
                              styles.textBold,
                              {
                                textAlign: "center",
                                textDecoration: "underline",
                                marginBottom: "20px",
                                fontSize: 12,
                              },
                            ]}
                          >
                            CONTA BANCÁRIA DO TITULAR{" "}
                          </Text>
                          <View style={styles.tableContainer}>
                            <View style={styles.row}>
                              <Text style={styles.cell2}>
                                <Text style={styles.centerH1}>Banco</Text>
                              </Text>
                              <Text style={styles.cell2}>
                                <Text style={styles.centerH1}>
                                  Nº do Banco{" "}
                                </Text>
                              </Text>
                              <Text style={styles.cell2}>
                                <Text style={styles.centerH1}>
                                  Nº da Agência
                                </Text>
                              </Text>
                              <Text style={styles.cell2}>
                                <Text style={styles.centerH1}>Nº da Conta</Text>
                              </Text>
                            </View>
                            <View style={styles.view4}>
                              <Text style={styles.cell2}>
                                <Text style={styles.text3}>
                                  {investidor.banco}
                                </Text>
                              </Text>
                              <Text style={styles.cell2}>
                                <Text style={styles.text3}>
                                  {investidor.numeroDoBanco}
                                </Text>
                              </Text>
                              <Text style={styles.cell2}>
                                <Text style={styles.text7}>
                                  {investidor.agencia}
                                </Text>
                              </Text>
                              <Text style={styles.cell2}>
                                <Text style={styles.text7}>
                                  {investidor.conta}
                                </Text>
                              </Text>
                            </View>
                          </View>
                          <View style={styles.tableContainer} break>
                            <View style={styles.row}>
                              <Text style={styles.text8}>
                                Declaro, para todos os fins, que estou de acordo
                                com as condições expressas no presente boletim,
                                bem como declaro ter obtido exemplar do Termo
                                Constitutivo.
                                {"\n\n"}
                                <Text style={styles.text7}>
                                  São Paulo/SP, {day} de {pMonth} de {year}.
                                </Text>
                              </Text>
                            </View>
                            <View style={styles.view2}>
                              <Text style={styles.centerH1}>
                                ___________________________________
                                {"\n\n"}
                              </Text>
                              <Text style={styles.text6}>
                                Assinatura do Subscritor ou Representante Legal
                                {"\n\n"}
                              </Text>
                            </View>
                          </View>
                        </View>
                      )}
                    </View>
                    {isFirstPage &&
                      (pageIndex === 0 ? (isFirstPage = false) : null)}
                  </Page>
                  <Page style={styles.page}>
                    <View style={styles.section}>
                      <View>
                        <Text
                          style={[
                            styles.textBold,
                            {
                              textAlign: "center",
                              textDecoration: "underline",
                              marginBottom: "20px",
                              fontSize: 12,
                            },
                          ]}
                        >
                          ANEXO II – PLANO DE PAGAMENTO
                        </Text>
                        <View style={styles.downTable}>
                          <View style={styles.tr}>
                            <Text
                              style={[
                                styles.centralizadoTh,
                                {
                                  borderRightWidth: "1px",
                                  borderRightColor: "black",
                                  width: "70px",
                                },
                              ]}
                            >
                              Período
                            </Text>
                            <Text
                              style={[
                                styles.centralizadoTh,
                                {
                                  borderRightWidth: "1px",
                                  borderRightColor: "black",
                                  width: "82px",
                                },
                              ]}
                            >
                              Vencimento
                            </Text>
                            <Text
                              style={[
                                styles.centralizadoTh,
                                {
                                  borderRightWidth: "1px",
                                  borderRightColor: "black",
                                  width: "90px",
                                },
                              ]}
                            >
                              Parcela
                            </Text>
                            <Text
                              style={[
                                styles.centralizadoTh,
                                {
                                  borderRightWidth: "1px",
                                  borderRightColor: "black",
                                  width: "91px",
                                },
                              ]}
                            >
                              Amortização
                            </Text>
                            <Text
                              style={[
                                styles.centralizadoTh,
                                {
                                  borderRightWidth: "1px",
                                  borderRightColor: "black",
                                  width: "88px",
                                },
                              ]}
                            >
                              Juros
                            </Text>
                            <Text
                              style={[
                                styles.centralizadoTh,
                                {
                                  borderRightWidth: "1px",
                                  borderRightColor: "black",
                                  width: "100px",
                                },
                              ]}
                            >
                              Saldo Devedor
                            </Text>
                          </View>
                        </View>
                        <View style={styles.tr}>
                          <Text
                            style={[
                              styles.centralizado,
                              {
                                borderLeftWidth: "1px",
                                borderRightWidth: "1px",
                                borderRightColor: "black",
                                width: "71px",
                              },
                            ]}
                          >
                            0
                          </Text>
                          <Text
                            style={[
                              styles.centralizado,
                              {
                                borderRightWidth: "1px",
                                borderRightColor: "black",
                                width: "82px",
                              },
                            ]}
                          >
                            {now.getDate().toString().padStart(2, "0")}/
                            {(now.getMonth() + 1).toString().padStart(2, "0")}/
                            {now.getFullYear()}
                          </Text>
                          <Text
                            style={[
                              styles.alinhamentoDireitaTd,
                              { width: "90px" },
                            ]}
                          >
                            0
                          </Text>
                          <Text
                            style={[
                              styles.alinhamentoDireitaTd,
                              { width: "91px" },
                            ]}
                          >
                            0
                          </Text>
                          <Text
                            style={[
                              styles.alinhamentoDireitaTd,
                              { width: "88px" },
                            ]}
                          >
                            0
                          </Text>
                          <Text
                            style={[
                              styles.alinhamentoDireitaTd,
                              { width: "100px" },
                            ]}
                          >
                            {formData.total}
                          </Text>
                        </View>
                      </View>
                      {chunk.map((data, index) => (
                        <View
                          key={index}
                          style={[styles.tr, { borderTopWidth: "1px" }]}
                        >
                          <Text
                            style={[
                              styles.centralizado,
                              {
                                borderLeftWidth: "1px",
                                borderRightWidth: "1px",
                                borderRightColor: "black",
                                width: "71px",
                              },
                            ]}
                          >
                            {data[0]}
                          </Text>
                          <Text
                            style={[
                              styles.centralizado,
                              {
                                borderRightWidth: "1px",
                                borderRightColor: "black",
                                width: "82px",
                              },
                            ]}
                          >
                            {data[1] < 10 ? `0${data[1]}` : data[1]}/
                            {data[2] === "00" ? 12 : data[2]}/{data[3]}
                          </Text>
                          <Text
                            style={[
                              styles.alinhamentoDireitaTd,
                              { width: "90px" },
                            ]}
                          >
                            {data[4].toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </Text>
                          <Text
                            style={[
                              styles.alinhamentoDireitaTd,
                              { width: "91px" },
                            ]}
                          >
                            {data[5].toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </Text>
                          <Text
                            style={[
                              styles.alinhamentoDireitaTd,
                              { width: "88px" },
                            ]}
                          >
                            {data[6].toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </Text>
                          <Text
                            style={[
                              styles.alinhamentoDireitaTd,
                              { width: "100px" },
                            ]}
                          >
                            {data[7].toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </Text>
                        </View>
                      ))}
                    </View>
                  </Page>
                </>
              ))}
            </Document>
          </PDFViewer>
        )}
        {showPDF && (
          <PostNote onNotePosted={() => {}} dados={{ emissionControl }} />
        )}
      </Container>
    </Box>
  );
};

export default Minuta;
