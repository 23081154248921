import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Emissors from "../components/Emissors";
import DevedorSolidario from "../components/DevedorSolidario";
import { BsArrowLeft } from "react-icons/bs";
import { formatDocument } from "../Modals/UpdateEmissor";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { cadastrarEmissorRequest } from "../redux/actions";
import { formatarTelefone } from "./Investidor";
import { formatCEP } from "../Modals/UpdateInvestor";
import { formatCnpj, formatCpf, formatFileSize } from "../utils/tools";
import { IoIosCloudUpload } from "react-icons/io";

const Emissor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const emissor = JSON.parse(localStorage.getItem("emissor"));
  const storedData = JSON.parse(localStorage.getItem("notaComercial"));

  const [dadosBancarios, setDadosBancarios] = useState("");
  const [conta, setConta] = useState("");
  const [agency, setAgency] = useState("");
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState("");
  const [bankNumber, setBankNumber] = useState("");
  const [devedor, setDevedor] = useState(true);
  const [fiel, setFiel] = useState(emissor ? emissor.fielDepositario : false);
  const [avalista, setAvalista] = useState(false);
  const [dRazao, setDRazao] = useState("");
  const [dCnpj, setDCnpj] = useState("");
  const [dTelefone, setDTelefone] = useState("");
  const [custodiante, setCustodiante] = useState(false);
  const [dCustodiante, setDCustodiante] = useState({
    nome: "",
    telefone: "",
    email: "",
    rua: "",
    numero: "",
    complemento: "",
    bairro: "",
    cidade: "",
    estado: "",
    cep: "",
  });
  const [dCep, setDCep] = useState("");
  const [dRua, setDRua] = useState("");
  const [dCidade, setDCidade] = useState("");
  const [dEstado, setDEstado] = useState("");
  const [dNumero, setDNumero] = useState("");
  const [dBairro, setDBairro] = useState("");
  const [dComplemento, setDComplemento] = useState("");
  const [dEmail, setDEmail] = useState("");
  const [representante, setRepresentante] = useState("");
  const [dCpf, setDCpf] = useState("");
  const [cargo, setCargo] = useState("");

  const [dAdress, setDAdress] = useState("");
  const [fName, setFName] = useState("");
  const [fOcupacao, setFOcupacao] = useState("");
  const [fCep, setFCep] = useState("");
  const [fRua, setFRua] = useState("");
  const [fCidade, setFCidade] = useState("");
  const [fEstado, setFEstado] = useState("");
  const [fNumero, setFNumero] = useState("");
  const [fComplemento, setFComplemento] = useState("");
  const [fCpf, setFCpf] = useState("");
  const [fCnpj, setFCnpj] = useState("");
  const [fRg, setFRg] = useState("");
  const [fEmail, setFEmail] = useState("");
  const [avalistas, setAvalistas] = useState([
    {
      aNome: "",
      aCpf: "",
      aEmail: "",
      aCep: "",
      aRua: "",
      aCidade: "",
      aEstado: "",
      aNumero: "",
      aComplemento: "",
      aProfissao: "",
      aNacionalidade: "",
      aEstadoCivil: "",
      aDataNascimento: "",
      aCNH: "",
      aResidencia: "",
      aCasamento: "",
      documentos: [],
    },
  ]);
  const [meioPagamento, setMeioPagamento] = useState(
    "[X] Transferência Eletrônica Disponível (TED)"
  );
  const [erroValidacao, setErroValidacao] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [tipo, setTipo] = useState("PF"); // Inicialmente, define como pessoa física (PF)
  const [solidario, setSolidario] = useState(false);
  const [cadastrados, setCadastrados] = useState(true);
  const [devedorInfo, setDevedorInfo] = useState({});
  const [eFaturamento, setEFaturamento] = useState("");
  const [eSocial, setESocial] = useState("");
  const [fCNH, setFCNH] = useState("");
  const [fResidencia, setFResidencia] = useState("");
  const [fCasamento, setFCasamento] = useState("");

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const updateFormData = (newData) => {
    setDevedorInfo(newData);
  };
  useEffect(() => {
    handleExpand();
  }, []);
  //const [loading, setLoading] = useState(false);

  const keywordsToPrioritize = [
    "caixa eco",
    "itaú uni",
    "bco do brasil",
    "bradesco s",
    "santander",
    "original",
    "banco inter",
    "bdigital",
  ];

  const sortedBanks = [...banks].sort((a, b) => {
    const bankNameA = a.name ? a.name.toLowerCase() : "";
    const bankNameB = b.name ? b.name.toLowerCase() : "";

    const priorityA = getPriority(bankNameA);
    const priorityB = getPriority(bankNameB);

    if (priorityA !== priorityB) {
      return priorityA - priorityB; // Coloque o banco com prioridade maior primeiro
    } else {
      return bankNameA.localeCompare(bankNameB); // Ordem alfabética padrão
    }
  });
  const toggleTipo = () => {
    setTipo(tipo === "PF" ? "PJ" : "PF");
  };
  const toggleSolidario = () => {
    setSolidario(!solidario);
  };
  function getPriority(name) {
    for (let i = 0; i < keywordsToPrioritize.length; i++) {
      if (name.includes(keywordsToPrioritize[i])) {
        return i;
      }
    }
    return keywordsToPrioritize.length;
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    fetch("https://brasilapi.com.br/api/banks/v1")
      .then((response) => response.json())
      .then((data) => setBanks(data))
      .catch((error) =>
        console.error("Ocorreu um erro ao buscar os bancos:", error)
      );
    setDadosBancarios(
      `Banco: ${selectedBank}-${bankNumber}/Agencia: ${agency}/Conta: ${conta}`
    );
  }, []);

  const handleBankChange = (event) => {
    const selectedBankName = event.target.value;
    setSelectedBank(selectedBankName);
    const selectedBankData = banks.find(
      (bank) => bank.name === selectedBankName
    );

    if (selectedBankData) {
      setBankNumber(selectedBankData.code);
    } else if (selectedBankName === "BNK Digital") {
      setBankNumber(547);
    } else {
      setBankNumber("");
    }
  };

  const handleBankNumberChange = (event) => {
    const bankNumber = event.target.value;
    setBankNumber(bankNumber);

    const selectedBankData = banks.find((bank) => bank.code == bankNumber);

    if (selectedBankData) {
      setSelectedBank(selectedBankData.name);
    } else {
      setSelectedBank("");
    }
  };

  const handleSearch = async (cep) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
      const data = response.data;

      setFRua(data.logradouro || "");
      setFNumero("");
      setFComplemento("");
      setFCidade(data.localidade || "");
      setFEstado(data.uf || "");
    } catch (error) {
      console.error("Erro ao buscar endereço:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCepChange = (e) => {
    const newCep = e?.target.value?.replace(/\D/g, "");
    setFCep(newCep);

    if (newCep.length === 8) {
      handleSearch(newCep);
    }
  };

  const toggleDevedorVisibility = () => {
    setDevedor(!devedor);
  };

  const handleAvalistaChange = (e, index, field) => {
    const newAvalistas = [...avalistas];
    newAvalistas[index][field] = e.target.value;
    setAvalistas(newAvalistas);
    console.log(newAvalistas);
    console.log(avalistas);
  };

  const removeAvalista = (indexToRemove) => {
    const updatedAvalistas = [...avalistas];
    updatedAvalistas.splice(indexToRemove, 1); // Remove o avalista pelo índice
    setAvalistas(updatedAvalistas);
  };

  const toggleFielVisibility = () => {
    setFiel(!fiel);
  };

  const toggleCadastrados = () => {
    setCadastrados(!cadastrados);
  };

  const toggleAvalistaVisibility = () => {
    setAvalista(!avalista);
  };

  const handleSearch2 = async () => {
    try {
      setIsLoading2(true);
      const response = await axios.get(
        `https://viacep.com.br/ws/${dCep}/json/`
      );
      setDAdress(response.data);
      setDBairro(response.data.bairro);
      setDCidade(response.data.localidade);
      setDEstado(response.data.uf);
      setDRua(response.data.logradouro);
    } catch (error) {
      console.error("Error fetching address:", error);
    } finally {
      setIsLoading2(false);
    }
  };

  const handleSearch3 = async () => {
    try {
      const response = await axios.get(
        `https://viacep.com.br/ws/${dCustodiante.cep}/json/`
      );

      setDCustodiante((prevState) => ({
        ...prevState,
        bairro: response.data.bairro,
        cidade: response.data.localidade,
        estado: response.data.uf,
        rua: response.data.logradouro,
      }));
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  const addAvalista = () => {
    setAvalistas([...avalistas, { aNome: "", aCpf: "", aEmail: "" }]);
  };
  const validateFields = (data) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        if (key === "complemento") {
          continue;
        }
        if (key === "garantia") {
          continue;
        }
        if (key === "gTipo") {
          continue;
        }
        if (key === "gNatureza") {
          continue;
        }
        if (key === "gDescricao") {
          continue;
        }
        if (key === "gDocumento") {
          continue;
        }
        if (key === "gProprietario") {
          continue;
        }
        if (key === "gQuantidade") {
          continue;
        }
        if (key === "gValor") {
          continue;
        }
        if (!value || (typeof value === "string" && value === "")) {
          return `O campo ${key} não pode estar vazio.`;
        }
      }
    }

    if (custodiante) {
      if (Object.values(dCustodiante).some((value) => value === "")) {
        return "Todos os dados do custodiante devem ser preenchido, se o mesmo for adicionado.";
      }
    }

    return null; // Todos os campos estão preenchidos
  };

  const sendInfos = async () => {
    console.log(
      avalistas,
      "avalistosaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa"
    );
    const dados = {
      userRef: user.data.email,
      razaoSocial: dRazao,
      cnpj: dCnpj,
      telefone: dTelefone,
      email: dEmail,
      cpf: dCpf,
      representante,
      cargo,
      banco: selectedBank,
      bankNumber,
      conta,
      agency,
      cep: dCep,
      rua: dRua,
      numero: dNumero,
      bairro: dBairro,
      cidade: dCidade,
      estado: dEstado,
      complemento: dComplemento,
      fielDepositario: {
        nome: fName,
        ocupacao: fOcupacao,
        cep: fCep,
        rua: fRua,
        numero: fNumero,
        complemento: fComplemento,
        cidade: fCidade,
        estado: fEstado,
        cpf: fCpf,
        rg: fRg,
        email: fEmail,
      },
      devedorSolidario: devedorInfo,
      avalistas,
      meioPagamento,
      custodiante: custodiante ? dCustodiante : null,
    };
    const emissorLaqus = {
      cnpj: dCnpj,
      razaoSocial: dRazao,
      complemento: dComplemento,
      cep: dCep,
      logradouro: dRua,
      numero: dNumero,
      bairro: dBairro,
      cidade: dCidade,
      uf: dEstado,
      atividadePrincipal: cargo,
      faturamentoMedioMensal12Meses: "",
      razaoSocialBancoLiquidante: selectedBank,
      ispbBancoLiquidante: "",
      agenciaBancoLiquidante: agency,
      contaBancoLiquidante: conta,
      telefones: "",
    };
    const resultadoValidacao = validateFields(dados);
    if (resultadoValidacao) {
      setErroValidacao(resultadoValidacao);
      return;
    }
    setErroValidacao(null);

    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/emissor",
      dados,
      {
        headers: {
          "Content-Length": JSON.stringify(dados).length,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 201) {
      console.log("Emissor criado com sucesso!");
      dispatch(cadastrarEmissorRequest(emissorLaqus));
      console.log(emissorLaqus, "dados enviou pro redux");
      toggleCadastrados();
    }
    localStorage.setItem("emissorPreenchido", true);
    localStorage.setItem("emissor", JSON.stringify(dados));
    window.alert("Emissor Cadastrado!");
  };

  const handleSubmitEmissor = async (e) => {
    const dados = {
      userRef: user.data.email,
      razaoSocial: dRazao,
      cnpj: dCnpj,
      email: dEmail,
      cpf: dCpf,
      representante,
      cargo,
      banco: selectedBank,
      bankNumber,
      conta,
      agency,
      cep: dCep,
      rua: dRua,
      numero: dNumero,
      bairro: dBairro,
      cidade: dCidade,
      estado: dEstado,
      complemento: dComplemento,
      fielDepositario: {
        nome: fName,
        ocupacao: fOcupacao,
        cep: fCep,
        rua: fRua,
        numero: fNumero,
        complemento: fComplemento,
        cidade: fCidade,
        estado: fEstado,
        cpf: fCpf,
        rg: fRg,
        email: fEmail,
      },
      devedorSolidario: devedorInfo,
      avalistas,
      meioPagamento,
    };
    const formData = new FormData();
    formData.append("userRef", user.data.email);
    formData.append("razaoSocial", dRazao);
    formData.append("cnpj", dCnpj);
    formData.append("email", dEmail);
    formData.append("cpf", dCpf);
    formData.append("representante", representante);
    formData.append("cargo", cargo);
    formData.append("banco", selectedBank);
    formData.append("bankNumber", bankNumber);
    formData.append("cep", dCep);
    formData.append("rua", dRua);
    formData.append("numero", dNumero);
    formData.append("bairro", dBairro);
    formData.append("cidade", dCidade);
    formData.append("estado", dEstado);
    formData.append("complemento", dEstado);
    formData.append("faturamento", eFaturamento);
    formData.append("social", eSocial);
    formData.append("qtdAval", avalistas.length);
    formData.append("conta", conta);
    formData.append("agency", agency);
    formData.append("custodiante", dCustodiante);
    //  formData.append(avalistas);
    const fielDepositario = {
      nome: fName,
      ocupacao: fOcupacao,
      cep: fCep,
      rua: fRua,
      numero: fNumero,
      complemento: fComplemento,
      cidade: fCidade,
      estado: fEstado,
      cpf: fCpf,
      rg: fRg,
      email: fEmail,
      fCNH: fCNH,
      fResidencia: fResidencia,
      fcasamento: fCasamento,
    };
    for (const [key, value] of Object.entries(fielDepositario)) {
      formData.append(`fielDepositario[${key}]`, value);
    }
    avalistas.forEach((avalista, index) => {
      for (const [key, value] of Object.entries(avalista)) {
        formData.append(`avalistas[${index}][${key}]`, value);
      }
    });
    const resultadoValidacao = validateFields(dados);
    if (resultadoValidacao) {
      setErroValidacao(resultadoValidacao);
      alert(resultadoValidacao)
      return;
    }
    setErroValidacao(null);
    const url = process.env.REACT_APP_BASE_URL;
    try {
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + "/emissor/new",
        {
          method: "POST",

          body: formData,
        }
      );
      if (response.ok) {
        navigate("/minuta");
        window.alert(" emissor cadastro com sucesso!");
        toggleCadastrados();
      } else {
        // Tratar erros
        console.error("Erro ao enviar o formulário para o servidor");
      }
    } catch (error) {
      window.alert("Erro ao cadastrar emissor!");
    }
    localStorage.setItem("emissorPreenchido", true);
    localStorage.setItem("emissor", JSON.stringify(dados));
    // window.alert('Emissor Cadastrado!');
  };

  const handleSearchAva = async (cep, index) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
      const data = response.data;

      const newAvalistas = [...avalistas];
      const currentAvalista = newAvalistas[index];

      currentAvalista.aRua = data.logradouro || "";
      currentAvalista.aNumero = "";
      currentAvalista.aBairro = data.bairro || "";
      currentAvalista.aCidade = data.localidade || "";
      currentAvalista.aEstado = data.uf || "";

      setAvalistas(newAvalistas);
    } catch (error) {
      console.error("Erro ao buscar endereço:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCepChangeAva = (e, index, field) => {
    const newCep = e?.target.value?.replace(/\D/g, "");
    const newAvalistas = [...avalistas];
    newAvalistas[index][field] = e.target.value;
    setAvalistas(newAvalistas);

    if (newCep.length === 8) {
      handleSearchAva(newCep, index);
    }
  };

  const toggleCustodiante = () => {
    setCustodiante(!custodiante);
  };

  const handleCustodianteChange = (event) => {
    const { name, value } = event.target;
    setDCustodiante((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e, tipoCadastro) => {
    const selectedFiles = e.target.files;
    //Arquivos emissor
    if (tipoCadastro === "faturamento") {
      if (selectedFiles.length === 1) {
        const file = selectedFiles[0];
        // setUploadedFiles([...uploadedFiles, { fileType, file }]);
        setEFaturamento(file);
        //console.log(uploadedFiles);
        // console.log(file);
      }
    } else {
      //arquicos de fiel depositario
      if (selectedFiles.length === 1) {
        const file = selectedFiles[0];
        // setUploadedFilesFD([...uploadedFilesFD, { fileType, file }]);
        setESocial(file);
        //console.log(uploadedFiles);
        // console.log(file);
      }
    }
  };

  const handleFileChangeFiel = (e, doc) => {
    const selectedFiles = e.target.files;
    if (selectedFiles.length === 1) {
      const file = selectedFiles[0];
      switch (doc) {
        case "CNH":
          // console.log('CNH')
          setFCNH(file);
          break;
        case "Residencia":
          //console.log('res')
          setFResidencia(file);
          break;
        case "Casamento":
          //console.log('casa')
          setFCasamento(file);
          break;
        default:
          break;
      }
      // setAvalistas(newAvalistas);
    }
  };

  const handleFileChangeAvalista = (e, index, doc) => {
    const selectedFiles = e.target.files;
    //Arquivos emissor
    console.log(doc, "doc");
    if (selectedFiles.length === 1) {
      const file = selectedFiles[0];
      const newAvalistas = [...avalistas];
      switch (doc) {
        case "CNH":
          // console.log('CNH')
          newAvalistas[index].aCNH = file;
          break;
        case "Residencia":
          //console.log('res')
          newAvalistas[index].aResidencia = file;
          break;
        case "Casamento":
          //console.log('casa')
          newAvalistas[index].aCasamento = file;
          break;
        default:
          break;
      }
      setAvalistas(newAvalistas);
    }
  };

  const handleRemoverArquivoEmissor = (doc) => {
    switch (doc) {
      case "Faturamento":
        setEFaturamento("");
        break;
      case "Social":
        setESocial("");
        break;
      default:
        break;
    }
  };

  const handleRemoverArquivoFD = (doc) => {
    switch (doc) {
      case "CNH":
        setFCNH("");
        break;
      case "Residencia":
        setFResidencia("");
        break;
      case "Casamento":
        setFCasamento("");
        break;
      default:
        break;
    }
  };

  const handleRemoverArquivo = (index, doc) => {
    const newAvalistas = [...avalistas];
    switch (doc) {
      case "CNH":
        newAvalistas[index].aCNH = "";
        break;
      case "Residencia":
        newAvalistas[index].aResidencia = "";
        break;
      case "Casamento":
        newAvalistas[index].aCasamento = "";
        break;
      default:
        break;
    }
    setAvalistas(newAvalistas);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Header selected={"emissor"} />
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingY: "20px",
        }}
      >
        {cadastrados && (
          <Button
            onClick={toggleCadastrados}
            sx={{
              background:
                "linear-gradient(to right, rgb(103, 220, 159), rgb(31, 45, 99))", // Gradiente de verde para azul
              borderRadius: "20px", // Bordas arredondadas
              color: "white", // Cor do texto
              "&:hover": {
                background: "linear-gradient(to right, #00CC00, #000099)", // Gradiente de verde mais escuro para azul mais escuro ao passar o mouse
              },
              marginTop: "20px",
              alignSelf: "center",
            }}
          >
            Cadastrar novo emissor
          </Button>
        )}
        {cadastrados ? (
          <Emissors />
        ) : (
          <div className="mainDoc22">
            <FormControl className="formDoc1" style={{ width: "100%" }}>
              <Button
                sx={{
                  color: "#000",
                  alignSelf: "flex-start",
                }}
                className="divTitleDoc33"
                onClick={() => {
                  toggleCadastrados();
                }}
              >
                <Typography variant="h5" display="flex" alignItems="center">
                  <BsArrowLeft style={{ marginRight: "0.5rem" }} />
                  Cadastrar novo emissor
                </Typography>
              </Button>
              <FormLabel htmlFor="" className="labelInputsDoc">
                Razão Social do Emissor:
              </FormLabel>
              <TextField
                type="text"
                placeholder="Razão Social"
                className={`inputsDoc ${
                  isExpanded ? "expandedDoc" : "expandedDoc"
                }`}
                value={dRazao}
                onChange={(e) => {
                  setDRazao(e.target.value);
                }}
                maxLength={120}
              />

              <FormLabel htmlFor="" className="labelInputsDoc">
                CNPJ:
              </FormLabel>
              <TextField
                type="text"
                placeholder="CNPJ"
                className={`inputsDoc ${
                  isExpanded ? "expandedDoc" : "expandedDoc"
                }`}
                value={formatCnpj(dCnpj)}
                onChange={(e) => {
                  setDCnpj(e.target.value);
                }}
                maxLength={18}
              />

              <FormLabel htmlFor="" className="labelInputsDoc">
                Telefone:
              </FormLabel>
              <TextField
                type="text"
                placeholder="Telefone"
                className={`inputsDoc ${
                  isExpanded ? "expandedDoc" : "expandedDoc"
                }`}
                value={formatarTelefone(dTelefone)}
                onChange={(e) => {
                  setDTelefone(e.target.value);
                }}
              />

              <FormLabel htmlFor="" className="labelInputsDoc">
                CEP:
              </FormLabel>
              <TextField
                mask="99999-999"
                maskChar=""
                type="text"
                placeholder="CEP"
                className={`inputsDoc`}
                value={formatCEP(dCep)}
                onChange={(e) => {
                  setDCep(e.target.value);
                }}
                onBlur={handleSearch2}
              />

              <FormLabel htmlFor="" className="labelInputsDoc">
                Número:
              </FormLabel>
              <TextField
                type="text"
                placeholder="Número"
                className={`inputsDoc`}
                value={dNumero}
                onChange={(e) => {
                  setDNumero(e.target.value);
                }}
              />

              <FormLabel htmlFor="" className="labelInputsDoc">
                Complemento:
              </FormLabel>
              <TextField
                type="text"
                placeholder="Complemento"
                className={`inputsDoc`}
                value={dComplemento}
                onChange={(e) => {
                  setDComplemento(e.target.value);
                }}
              />

              {isLoading2 ? (
                <p className="loading">Carregando...</p>
              ) : (
                <>
                  <FormLabel htmlFor="" className="labelInputsDoc">
                    Rua:
                  </FormLabel>
                  <TextField
                    type="text"
                    placeholder="Rua"
                    className={`inputsDoc ${
                      isExpanded ? "expandedDoc" : "expandedDoc"
                    }`}
                    value={dRua}
                    onChange={(e) => {
                      setDRua(e.target.value);
                    }}
                  />

                  <FormLabel htmlFor="" className="labelInputsDoc">
                    Bairro:
                  </FormLabel>
                  <TextField
                    type="text"
                    placeholder="Complemento"
                    className={`inputsDoc ${
                      isExpanded ? "expandedDoc" : "expandedDoc"
                    }`}
                    value={dBairro}
                    onChange={(e) => {
                      setDBairro(e.target.value);
                    }}
                  />

                  <FormLabel htmlFor="" className="labelInputsDoc">
                    Cidade:
                  </FormLabel>
                  <TextField
                    placeholder="Cidade"
                    type="text"
                    className={`inputsDoc ${
                      isExpanded ? "expandedDoc" : "expandedDoc"
                    }`}
                    value={dCidade}
                    onChange={(e) => {
                      setDCidade(e.target.value);
                    }}
                  />

                  <FormLabel htmlFor="" className="labelInputsDoc">
                    Estado:
                  </FormLabel>
                  <TextField
                    type="text"
                    placeholder="Estado"
                    className={`inputsDoc ${
                      isExpanded ? "expandedDoc" : "expandedDoc"
                    }`}
                    value={dEstado}
                    onChange={(e) => {
                      setDEstado(e.target.value);
                    }}
                  />
                </>
              )}
              <FormLabel htmlFor="" className="labelInputsDoc">
                E-mail:
              </FormLabel>
              <TextField
                type="text"
                placeholder="Email"
                className={`inputsDoc ${
                  isExpanded ? "expandedDoc" : "expandedDoc"
                }`}
                value={dEmail}
                onChange={(e) => {
                  setDEmail(e.target.value);
                }}
                maxLength={40}
              />

              <FormLabel htmlFor="" className="labelInputsDoc">
                Representante Legal
              </FormLabel>
              <TextField
                type="text"
                placeholder="Representante Legal"
                className={`inputsDoc ${
                  isExpanded ? "expandedDoc" : "expandedDoc"
                }`}
                value={representante}
                onChange={(e) => {
                  setRepresentante(e.target.value);
                }}
                maxLength={40}
              />

              <FormLabel htmlFor="" className="labelInputsDoc">
                CPF
              </FormLabel>
              <TextField
                type="text"
                placeholder="CPF"
                className={`inputsDoc ${
                  isExpanded ? "expandedDoc" : "expandedDoc"
                }`}
                value={formatCpf(dCpf)}
                onChange={(e) => {
                  setDCpf(e.target.value);
                }}
                maxLength={14}
              />

              <FormLabel htmlFor="" className="labelInputsDoc">
                Função
              </FormLabel>
              <TextField
                type="text"
                placeholder="Cargo"
                className={`inputsDoc ${
                  isExpanded ? "expandedDoc" : "expandedDoc"
                }`}
                value={cargo}
                onChange={(e) => {
                  setCargo(e.target.value);
                }}
                maxLength={40}
              />

              <FormLabel htmlFor="" className="labelInputsDoc">
                Banco
              </FormLabel>
              <Select
                value={selectedBank}
                onChange={handleBankChange}
                className="selectDoc"
                inputProps={{
                  name: "dadosBancarios",
                  id: "dadosBancarios",
                }}
              >
                <MenuItem value="" className="optionInputsDoc">
                  Selecione um banco
                </MenuItem>
                <MenuItem value="BNK Digital" className="optionInputsDoc">
                  BNK Digital
                </MenuItem>
                {sortedBanks.map((bank, index) => (
                  <MenuItem
                    key={index}
                    value={bank.name}
                    className="optionInputsDoc"
                  >
                    {bank.name}
                  </MenuItem>
                ))}
              </Select>

              <FormLabel htmlFor="" className="labelInputsDoc">
                Número do Banco
              </FormLabel>
              <TextField
                type="text"
                name="Número do banco"
                placeholder="Selecione um banco"
                className={`inputsDoc ${
                  isExpanded ? "expandedDoc" : "expandedDoc"
                }`}
                value={bankNumber}
                onChange={handleBankNumberChange}
                maxLength={20}
              />

              <FormLabel htmlFor="" className="labelInputsDoc">
                Agência:
              </FormLabel>
              <TextField
                placeholder="12345"
                type="text"
                className={`inputsDoc ${
                  isExpanded ? "expandedDoc" : "expandedDoc"
                }`}
                value={agency}
                onChange={(e) => {
                  setAgency(e.target.value);
                }}
                maxLength={8}
              />

              <FormLabel htmlFor="" className="labelInputsDoc">
                Conta:
              </FormLabel>
              <TextField
                placeholder="12345"
                type="text"
                className={`inputsDoc ${
                  isExpanded ? "expandedDoc" : "expandedDoc"
                }`}
                value={conta}
                onChange={(e) => {
                  setConta(e.target.value);
                }}
                maxLength={10}
              />

              <FormLabel htmlFor="meioPagamento">Meio de Pagamento</FormLabel>
              <Select
                value={meioPagamento}
                onChange={(e) => {
                  setMeioPagamento(e.target.value);
                }}
                className="selectDoc"
                inputProps={{
                  name: "meioPagamento",
                  id: "meioPagamento",
                }}
              >
                <MenuItem value="[X] Transferência Eletrônica Disponível (TED)">
                  Transferência Eletrônica Disponível (TED)
                </MenuItem>
                <MenuItem value="[X] Dação em Pagamento">
                  Dação em Pagamento
                </MenuItem>
              </Select>

              <FormLabel htmlFor="faturamento" className="labelInputsDoc">
                Contrato de faturamento:
              </FormLabel>
              <FormControl>
                <Input
                  onClick={(event) => {
                    event.target.value = "";
                  }}
                  onChange={(e) => {
                    handleFileChange(e, "faturamento");
                  }}
                  id="faturamento"
                  type="file"
                  sx={{
                    display: "none",
                  }}
                />
                <label htmlFor="faturamento" style={{ cursor: "pointer" }}>
                  <Typography
                    component="span"
                    variant="body2"
                    className="uploadText"
                    textAlign="center"
                    align="center"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "6px 16px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      backgroundColor: "#f9f9f9",
                    }}
                  >
                    <IoIosCloudUpload
                      className="uploadIcon"
                      sx={{ marginRight: "4px" }}
                    />
                    Selecionar contrato de faturamento
                  </Typography>
                </label>
                {eFaturamento ? (
                  <div
                    className="uploadedFileItem"
                    style={{ marginTop: "2vh" }}
                  >
                    <span>{"Relatório"}</span>
                    <span>{eFaturamento.name}</span>
                    <span>{formatFileSize(eFaturamento.size)}</span>
                    <Button
                      onClick={() => handleRemoverArquivoEmissor("Faturamento")}
                      className="button11"
                    >
                      Remover
                    </Button>
                  </div>
                ) : (
                  <div>{/* <span>Nenhum arquivo enviado ainda.</span>*/}</div>
                )}
              </FormControl>

              <FormLabel htmlFor="contract" className="labelInputsDoc">
                Contrato Social:
              </FormLabel>
              <Input
                onClick={(event) => {
                  event.target.value = "";
                }}
                onChange={(e) => {
                  handleFileChange(e, "contratos");
                }}
                id="contract"
                type="file"
                sx={{
                  display: "none",
                }}
              />
              <label htmlFor="contract" style={{ cursor: "pointer" }}>
                <Typography
                  component="span"
                  variant="body2"
                  className="uploadText"
                  textAlign="center"
                  align="center"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "6px 16px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    backgroundColor: "#f9f9f9",
                  }}
                >
                  <IoIosCloudUpload
                    className="uploadIcon"
                    sx={{ marginRight: "4px" }}
                  />
                  Selecionar contrato social
                </Typography>
              </label>
              {eSocial ? (
                <div className="uploadedFileItem">
                  <span>{"Social"}</span>
                  <span>{eSocial.name}</span>
                  <span>{formatFileSize(eSocial.size)}</span>
                  <Button
                    onClick={() => handleRemoverArquivoEmissor("Social")}
                    className="button11"
                  >
                    Remover
                  </Button>
                </div>
              ) : (
                <div>{/* <span>Nenhum arquivo enviado ainda.</span>*/}</div>
              )}
              {/* <Select
                name="meioDePagamento"
                value={meioPagamento}
                onChange={(e) => {
                  setMeioPagamento(e.target.value);
                }}
                className="selectDoc"
              >
                <option value={`[X] Transferência Eletrônica Disponível (TED)`}>
                  Transferência Eletrônica Disponível (TED)
                </option>
                <option value={`[X] Dação em Pagamento`}>
                  Dação em Pagamento
                </option>
              </Select> */}
              <Button className="newButtonEm" onClick={toggleCustodiante}>
                {custodiante
                  ? "Remover Cadastro de Custodiante"
                  : "Adicionar Cadastro de Custodiante +"}
              </Button>
              {custodiante && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <label style={{ marginTop: "2vh" }}>Custodiante:</label>
                  <TextField
                    type="text"
                    className={`inputsRep`}
                    value={dCustodiante.nome}
                    onChange={handleCustodianteChange}
                    placeholder="Insira o Custodiante"
                    name="nome"
                    maxLength={60}
                  />
                  <label style={{ marginTop: "2vh" }}>Telefone:</label>
                  <TextField
                    type="text"
                    className={`inputsRep`}
                    value={formatarTelefone(dCustodiante.telefone)}
                    onChange={handleCustodianteChange}
                    placeholder="Insira o Telefone"
                    name="telefone"
                    maxLength={60}
                  />
                  <label style={{ marginTop: "2vh" }}>Email:</label>
                  <TextField
                    type="text"
                    className={`inputsRep`}
                    value={dCustodiante.email}
                    onChange={handleCustodianteChange}
                    placeholder="Insira o Email"
                    name="email"
                    maxLength={60}
                  />
                  <label style={{ marginTop: "2vh" }}>CEP:</label>
                  <TextField
                    type="text"
                    className={`inputsRep`}
                    value={formatCEP(dCustodiante.cep)}
                    onChange={handleCustodianteChange}
                    placeholder="Insira o CEP"
                    name="cep"
                    maxLength={60}
                    onBlur={handleSearch3}
                  />
                  <label style={{ marginTop: "2vh" }}>Rua:</label>
                  <TextField
                    type="text"
                    className={`inputsRep`}
                    value={dCustodiante.rua}
                    onChange={handleCustodianteChange}
                    placeholder="Insira a rua"
                    name="rua"
                    maxLength={60}
                  />
                  <label style={{ marginTop: "2vh" }}>Número:</label>
                  <TextField
                    type="text"
                    className={`inputsRep`}
                    value={dCustodiante.numero}
                    onChange={handleCustodianteChange}
                    placeholder="Insira o número"
                    name="numero"
                    maxLength={60}
                  />
                  <label style={{ marginTop: "2vh" }}>Complemento:</label>
                  <TextField
                    type="text"
                    className={`inputsRep`}
                    value={dCustodiante.complemento}
                    onChange={handleCustodianteChange}
                    placeholder="Insira o complemento"
                    name="complemento"
                    maxLength={60}
                  />
                  <label style={{ marginTop: "2vh" }}>Bairro:</label>
                  <TextField
                    type="text"
                    className={`inputsRep`}
                    value={dCustodiante.bairro}
                    onChange={handleCustodianteChange}
                    placeholder="Insira o bairro"
                    name="bairro"
                    maxLength={60}
                  />
                  <label style={{ marginTop: "2vh" }}>Cidade:</label>
                  <TextField
                    type="text"
                    className={`inputsRep`}
                    value={dCustodiante.cidade}
                    onChange={handleCustodianteChange}
                    placeholder="Insira a cidade"
                    name="cidade"
                    maxLength={60}
                  />
                  <label style={{ marginTop: "2vh" }}>Estado:</label>
                  <TextField
                    type="text"
                    className={`inputsRep`}
                    value={dCustodiante.estado}
                    onChange={handleCustodianteChange}
                    placeholder="Insira o estado"
                    name="estado"
                    maxLength={60}
                  />
                </div>
              )}
              <Button
                className="newButtonEm"
                onClick={toggleAvalistaVisibility}
              >
                {avalista
                  ? "Ocultar Cadastro de Avalista"
                  : "Exibir Cadastro de Avalista +"}
              </Button>
              {avalista ? (
                <div className="">
                  {avalistas.map((avalista, index) => (
                    <FormGroup key={index} className="mainAvalista">
                      <Typography variant="h5" color="#1976d2">
                        AVALISTA {index + 1}
                      </Typography>

                      <FormLabel
                        htmlFor=""
                        className="labelInputsDoc"
                        style={{ marginTop: "2vh" }}
                      >
                        Nome:
                      </FormLabel>
                      <TextField
                        type="text"
                        className={`inputsDocAva`}
                        value={avalista.aNome}
                        onChange={(e) =>
                          handleAvalistaChange(e, index, "aNome")
                        }
                        maxLength={40}
                      />

                      <FormLabel
                        htmlFor=""
                        className="labelInputsDoc"
                        style={{ marginTop: "2vh" }}
                      >
                        CPF/CNPJ:
                      </FormLabel>
                      <TextField
                        type="text"
                        className={`inputsDocAva`}
                        value={formatDocument(avalista.aCpf)}
                        onChange={(e) => handleAvalistaChange(e, index, "aCpf")}
                        maxLength={19}
                      />

                      <FormLabel htmlFor="" className="labelInputsDoc">
                        Email:
                      </FormLabel>
                      <TextField
                        type="text"
                        className={`inputsDocAva`}
                        value={avalista.aEmail}
                        onChange={(e) =>
                          handleAvalistaChange(e, index, "aEmail")
                        }
                      />

                      <FormLabel htmlFor="" className="labelInputsDoc">
                        País de Nascimento:
                      </FormLabel>
                      <TextField
                        type="text"
                        className={`inputsDocAva`}
                        value={avalista.aNascimento}
                        onChange={(e) =>
                          handleAvalistaChange(e, index, "aNascimento")
                        }
                        maxLength={40}
                      />

                      <FormLabel htmlFor="" className="labelInputsDoc">
                        Estado Civil:
                      </FormLabel>
                      <Select
                        className={`selectDoc`}
                        value={avalista.aEstadoCivil}
                        onChange={(e) =>
                          handleAvalistaChange(e, index, "aEstadoCivil")
                        }
                      >
                        <MenuItem value="solteiro">Solteiro</MenuItem>
                        <MenuItem value="casado">Casado</MenuItem>
                        <MenuItem value="divorciado">Divorciado</MenuItem>
                        <MenuItem value="viuvo">Viúvo</MenuItem>
                        {/* Adicione mais opções conforme necessário */}
                      </Select>

                      <FormLabel htmlFor="" className="labelInputsDoc">
                        Profissão:
                      </FormLabel>
                      <TextField
                        type="text"
                        className={`inputsDocAva`}
                        value={avalista.aProfissao}
                        onChange={(e) =>
                          handleAvalistaChange(e, index, "aProfissao")
                        }
                      />

                      <FormLabel htmlFor="" className="labelInputsDoc">
                        Data de Nascimento:
                      </FormLabel>
                      <TextField
                        type="date"
                        className={`inputsDocAva`}
                        value={avalista.aDataNascimento}
                        onChange={(e) =>
                          handleAvalistaChange(e, index, "aDataNascimento")
                        }
                        maxLength={40}
                      />

                      <FormLabel htmlFor="" className="labelInputsDoc">
                        CEP:
                      </FormLabel>
                      <TextField
                        type="text"
                        className={`inputsDocAva`}
                        value={formatCEP(avalista.aCep)}
                        onChange={(e) => handleCepChangeAva(e, index, "aCep")}
                      />

                      <FormLabel htmlFor="" className="labelInputsDoc">
                        Rua:
                      </FormLabel>
                      <TextField
                        type="text"
                        className={`inputsDocAva`}
                        value={avalista.aRua}
                        onChange={(e) => handleAvalistaChange(e, index, "aRua")}
                        maxLength={14}
                      />

                      <FormLabel htmlFor="" className="labelInputsDoc">
                        Número:
                      </FormLabel>
                      <TextField
                        type="text"
                        className={`inputsDocAva`}
                        value={avalista.aNumero}
                        onChange={(e) =>
                          handleAvalistaChange(e, index, "aNumero")
                        }
                        maxLength={14}
                      />

                      <FormLabel htmlFor="" className="labelInputsDoc">
                        Bairro:
                      </FormLabel>
                      <TextField
                        type="text"
                        className={`inputsDocAva`}
                        value={avalista.aBairro}
                        onChange={(e) =>
                          handleAvalistaChange(e, index, "aBairro")
                        }
                        maxLength={14}
                      />
                      <FormLabel htmlFor="" className="labelInputsDoc">
                        Cidade:
                      </FormLabel>
                      <TextField
                        type="text"
                        className={`inputsDocAva`}
                        value={avalista.aCidade}
                        onChange={(e) =>
                          handleAvalistaChange(e, index, "aCidade")
                        }
                        maxLength={14}
                      />
                      <FormLabel htmlFor="" className="labelInputsDoc">
                        Estado:
                      </FormLabel>
                      <TextField
                        type="text"
                        className={`inputsDocAva`}
                        value={avalista.aEstado}
                        onChange={(e) =>
                          handleAvalistaChange(e, index, "aEstado")
                        }
                        maxLength={14}
                      />

                      <FormLabel
                        htmlFor="cnh-avalista"
                        className="labelInputsDoc"
                      >
                        Carteira Nacional de Habilitação:
                      </FormLabel>
                      <Input
                        onClick={(event) => {
                          event.target.value = "";
                        }}
                        onChange={(e) => {
                          handleFileChangeAvalista(e, index, "CNH");
                        }}
                        id="cnh-avalista"
                        type="file"
                        sx={{
                          display: "none",
                        }}
                      />
                      <label
                        htmlFor="cnh-avalista"
                        style={{ cursor: "pointer" }}
                      >
                        <Typography
                          component="span"
                          variant="body2"
                          className="uploadText"
                          textAlign="center"
                          align="center"
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "6px 16px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            backgroundColor: "#f9f9f9",
                          }}
                        >
                          <IoIosCloudUpload
                            className="uploadIcon"
                            sx={{ marginRight: "4px" }}
                          />
                          Selecionar CNH
                        </Typography>
                      </label>
                      {avalistas[index].aCNH ? (
                        <div className="uploadedFileItem">
                          <span>{"CNH"}</span>
                          <span>{avalistas[index].aCNH.name}</span>
                          <span>
                            {formatFileSize(avalistas[index].aCNH.size)}
                          </span>
                          <Button
                            onClick={() => handleRemoverArquivo(index, "CNH")}
                            className="button11"
                          >
                            Remover
                          </Button>
                        </div>
                      ) : (
                        <div>
                          {/* <span>Nenhum arquivo enviado ainda.</span>*/}
                        </div>
                      )}

                      <FormLabel
                        htmlFor="residencia-avalista"
                        className="labelInputsDoc"
                      >
                        Comprovante de Residência:
                      </FormLabel>
                      <Input
                        onClick={(event) => {
                          event.target.value = "";
                        }}
                        onChange={(e) => {
                          handleFileChangeAvalista(e, index, "Residencia");
                        }}
                        id="residencia-avalista"
                        type="file"
                        sx={{
                          display: "none",
                        }}
                      />
                      <label
                        htmlFor="residencia-avalista"
                        style={{ cursor: "pointer" }}
                      >
                        <Typography
                          component="span"
                          variant="body2"
                          className="uploadText"
                          textAlign="center"
                          align="center"
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "6px 16px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            backgroundColor: "#f9f9f9",
                          }}
                        >
                          <IoIosCloudUpload
                            className="uploadIcon"
                            sx={{ marginRight: "4px" }}
                          />
                          Selecionar Comprovante de Residência
                        </Typography>
                      </label>
                      {avalistas[index].aResidencia ? (
                        <div className="uploadedFileItem">
                          <span>{"Residencia"}</span>
                          <span>{avalistas[index].aResidencia.name}</span>
                          <span>
                            {formatFileSize(avalistas[index].aResidencia.size)}
                          </span>
                          <Button
                            onClick={() =>
                              handleRemoverArquivo(index, "Residencia")
                            }
                            className="button11"
                          >
                            Remover
                          </Button>
                        </div>
                      ) : (
                        <div>
                          {/* <span>Nenhum arquivo enviado ainda.</span>*/}
                        </div>
                      )}

                      <FormLabel
                        htmlFor="casamento-avalista"
                        className="labelInputsDoc"
                      >
                        Comprovante de Casamento:
                      </FormLabel>
                      <Input
                        onClick={(event) => {
                          event.target.value = "";
                        }}
                        onChange={(e) => {
                          handleFileChangeAvalista(e, index, "Casamento");
                        }}
                        id="casamento-avalista"
                        type="file"
                        sx={{
                          display: "none",
                        }}
                      />
                      <label
                        htmlFor="casamento-avalista"
                        style={{ cursor: "pointer" }}
                      >
                        <Typography
                          component="span"
                          variant="body2"
                          className="uploadText"
                          textAlign="center"
                          align="center"
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "6px 16px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            backgroundColor: "#f9f9f9",
                          }}
                        >
                          <IoIosCloudUpload
                            className="uploadIcon"
                            sx={{ marginRight: "4px" }}
                          />
                          Selecionar Comprovante de Casamento
                        </Typography>
                      </label>
                      {avalistas[index].aCasamento ? (
                        <div className="uploadedFileItem">
                          <span>{"Casamento"}</span>
                          <span>{avalistas[index].aCasamento.name}</span>
                          <span>
                            {formatFileSize(avalistas[index].aCasamento.size)}
                          </span>
                          <Button
                            onClick={() =>
                              handleRemoverArquivo(index, "Casamento")
                            }
                            className="button11"
                          >
                            Remover
                          </Button>
                        </div>
                      ) : (
                        <div>
                          {/* <span>Nenhum arquivo enviado ainda.</span>*/}
                        </div>
                      )}

                      <Button
                        className="addButton2"
                        onClick={() => removeAvalista(0)}
                      >
                        Remover Avalista
                      </Button>
                    </FormGroup>
                  ))}
                  <Button fullWidth className="addButton" onClick={addAvalista}>
                    + Adicionar Avalista
                  </Button>
                </div>
              ) : (
                <></>
              )}
              <Button onClick={toggleFielVisibility} className="newButtonEm">
                {fiel
                  ? "Ocultar Cadastro de Fiel Depositário"
                  : "Exibir Cadastro de Fiel Depositário +"}
              </Button>
              {fiel ? (
                <form
                  className="mainAvalista"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="divTitleDoc">
                    <Typography variant="h5" color="#1976d2">
                      FIEL DEPOSITÁRIO
                    </Typography>
                  </div>
                  <div
                    type="button"
                    style={{
                      width: "100%",
                      padding: "10px",
                      cursor: "pointer",
                      backgroundColor: "#2C3E50",
                      color: "white",
                      textAlign: "center",
                      borderRadius: "5px",
                      marginBottom: "10px",
                    }}
                    onClick={toggleTipo}
                  >
                    {tipo === "PF"
                      ? "Pessoa Física (CPF)"
                      : "Pessoa Jurídica (CNPJ)"}
                  </div>
                  {tipo === "PF" ? (
                    <>
                      <FormLabel htmlFor="" className="labelInputsDoc">
                        Nome:
                      </FormLabel>
                      <TextField
                        type="text"
                        className={`inputsDoc`}
                        value={fName}
                        onChange={(e) => {
                          setFName(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <FormLabel htmlFor="" className="labelInputsDoc">
                        Razão Social:
                      </FormLabel>
                      <TextField
                        type="text"
                        className={`inputsDoc`}
                        value={fName} // Você precisa criar o estado fCnpj
                        onChange={(e) => {
                          setFName(e.target.value);
                        }}
                      />
                    </>
                  )}

                  <>
                    <FormLabel htmlFor="" className="labelInputsDoc">
                      Ocupação:
                    </FormLabel>
                    <TextField
                      type="text"
                      className={`inputsDoc`}
                      value={fOcupacao}
                      onChange={(e) => {
                        setFOcupacao(e.target.value);
                      }}
                      maxLength={40}
                    />
                  </>

                  <FormLabel htmlFor="" className="labelInputsDoc">
                    CEP:
                  </FormLabel>
                  <TextField
                    mask="99999-999"
                    maskChar=""
                    type="text"
                    className={`inputsDoc`}
                    value={formatCEP(fCep)}
                    onChange={handleCepChange}
                  />
                  {isLoading ? (
                    <p className="loading">Carregando...</p>
                  ) : (
                    <>
                      <FormLabel htmlFor="" className="labelInputsDoc">
                        Rua:
                      </FormLabel>
                      <TextField
                        type="text"
                        className={`inputsDoc`}
                        value={fRua}
                        onChange={(e) => setFRua(e.target.value)}
                        maxLength={40}
                      />
                      <FormLabel htmlFor="" className="labelInputsDoc">
                        Número:
                      </FormLabel>
                      <TextField
                        type="text"
                        className={`inputsDoc`}
                        value={fNumero}
                        onChange={(e) => setFNumero(e.target.value)}
                        maxLength={40}
                      />
                      <FormLabel htmlFor="" className="labelInputsDoc">
                        Complemento:
                      </FormLabel>
                      <TextField
                        type="text"
                        className={`inputsDoc`}
                        value={fComplemento}
                        onChange={(e) => setFComplemento(e.target.value)}
                        maxLength={40}
                      />
                    </>
                  )}

                  <FormLabel htmlFor="" className="labelInputsDoc">
                    Cidade:
                  </FormLabel>
                  <TextField
                    type="text"
                    className={`inputsDoc ${
                      isExpanded ? "expandedDoc" : "expandedDoc"
                    }`}
                    value={fCidade}
                    onChange={(e) => setFCidade(e.target.value)}
                    maxLength={40}
                  />

                  <FormLabel htmlFor="" className="labelInputsDoc">
                    Estado:
                  </FormLabel>
                  <TextField
                    type="text"
                    className={`inputsDoc ${
                      isExpanded ? "expandedDoc" : "expandedDoc"
                    }`}
                    value={fEstado}
                    onChange={(e) => setFEstado(e.target.value)}
                    maxLength={40}
                  />
                  {tipo === "PF" ? (
                    <>
                      <FormLabel htmlFor="" className="labelInputsDoc">
                        CPF:
                      </FormLabel>
                      <TextField
                        type="text"
                        className={`inputsDoc ${
                          isExpanded ? "expandedDoc" : "expandedDoc"
                        }`}
                        value={formatCpf(fCpf)}
                        onChange={(e) => {
                          setFCpf(e.target.value);
                        }}
                        maxLength={14}
                      />
                    </>
                  ) : (
                    <>
                      <FormLabel htmlFor="" className="labelInputsDoc">
                        CNPJ:
                      </FormLabel>
                      <TextField
                        type="text"
                        className={`inputsDoc ${
                          isExpanded ? "expandedDoc" : "expandedDoc"
                        }`}
                        value={formatCnpj(fCnpj)} // Você precisa criar o estado fCnpj
                        onChange={(e) => {
                          setFCnpj(e.target.value);
                        }}
                        maxLength={18}
                      />
                    </>
                  )}

                  <FormLabel htmlFor="" className="labelInputsDoc">
                    E-mail:
                  </FormLabel>
                  <TextField
                    type="text"
                    className={`inputsDoc ${
                      isExpanded ? "expandedDoc" : "expandedDoc"
                    }`}
                    value={fEmail}
                    onChange={(e) => {
                      setFEmail(e.target.value);
                    }}
                    maxLength={40}
                  />

                  <FormLabel htmlFor="cnh-fiel" className="labelInputsDoc">
                    Carteira Nacional de Habilitação:
                  </FormLabel>
                  <Input
                    onClick={(event) => {
                      event.target.value = "";
                    }}
                    onChange={(e) => {
                      handleFileChangeFiel(e, "CNH");
                    }}
                    id="cnh-fiel"
                    type="file"
                    sx={{
                      display: "none",
                    }}
                  />
                  <label htmlFor="cnh-fiel" style={{ cursor: "pointer" }}>
                    <Typography
                      component="span"
                      variant="body2"
                      className="uploadText"
                      textAlign="center"
                      align="center"
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "6px 16px",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        backgroundColor: "#f9f9f9",
                      }}
                    >
                      <IoIosCloudUpload
                        className="uploadIcon"
                        sx={{ marginRight: "4px" }}
                      />
                      Selecionar CNH
                    </Typography>
                  </label>
                  {fCNH ? (
                    <div className="uploadedFileItem">
                      <span>{"CNH"}</span>
                      <span>{fCNH.name}</span>
                      <span>{formatFileSize(fCNH.size)}</span>
                      <Button
                        onClick={() => handleRemoverArquivoFD("CNH")}
                        className="button11"
                      >
                        Remover
                      </Button>
                    </div>
                  ) : (
                    <div>{/* <span>Nenhum arquivo enviado ainda.</span>*/}</div>
                  )}

                  <FormLabel
                    htmlFor="residencia-fiel"
                    className="labelInputsDoc"
                  >
                    Comprovante de Residência:
                  </FormLabel>
                  <Input
                    onClick={(event) => {
                      event.target.value = "";
                    }}
                    onChange={(e) => {
                      handleFileChangeFiel(e, "Residencia");
                    }}
                    id="residencia-fiel"
                    type="file"
                    sx={{
                      display: "none",
                    }}
                  />
                  <label
                    htmlFor="residencia-fiel"
                    style={{ cursor: "pointer" }}
                  >
                    <Typography
                      component="span"
                      variant="body2"
                      className="uploadText"
                      textAlign="center"
                      align="center"
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "6px 16px",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        backgroundColor: "#f9f9f9",
                      }}
                    >
                      <IoIosCloudUpload
                        className="uploadIcon"
                        sx={{ marginRight: "4px" }}
                      />
                      Selecionar Comprovante de Residência
                    </Typography>
                  </label>
                  {fResidencia ? (
                    <div className="uploadedFileItem">
                      <span>{"Residencia"}</span>
                      <span>{fResidencia.name}</span>
                      <span>{formatFileSize(fResidencia.size)}</span>
                      <Button
                        onClick={() => handleRemoverArquivoFD("Residencia")}
                        className="button11"
                      >
                        Remover
                      </Button>
                    </div>
                  ) : (
                    <div>{/* <span>Nenhum arquivo enviado ainda.</span>*/}</div>
                  )}

                  <FormLabel
                    htmlFor="casamento-fiel"
                    className="labelInputsDoc"
                  >
                    Comprovante de Casamento:
                  </FormLabel>
                  <Input
                    onClick={(event) => {
                      event.target.value = "";
                    }}
                    onChange={(e) => {
                      handleFileChangeFiel(e, "Casamento");
                    }}
                    id="casamento-fiel"
                    type="file"
                    sx={{
                      display: "none",
                    }}
                  />
                  <label htmlFor="casamento-fiel" style={{ cursor: "pointer" }}>
                    <Typography
                      component="span"
                      variant="body2"
                      className="uploadText"
                      textAlign="center"
                      align="center"
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "6px 16px",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        backgroundColor: "#f9f9f9",
                      }}
                    >
                      <IoIosCloudUpload
                        className="uploadIcon"
                        sx={{ marginRight: "4px" }}
                      />
                      Selecionar Comprovante de Casamento
                    </Typography>
                  </label>
                  {fCasamento ? (
                    <div className="uploadedFileItem">
                      <span>{"Casamento"}</span>
                      <span>{fCasamento.name}</span>
                      <span>{formatFileSize(fCasamento.size)}</span>
                      <Button
                        onClick={() => handleRemoverArquivoFD("Casamento")}
                        className="button11"
                      >
                        Remover
                      </Button>
                    </div>
                  ) : (
                    <div>{/* <span>Nenhum arquivo enviado ainda.</span>*/}</div>
                  )}
                </form>
              ) : (
                <br />
              )}
              {/* <div onClick={toggleSolidario} className="newButtonEm">
                                    {solidario ? "Ocultar Cadastro de Devedor Solidário" : "Exibir Cadastro de Devedor Solidário  +"}
                                </div>
                                {solidario ?
                                    <DevedorSolidario updateFormData={updateFormData} />
                                    :
                                    <br />} */}

              <div className="buttonEmissor1">
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <Button onClick={handleSubmitEmissor}>
                    Cadastrar Emissor
                  </Button>
                  <Button onClick={toggleCadastrados}>Voltar</Button>
                </Grid>
              </div>
              {erroValidacao && (
                <div className="mensagemErro">
                  Erro de validação: {erroValidacao}
                </div>
              )}
            </FormControl>
          </div>
        )}
      </Container>
    </Box>
  );
};

export default Emissor;
