export const cadastrarEmissorSuccess = () => ({
    type: 'CADASTRAR_EMISSOR_SUCCESS',
});

export const cadastrarEmissorError = () => ({
    type: 'CADASTRAR_EMISSOR_ERROR',
})

export const cadastrarEmissorRequest = (emissor) => ({
    type: 'CADASTRAR_EMISSOR_REQUEST',
    payload: { emissor },
});
