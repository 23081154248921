import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import SideHeader from "./Header";
import { theme } from "./MainCalc";
import MyPDFViewer from "../utils/pdfCenario";
import { Close } from "@mui/icons-material";
import "../styles/Resultado.css";

const Resultado = ({ result }) => {
  const [tableData, setTableData] = useState([]);
  const [appear, setAppear] = useState(false);
  useEffect(() => {
    // Tentar obter calcData do localStorage
    const storedData = localStorage.getItem("calcData");
    if (storedData) {
      try {
        // Tentar converter os dados armazenados para um array
        const parsedData = JSON.parse(storedData);
        if (Array.isArray(parsedData)) {
          setTableData(parsedData);
        } else {
          // Se os dados não forem um array, usar um array vazio ou um valor padrão
          setTableData([]);
        }
      } catch (error) {
        // Em caso de erro na conversão dos dados, usar um array vazio ou um valor padrão
        setTableData([]);
      }
    } else {
      // Se não houver dados no localStorage, usar um array vazio ou um valor padrão
      setTableData([]);
    }
  }, []);
  const handleAppear = () => {
    if (appear === false) {
      setAppear(true);
    } else setAppear(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <SideHeader selected="resultado" />
      <ThemeProvider theme={theme}>
        <Container
          sx={{
            width: "100%",
            backgroundColor: "#fff",
            borderRadius: "10px 10px 10px 10px",
            paddingY: "20px",
            marginY: "20px",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              m: 1,
              width: "100%",
              textAlign: "center",
              color: "#1b2d66",
              fontWeight: "600",
              textTransform: "uppercase",
            }}
          >
            Resultado da Simulação
          </Typography>
          <Button className="button12" onClick={handleAppear}>
            Exportar Cenário
          </Button>
          {appear ? (
            <div className="pdf-container active">
              <div onClick={handleAppear}>
                <Close
                  size={40}
                  className="close-button1"
                  color="#f83939"
                  style={{ top: 0, right: 0 }}
                />
              </div>
              <MyPDFViewer />
            </div>
          ) : null}
          <TableContainer>
            <Table
              sx={{
                minWidth: 650,
                borderCollapse: "separate",
                borderSpacing: "0 0px",
              }}
              aria-label="tabela de resultados"
              style={{
                backgroundColor: "rgb(213, 213, 213)",
                borderRadius: "10px 10px 10px 10px",
              }}
            >
              <TableHead>
                <TableRow style={{ backgroundColor: "#1b2d66" }}>
                  <TableCell
                    align="center"
                    style={{
                      color: "white",
                      borderRight: "2px solid white",
                      borderBottom: "2px solid white",
                      textAlign: "center",
                    }}
                  >
                    Parcela
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "white",
                      borderRight: "2px solid white",
                      borderBottom: "2px solid white",
                      textAlign: "center",
                    }}
                  >
                    Data de Vencimento
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      color: "white",
                      borderRight: "2px solid white",
                      borderBottom: "2px solid white",
                      textAlign: "center",
                    }}
                  >
                    Valor da Parcela
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      color: "white",
                      borderRight: "2px solid white",
                      borderBottom: "2px solid white",
                      textAlign: "center",
                    }}
                  >
                    Amortização
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      color: "white",
                      borderRight: "2px solid white",
                      borderBottom: "2px solid white",
                      textAlign: "center",
                    }}
                  >
                    Juros
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      color: "white",
                      borderRight: "2px solid white",
                      borderBottom: "2px solid white",
                      textAlign: "center",
                    }}
                  >
                    Saldo Devedor
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.length > 0 &&
                  tableData.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ borderRight: "2px solid white" }}
                      >
                        {row[0]}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ borderRight: "2px solid white" }}
                      >
                        {String(row[1]).toString().padStart(2, "0")}/{row[2]}/{row[3]}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ borderRight: "2px solid white" }}
                      >
                        {new Intl.NumberFormat("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }).format(row[4])}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ borderRight: "2px solid white" }}
                      >
                        {new Intl.NumberFormat("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }).format(row[5])}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ borderRight: "2px solid white" }}
                      >
                        {new Intl.NumberFormat("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }).format(row[6])}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }).format(row[7])}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </ThemeProvider>
    </Box>
  );
};

export default Resultado;
