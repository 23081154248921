export const pdfStyle = {
  viewRow: {
    flexDirection: "row",
    fontSize: 11,
    justifyContent: "space-between",
    alignContent: "center",
  },

  view2: {
    fontSize: 11,
    justifyContent: "space-between",
    flexDirection: "column",
    marginTop: "20px",
  },

  view3: {
    fontSize: 11,
    justifyContent: "space-around",
    marginTop: "20px",
    textAlign: "center",
    flexDirection: "row",
  },

  view4: {
    flexDirection: "row",
    textAlign: "center",
  },

  text1: {
    fontSize: 11,
  },

  textJustify: {
    fontSize: 11,
    textAlign: "justify",
  },

  text5: {
    fontSize: 10,
    marginLeft: "5px",
    marginRight: "5px",
  },

  text6: {
    fontSize: 11,
    fontFamily: "Helvetica",
    margin: "auto",
  },

  text7: {
    fontSize: 11,
    fontFamily: "Helvetica",
    textAlign: "center",
  },

  text8: {
    fontSize: 10,
    marginLeft: "2px",
    marginTop: "2px",
  },

  h1: {
    fontSize: 11,
    fontFamily: "Helvetica-Bold",
    marginBottom: "10px",
    marginTop: "20px",
  },

  text3: {
    fontSize: 11,
    fontFamily: "Helvetica",
    wordBreak: "break-word",
  },

  text4: {
    fontSize: 11,
    paddingTop: "6px",
    marginTop: "20px",
    borderTop: "1px solid black",
  },

  text2: {
    fontSize: 11,
    paddingTop: "6px",
    borderTop: "1px solid black",
  },

  page: {
    fontFamily: "Helvetica",
    paddingHorizontal: 20,
    paddingVertical: 20,
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  header: {
    marginBottom: 20,
    textAlign: "center",
  },

  footer: {
    position: "fixed", // Adicione position absolute ao footer
    bottom: 0, // Mantenha o footer na parte inferior
    left: 0,
    right: 0,
    textAlign: "center", // Ou ajuste conforme necessário
    padding: 10, // Espaçamento interno do footer
    marginHorizontal: "40px",
  },

  section: {
    marginBottom: 0,
    marginHorizontal: 20,
  },

  image: {
    width: "150px",
    height: "40px",
    marginBottom: "10px",
  },

  textHeader: {
    width: "100%",
    fontSize: 11,
    fontWeight: "900",
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
  },

  tableContainer: {
    width: "100%",
    alignSelf: "center",
    border: "1.5px solid black",
    marginBottom: "15px",
  },

  row: {
    flexDirection: "row",
  },

  cell: {
    fontSize: 11,
    padding: 3,
    paddingRight: 20,
    paddingLeft: 20,
    width: "40%",
  },

  cell1: {
    width: "70%",
    fontSize: 11,
    padding: 3,
    paddingRight: 20,
    paddingLeft: 20,
    borderLeftWidth: "1px",
    borderLeftColor: "black",
  },

  cell2: {
    width: "100%",
    fontSize: 9,
    padding: 3,
    paddingRight: 10,
    paddingLeft: 10,
    borderRightWidth: "1.5px",
    borderBottomWidth: "1.5px",
    borderBottomColor: "black",
    borderRightColor: "black",
    wordBreak: "break-word",
    hyphens: "none",
  },

  cell3: {
    width: "100%",
    fontSize: 9,
    padding: 3,
    paddingRight: 10,
    paddingLeft: 10,
    borderWidth: "1px",
    borderColor: "black",
  },

  cellRight: {
    width: "100%",
    fontSize: 9,
    padding: 3,
    paddingRight: 20,
    paddingLeft: 20,
    borderRightWidth: "1.5px",
    borderRightColor: "black",
  },

  cell5: {
    width: "100%",
    fontSize: 9,
    padding: 3,
    paddingRight: 20,
    paddingLeft: 20,
    borderBottomWidth: "1.5px",
    borderColor: "black",
  },

  cell6: {
    fontSize: 11,
    padding: 3,
    paddingRight: 20,
    paddingLeft: 20,
    width: "40%",
  },

  headerCell: {
    fontFamily: "Helvetica-Bold",
    fontSize: 11,
    padding: 3,
    paddingRight: 20,
    paddingLeft: 20,
    width: "40%",
  },

  textBold: {
    fontFamily: "Helvetica-Bold",
    wordBreak: "break-word",
    hyphens: "none",
  },

  textBoldUnderline: {
    fontFamily: "Helvetica-Bold",
    fontSize: 11,
    textDecoration: "underline",
  },

  textLine: {
    textDecoration: "underline",
    fontSize: 11,
    marginTop: "10px",
    marginBottom: "10px",
  },

  textCenter: {
    textAlign: "center",
    fontSize: 11,
  },

  centerH1: {
    textAlign: "center",
    fontSize: 10,
    fontFamily: "Helvetica-Bold",
  },

  downTable: {
    width: "100%",
    borderLeftWidth: "1px",
    borderTopWidth: "1px",
    borderTopColor: "black",
    borderLeftColor: "solid black",
    alignSelf: "center",
  },

  th: {
    textAlign: "right",
    marginTop: 20,
    marginBottom: 20,
    fontFamily: "Helvetica-Bold",
    flex: 1,
  },

  td: {
    fontSize: 11,
    flex: 1,
  },

  tr: {
    flexDirection: "row",
    borderBottomColor: "black",
    borderBottomWidth: 1,
  },

  centralizado: {
    textAlign: "center",
    fontSize: 11,
    fontWeight: "extrabold",
    paddingTop: 20,
    paddingBottom: 20,
    paddingRight: "7px",
  },

  centralizadoTh: {
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
    verticalAlign: "center",
    fontSize: 13,
    paddingTop: 10,
  },

  alinhamentoDireita: {
    textAlign: "right",
    flex: 1,
  },

  alinhamentoDireitaTd: {
    textAlign: "right",
    fontSize: 11,
    paddingTop: 20,
    paddingBottom: 20,
    borderRightWidth: "1px",
    borderRightColor: "black",
    paddingRight: "7px",
  },
};
