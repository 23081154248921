import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FaTrashCan } from "react-icons/fa6";
import UpdateInvestor from "../Modals/UpdateInvestor";
import { GrDocumentUpdate } from "react-icons/gr";
import { BsEye } from "react-icons/bs";
import InvestidorModal from "../Modals/InvestidorModal";
import { FaEdit } from "react-icons/fa";
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Container,
  TableContainer,
  Paper,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  Pagination,
} from "@mui/material";
import { Delete, Edit, VisibilityOutlined } from "@mui/icons-material";
import { red } from "@mui/material/colors";
import { formatCnpj } from "../utils/tools";

const Investidores = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [selectedInvestidor, setSelectedInvestidor] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const [investidores, setInvestidores] = useState([]);
  const [modalInvestorIsOpen, setModalInvestorIsOpen] = useState(false);
  const [detailsInvestor, setDetailsInvestor] = useState(false);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const totalPages = Math.ceil(investidores.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentInvestidores =
    investidores.length && Array.isArray(investidores)
      ? investidores?.slice(startIndex, endIndex)
      : [];

  const goToNextPage = () =>
    setCurrentPage((page) => Math.min(page + 1, totalPages));
  const goToPreviousPage = () =>
    setCurrentPage((page) => Math.max(page - 1, 1));

  const toggleModal = () => {
    setModalInvestorIsOpen(!modalInvestorIsOpen);
  };

  const openInvestorModal = (investidor) => {
    setSelectedInvestidor(investidor);
    toggleModal();
  };
  const closeInvestorModal = () => {
    setDetailsInvestor(false);
  };

  const userRef = user.data.email;

  useEffect(() => {
    if (selectedItemIndex !== null) {
      const selectedEmissorData = investidores[selectedItemIndex];
      const investidorTransformado = transformarInvestidor(selectedEmissorData);
      setSelectedInvestidor(investidorTransformado);
    } else {
      setSelectedInvestidor(null);
    }
  }, [selectedItemIndex, investidores]);

  useEffect(() => {
    const fetchInvestidores = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/investidor?userRef=${user.data.email}`,
          {
            headers: {
              "Content-Type": "application/json",
              "ngrok-skip-browser-warning": "69420",
            },
          }
        );
        await response.json().then((data) => {
          console.log(data);
          setInvestidores(data.length > 0 ? data : []);
        });
      } catch (error) {
        setInvestidores([]);
        console.error("Erro ao buscar investidores:", error);
      }
    };

    fetchInvestidores();
  }, []);

  const navigate = useNavigate();

  const handleSave = (investidor) => {
    localStorage.setItem("investidor", JSON.stringify(investidor));
    window.alert("Investidor Selecionado!");
    navigate("/Emissor");
  };
  const handleDeleteInfos = (index) => {
    setSelectedItemIndex(index);
    setShowConfirmation(true);
  };
  const toggleDetails = (index) => {
    console.log(index, "oqueeindex");
    setSelectedItemIndex(index);
    setDetailsInvestor(true);
  };
  const handleDelete = (investidorId) => {
    console.log(investidorId);
    const url = process.env.REACT_APP_BASE_URL;
    const headers = {};
    if (investidorId) {
      axios
        .delete(`${url}/investidor/${investidorId}`, headers)
        .then((response) => {
          if (response.status === 204) {
            setDeleteSuccess(true);
            setTimeout(() => {
              setDeleteSuccess(false);
            }, 3000);
            setSelectedItemIndex(null);
            setShowConfirmation(false);

            axios
              .get(`${url}/investidor?userRef=${user.data.email}`)
              .then((response) => {
                console.log(response);
                const updatedInvestidores = response.json().data;
                setInvestidores(updatedInvestidores);
              })
              .catch((error) => {
                console.error("Erro ao fazer a solicitação:", error);
              });
          } else {
            setDeleteError(true);
            setTimeout(() => {
              setDeleteError(false);
            }, 3000);
          }
        })
        .catch((error) => {
          console.error("Erro ao fazer a solicitação:", error);
        });
    }
  };

  function transformarInvestidor(investidor) {
    if (investidor.nome && investidor.nome2 && investidor.email) {
      const investidorTransformado = {
        ...investidor,
        representantes: [{ nome: investidor.nome, email: investidor.email }],
      };

      // Se 'nome2' existir, adicione um segundo representante
      if (investidor.nome2) {
        investidorTransformado.representantes.push({
          nome: investidor.nome2,
          email: investidor.email,
        });
      }

      return investidorTransformado;
    }

    return investidor; // Retorna o objeto original se não houver transformação
  }

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Container>
      <Container>
        <h3>Selecione um Investidor para sua Nota Comercial</h3>
        {currentInvestidores.length > 0 ? (
          <TableContainer component={Paper}>
            <Table
              sx={{ backgroundColor: "#fff" }}
              style={{
                backgroundColor: "rgb(213, 213, 213)",
                borderRadius: "10px 10px 10px 10px",
              }}
            >
              <TableHead>
                <TableRow style={{ backgroundColor: "#1b2d66" }}>
                  <TableCell
                    style={{
                      color: "white",
                      borderRight: "2px solid white",
                      borderBottom: "2px solid white",
                      textAlign: "center",
                    }}
                  >
                    Razão Social
                  </TableCell>
                  <TableCell
                    style={{
                      color: "white",
                      borderRight: "2px solid white",
                      borderBottom: "2px solid white",
                      textAlign: "center",
                    }}
                  >
                    CNPJ
                  </TableCell>
                  <TableCell
                    style={{
                      color: "white",
                      borderRight: "2px solid white",
                      borderBottom: "2px solid white",
                      textAlign: "center",
                    }}
                  >
                    E-mail
                  </TableCell>
                  <TableCell
                    style={{
                      color: "white",
                      borderRight: "2px solid white",
                      borderBottom: "2px solid white",
                      textAlign: "center",
                    }}
                  >
                    Telefone
                  </TableCell>
                  <TableCell
                    style={{
                      color: "white",
                      borderRight: "2px solid white",
                      borderBottom: "2px solid white",
                      textAlign: "center",
                    }}
                  >
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentInvestidores?.map((investidor, index) => (
                  <TableRow key={investidor._id}>
                    <TableCell
                      style={{
                        borderRight: "2px solid white",
                        borderBottom: "2px solid white",
                        textAlign: "center",
                      }}
                    >
                      {investidor.razaoSocial}
                    </TableCell>
                    <TableCell
                      style={{
                        borderRight: "2px solid white",
                        borderBottom: "2px solid white",
                        textAlign: "center",
                      }}
                    >
                      {formatCnpj(investidor.cnpj)}
                    </TableCell>
                    <TableCell
                      style={{
                        borderRight: "2px solid white",
                        borderBottom: "2px solid white",
                        textAlign: "center",
                      }}
                    >
                      {investidor.representantes[0].email}
                    </TableCell>
                    <TableCell
                      style={{
                        borderRight: "2px solid white",
                        borderBottom: "2px solid white",
                        textAlign: "center",
                      }}
                    >
                      {investidor.telefone}
                    </TableCell>
                    <TableCell
                      style={{
                        borderRight: "2px solid white",
                        borderBottom: "2px solid white",
                        textAlign: "center",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Tooltip title="Visualizar">
                          <IconButton
                            onClick={() => toggleDetails(index)}
                            color="primary"
                          >
                            <VisibilityOutlined />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Editar">
                          <IconButton
                            onClick={() => openInvestorModal(investidor)}
                            color="secondary"
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Excluir">
                          <IconButton
                            onClick={() => {
                              setShowConfirmation(true);
                              setSelectedInvestidor(investidor);
                              console.log(investidor);
                            }}
                            sx={{ color: red[500] }}
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <Dialog
                        open={showConfirmation}
                        onClose={() => setShowConfirmation(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          {"Confirmar Exclusão"}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            Tem certeza de que deseja excluir este investidor?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={() => setShowConfirmation(false)}
                            color="primary"
                          >
                            Cancelar
                          </Button>
                          <Button
                            onClick={() => handleDelete(selectedInvestidor._id)}
                            color="primary"
                            autoFocus
                          >
                            Confirmar
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div>Nenhum investidor encontrado</div>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Button onClick={goToPreviousPage} disabled={currentPage === 1}>
            Anterior
          </Button>
          <span>
            Página {currentPage} de {totalPages}
          </span>
          <Button onClick={goToNextPage} disabled={currentPage === totalPages}>
            Próxima
          </Button>
        </div>

        {detailsInvestor && (
          <InvestidorModal
            selectedInvestidor={selectedInvestidor}
            handleSave={handleSave}
            onClose={closeInvestorModal}
          />
        )}
        {modalInvestorIsOpen && (
          <UpdateInvestor
            isOpen={modalInvestorIsOpen}
            onClose={toggleModal}
            investorData={selectedInvestidor}
          />
        )}
      </Container>
    </Container>
  );
};

export default Investidores;
