import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../styles/Update.css";
import { BsArrowLeft } from "react-icons/bs";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const handleValidation = () => {
    if (!email || !newPassword || !confirmPassword) {
      window.alert("Por favor, preencha todos os campos.");
      return false;
    }
    if (newPassword !== confirmPassword) {
      window.alert("As senhas digitadas não coincidem.");
      return false;
    }
    if (newPassword.length < 8) {
      window.alert("A senha deve ter no mínimo 8 caracteres.");
      return false;
    }
    if (!/[A-Z]/.test(newPassword)) {
      window.alert("A senha deve conter pelo menos uma letra maiúscula.");
      return false;
    }
    if (!/[a-z]/.test(newPassword)) {
      window.alert("A senha deve conter pelo menos uma letra minúscula.");
      return false;
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(newPassword)) {
      window.alert("A senha deve conter pelo menos um caractere especial.");
      return false;
    }
    return true;
  };

  const handleSaveChanges = async () => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    if (!handleValidation()) {
      // Se a validação falhar, retorne sem chamar a API
      return;
    }
    const requestBody = {
      email: email,
      password: newPassword,
    };
    handleValidation();
    try {
      await axios.put(
        process.env.REACT_APP_BASE_URL + "/master/reset-password",
        { requestBody, headers }
      );
      window.alert("Senha atualizada com sucesso!");
      navigate("/");
    } catch (error) {
      window.alert("Erro ao atualizar a senha");
      console.error("Erro ao atualizar a senha:", error);
    }
  };
  const voltar = () => {
    navigate("/");
  };

  return (
    <div className="bodyUpdt">
      <div className="divCad">
        <div className="containerWeb" style={{ marginTop: "5vh" }}>
          <div>
            <div className="returnArrow" onClick={voltar}>
              <BsArrowLeft size={25} style={{ marginRight: "10px" }} />
              Redefinir senha
            </div>
            <h5 className="otherTitle">
              Preencha os dados abaixo para redefinir sua senha
            </h5>
          </div>
          <p className="labelForgot">E-mail:</p>
          <div className="input-password">
            <input
              className="input-container-input"
              maxLength={40}
              value={email}
              placeholder="Insira seu e-mail"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <p className="labelForgot">Nova senha:</p>
          <div className="input-password">
            <input
              className="input-container-input"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Insira sua senha"
              type="password"
            />
          </div>
          <p className="labelForgot">Confirme sua nova senha:</p>
          <div className="input-password">
            <input
              className="input-container-input"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Insira sua senha"
              type="password"
            />
          </div>
          <div>
            <button className="buttonEntrar" onClick={handleSaveChanges}>
              Atualizar senha
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
