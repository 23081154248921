import React, { useEffect, useState } from "react";
import axios from "axios";
import "../styles/Emitted.css";
import Header from "../components/Header";
import ShowNotes from "../Modals/ShowNotes";
import { AiOutlineEye } from "react-icons/ai";
import { Box, Container, ThemeProvider } from "@mui/material";
import SideHeader from "../components/Header";
import { formatDate2 } from "../utils/tools";

const EmittedNotes = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const userRef = user.data.cnpj;
  const [emittedNotes, setEmittedNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null); // Estado para armazenar o arquivo selecionado
  const [selectedNote, setSelectedNote] = useState(null); // State to store the selected note for the modal
  const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar a abertura do modal
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState(null);
  const [deletionSuccess, setDeletionSuccess] = useState(false);

  const showConfirmation = (note) => {
    setConfirmationVisible(true);
    setNoteToDelete(note);
  };

  const openModal = (note) => {
    console.log("Opening modal for note:", note);
    if (!note.valorOperacao.startsWith("R$")) {
      note = {
        ...note,
        valorOperacao: new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(note.valorOperacao),
      };
    }

    setSelectedNote(note);
    setIsModalOpen(true); // Abre o modal quando um botão de detalhes é clicado
  };

  const closeModal = () => {
    setSelectedNote(null);
    setIsModalOpen(false); // Fecha o modal quando o botão de fechar é clicado
  };

  useEffect(() => {
    if (user && user.data && user.data.cnpj) {
      const cnpj = user.data.cnpj;

      axios
        .get(`${process.env.REACT_APP_BASE_URL}/emitted/getPdfs/${userRef}`)
        .then((response) => {
          const data = response.data;
          console.log(data);
          setEmittedNotes(
            data.map((note) => ({
              razaoEmissor: note.razaoEmissor,
              escrituradora: note.escrituradora,
              urlMinuta: note.urlMinuta,
              urlFaturamento: note.urlFaturamento,
              status: note.status,
              valorOperacao: note.valorOperacao,
              parcelas: note.parcelas,
              vencimento: note.vencimento,
              createdAt: formatDate2(note.createdAt),
            }))
          );
          setLoading(false);
        })
        .catch((error) => {
          console.error("Erro ao buscar notas emitidas:", error);
          setLoading(false);
        });
    } else {
      console.error("Usuário não autenticado ou CNPJ indisponível.");
      setLoading(false);
    }
  }, []);

  const bytesToMB = (bytes) => {
    return (bytes / (1024 * 1024)).toFixed(2) + " MB";
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const deleteNote = () => {
    if (noteToDelete) {
      // Fazer a solicitação de exclusão usando Axios
      axios
        .delete(
          `${process.env.REACT_APP_BASE_URL}/emitted/pdfs/${noteToDelete._id}`
        )
        .then((response) => {
          // Atualizar a lista de notas após a exclusão
          const updatedNotes = emittedNotes.filter(
            (note) => note._id !== noteToDelete._id
          );
          setEmittedNotes(updatedNotes);
          setDeletionSuccess(true); // Defina o estado de sucesso da exclusão como verdadeiro
        })
        .catch((error) => {
          console.error("Erro ao excluir nota:", error);
        })
        .finally(() => {
          setConfirmationVisible(false);
          setNoteToDelete(null);
        });
    }
  };

  const cancelDelete = () => {
    setConfirmationVisible(false);
    setNoteToDelete(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <SideHeader selected="emitidas" />
      <Container
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
          backgroundColor: "#fff",
          borderRadius: "15px",
          paddingY: "60px",
          marginY: "20px",
        }}
      >
        <body className="emittedBody">
          <main className="emittedMain">
            <div className="ahead">
              <h2 className="h2Emitted">Notas Emitidas:</h2>
              <div className="div1">
                <h3>Bem Vindo, {user.data.razaoSocial}</h3>
              </div>
            </div>
            {loading ? (
              <p className="pEmitted">Carregando...</p>
            ) : (
              <div className="divEmitted">
                <table className="tableEmitted">
                  <thead>
                    <tr>
                      <th>Razão Social</th>
                      <th>Valor da Operação</th>
                      <th>Parcelas</th>
                      <th>Data de Vencimento</th>
                      <th>Status</th>
                      <th>Detalhes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {emittedNotes?.map((note) => (
                      <tr key={note._id}>
                        <td>{note.razaoEmissor}</td>
                        <td>
                          {note.valorOperacao.startsWith("R$")
                            ? note.valorOperacao
                            : new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              }).format(note.valorOperacao)}
                        </td>
                        <td>{note.parcelas}</td>
                        <td>{note.vencimento}</td>
                        <td>{note.status}</td>
                        <td style={{ width: "10%" }}>
                          <div
                            className="emittedShowDetails"
                            onClick={() => openModal(note)}
                          >
                            <AiOutlineEye />
                          </div>
                          {/* <button className="button111" onClick={() => showConfirmation(note)}>Excluir</button> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {deletionSuccess && (
                  <div className="success-message">
                    Nota excluída com sucesso!
                  </div>
                )}
              </div>
            )}

            {confirmationVisible && (
              <div className="confirmation-modal">
                <p>Deseja realmente excluir esta nota?</p>
                <button className="button111" onClick={deleteNote}>
                  Sim
                </button>
                <button className="button111" onClick={cancelDelete}>
                  Cancelar
                </button>
              </div>
            )}
          </main>

          {isModalOpen && (
            <div className="modal-wrapper">
              <div className="modal-backdrop" onClick={closeModal}></div>
              <div className="modal-container">
                <ShowNotes note={selectedNote} onClose={closeModal} />
              </div>
            </div>
          )}
        </body>
      </Container>
    </Box>
  );
};

export default EmittedNotes;
