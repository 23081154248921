import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { Link } from "react-router-dom"; // Certifique-se de ter react-router-dom instalado
import logo from "../images/white.png";
import logoc2 from "../images/LOGO_C2_VERDE_BRANCO.png";
import { Logout } from "@mui/icons-material";

const SideHeader = ({ selected }) => {
  const theme = createTheme({
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            fontWeight: "bolder",
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: "25%",
          height: "90vh",
          backgroundColor: "#1b2d66",
          borderRadius: "10px 10px 10px 10px",
          m: 1,
          p: 1,
        }}
      >
        <List
          sx={{
            padding: 1,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <img
            src={logo}
            alt="Logo"
            style={{
              width: "90%",
              padding: "10px",
            }}
          />
          <ListItem
            button
            selected={selected === "calculo"}
            component={Link}
            to="/home"
            sx={selected === "calculo" ? { backgroundColor: "#0BF0A1" } : {}}
            style={
              selected === "calculo"
                ? {
                    backgroundColor: "#0BF0A1",
                    borderRadius: 5,
                  }
                : { borderRadius: 5 }
            }
          >
            <ListItemText primary="Simular Operação" sx={{ color: "white" }} />
          </ListItem>
          <ListItem
            button
            selected={selected === "resultado"}
            component={Link}
            to="/resultado"
            sx={selected === "resultado" ? { backgroundColor: "#0BF0A1" } : {}}
            style={
              selected === "resultado"
                ? { backgroundColor: "#0BF0A1", borderRadius: 5 }
                : { borderRadius: 5 }
            }
          >
            <ListItemText
              primary="Resultado Simulado"
              sx={{ color: "white" }}
            />
          </ListItem>
          <ListItem
            button
            selected={selected === "investidor"}
            component={Link}
            to="/investidor"
            sx={selected === "investidor" ? { backgroundColor: "#0BF0A1" } : {}}
            style={
              selected === "investidor"
                ? { backgroundColor: "#0BF0A1", borderRadius: 5 }
                : { borderRadius: 5 }
            }
          >
            <ListItemText
              primary="Cadastro de Investidor"
              sx={{ color: "white" }}
            />
          </ListItem>
          <ListItem
            button
            selected={selected === "emissor"}
            component={Link}
            to="/emissor"
            sx={selected === "emissor" ? { backgroundColor: "#0BF0A1" } : {}}
            style={
              selected === "emissor"
                ? { backgroundColor: "#0BF0A1", borderRadius: 5 }
                : { borderRadius: 5 }
            }
          >
            <ListItemText
              primary="Cadastro de Emissor"
              sx={{ color: "white" }}
            />
          </ListItem>
          <Divider />
          <ListItem
            button
            selected={selected === "minuta"}
            component={Link}
            to="/minuta"
            sx={selected === "minuta" ? { backgroundColor: "#0BF0A1" } : {}}
            style={
              selected === "minuta"
                ? { backgroundColor: "#0BF0A1", borderRadius: 5 }
                : { borderRadius: 5 }
            }
          >
            <ListItemText
              primary="Gerar Nota Comercial"
              sx={{ color: "white" }}
            />
          </ListItem>
          <ListItem
            button
            selected={selected === "emitidas"}
            component={Link}
            to="/emitted"
            sx={selected === "emitidas" ? { backgroundColor: "#0BF0A1" } : {}}
            style={
              selected === "emitidas"
                ? { backgroundColor: "#0BF0A1", borderRadius: 5 }
                : { borderRadius: 5 }
            }
          >
            <ListItemText
              primary="Notas Emitidas"
              sx={{ color: "white" }}
            />
          </ListItem>
          <ListItem
            button
            selected={selected === ""}
            component={Link}
            to="/"
            sx={selected === "sair" ? { backgroundColor: "#0BF0A1" } : {}}
            style={
              selected === "sair"
                ? {
                    backgroundColor: "#0BF0A1",
                    borderRadius: 5,
                  }
                : { borderRadius: 5 }
            }
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <ListItemText
                primary="Sair"
                sx={{ color: "white", paddingRight: 1 }}
              />
              <Logout sx={{ color: "white" }} />
            </div>
          </ListItem>
          <img
            src={logoc2}
            alt="Logo C2"
            style={{
              height: "25%",
              padding: "10px",
              marginTop: "auto",
            }}
          />
        </List>
      </Box>
    </ThemeProvider>
  );
};

export default SideHeader;
