import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Button, Typography, IconButton } from "@mui/material";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import { MdLockOutline, MdPersonOutline } from "react-icons/md";
import { useDispatch } from "react-redux";
import background from "../images/backgroundBlue.png";
import logo from "../images/abarfesx.png";
import { Image } from "@mui/icons-material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const authenticateUserInternal = async (
  authEmail,
  authPassword,
  endpoint,
  navigate,
  dispatch
) => {
  try {
    console.log("Authenticating user internally...");
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: authEmail,
        password: authPassword,
      }),
    });

    const json = await response.json();

    if (response.status === 201) {
      console.log("Authentication successful!");
      localStorage.setItem("userToken", json.token);
      localStorage.setItem("user", JSON.stringify(json));
      localStorage.setItem("calcData", false);

      navigate("/home");
    } else {
      console.log("Authentication failed.");
      window.alert("Informações Incorretas. Revise e tente novamente.");
    }
  } catch (error) {
    console.error(error);
    window.alert("Informações Incorretas. Revise e tente novamente.");
  }
};

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(window.location.search);
  const emailParam = queryParams.get("emailParam");
  const senhaParam = queryParams.get("senhaParam");

  useEffect(() => {
    console.log("useEffect triggered", emailParam, senhaParam);
    if (emailParam && senhaParam) {
      console.log("Checking URL parameters for login...");
      authenticateUserInternal(
        emailParam,
        senhaParam,
        process.env.REACT_APP_BASE_URL + "/master/authenticate",
        navigate,
        dispatch
      );
    }
  }, [emailParam, senhaParam, navigate, dispatch]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async () => {
    console.log("Handling login...");
    if (emailParam && senhaParam) {
      authenticateUserInternal(
        emailParam,
        senhaParam,
        process.env.REACT_APP_BASE_URL + "/master/authenticate",
        navigate,
        dispatch
      );
    } else {
      authenticateUserInternal(
        email,
        password,
        process.env.REACT_APP_BASE_URL + "/master/authenticate",
        navigate,
        dispatch
      );
    }
  };

  return (
    <div
      style={{
        color: "#fff",
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundCcolor: "rgb(27, 45, 102)",
        height: "100vh",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center", // Centraliza verticalmente
        padding: "0 20px",
      }}
    >
      <div
        style={{
          width: "40%",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="body1"
          style={{
            paddingBottom: 20,
            fontSize: 24,
            paddingInline: 60,
          }}
        >
          Seja bem vindo associado{" "}
          <Typography
            component="span"
            style={{
              fontSize: 20,
              fontWeight: "bold",
              display: "inline",
            }}
          >
            ABRAFESC
          </Typography>
          , aqui você dispõe de uma condição exclusiva para emissão de sua Nota
          Comercial
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TextField
            variant="outlined"
            label="e-mail"
            margin="dense"
            maxLength={40}
            fullWidth
            value={email}
            placeholder="Insira seu e-mail"
            onChange={(e) => setEmail(e.target.value)}
            style={{
              marginInline: 20,
              width: "80%",
              background: "#fff",
              border: "3px solid #D9D9D9",
              borderRadius: "5px",
            }}
            sx={{
              color: "#D9D9D9",
              ".css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                color: "#D9D9D9",
              },
              input: { color: "#000" },
              ".css-1jy569b-MuiFormLabel-root-MuiInputLabel-root": {
                fontSize: "25px",
                color: "#1976d2",
              },
              ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                fontSize: "25px",
              },
            }}
            InputLabelProps={{
              style: { fontWeight: "bolder" },
            }}
          />

          <TextField
            variant="outlined"
            label="senha"
            fullWidth
            margin="dense"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Insira sua senha"
            type={showPassword ? "text" : "password"}
            style={{
              marginInline: 20,
              width: "80%",
              background: "#fff",
              border: "3px solid #D9D9D9",
              borderRadius: "5px",
            }}
            sx={{
              color: "#D9D9D9",
              ".css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                color: "#D9D9D9",
              },
              input: { color: "#000" },
              ".css-1jy569b-MuiFormLabel-root-MuiInputLabel-root": {
                fontSize: "25px",
                color: "#1976d2",
              },
              ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                fontSize: "25px",
              },
            }}
            InputLabelProps={{
              style: { fontWeight: "bolder" },
            }}
          />

          <Button
            variant="contained"
            onClick={handleLogin}
            fullWidth
            style={{
              marginInline: 20,
              marginTop: 5,
              width: "80%",
              backgroundColor: "rgb(25, 214, 195)",
            }}
          >
            Entrar
          </Button>
          <div
            className="divConta"
            style={{ width: "80%" }}
          >
            <p className="title11">
              <a href="/update" style={{ color: "#fff", textDecoration: "none" }}>
                Esqueci minha senha
              </a>
            </p>
          </div>
          <div className="divConta">
            <p className="title11">
              Ainda não possui conta?{" "}
              <a href="/register" style={{ color: "#fff" }}>
                Cadastre-se aqui
              </a>
            </p>
          </div>
          <Typography
            style={{
              fontWeight: "bold",
              marginTop: 20,
              textAlign: "center",
            }}
          >
            <IconButton
              aria-label="whatsapp"
              color="success"
              onClick={() => {
                // Adicione a lógica para abrir o link do WhatsApp aqui
                window.open("https://wa.me/seu-numero-de-whatsapp", "_blank");
              }}
            >
              <WhatsAppIcon />
            </IconButton>
            (11) 9.3079-4372
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              padding: 20,
              paddingInline: 80,
            }}
          >
            <Typography>www.abrafesc.com.br</Typography>
          </div>
        </div>
        <Typography
          variant="body2"
          style={{ fontSize: "12px", textAlign: "end" }}
        >
          v 2.4
        </Typography>
      </div>
      <div>
        <img
          src={logo}
          alt="C2Quick Logo"
          style={{
            width: "85svh",
            height: "20svh",
            paddingBottom: 80,
          }}
        />
      </div>
    </div>
  );
};

export default Login;
