import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { store } from '../store';

let autenticado = false;

export function isAutenticado() {
    return autenticado;
}

export function setAutenticado(aut) {
    console.log('setAutenticado:', aut);
    autenticado = aut;
}

const api = axios.create({
    baseURL: 'https://onboarding-api.sandbox.cloud.laqus.com.br',
});

api.interceptors.request.use(
    async (config) => {
        console.log('Iniciando a interceptação de uma requisição...');

        if (!autenticado) {
            console.log('Usuário não autenticado. Obtendo nova apiKey e sessionId...');

            // Obter apiKey
            const apiKeyResponse = await axios.post('https://auth-api.sandbox.cloud.laqus.io/v1/identities');
            const apiKey = apiKeyResponse.data.apiKey;
            console.log('apiKey obtida:', apiKey);

            // Obter sessionId
            const sessionResponse = await axios.post('https://auth-api.sandbox.cloud.laqus.io/v1/sessions', { apiKey: apiKey });
            const sessionId = sessionResponse.data.sessionId;
            console.log('sessionId obtida:', sessionId);

            // Configurar cabeçalhos para as próximas requisições
            config.headers['x-api-key'] = apiKey;
            config.headers['x-session-token'] = sessionId;
            config.auth = null;

            // Definir como autenticado
            setAutenticado(true);
            console.log(config.headers);
            console.log('Usuário autenticado com sucesso.');
        } else {
            console.log('Usuário já está autenticado.');
        }

        return config;
    },
    (error) => {
        console.error('Erro na interceptação da requisição:', error);
        return Promise.reject(error);
    },
);

api.interceptors.response.use(
    function (response) {
        console.log('Interceptando resposta...', response);

        if (!response.data.sucesso) {
            if (response.data.autenticado === false && autenticado) {
                autenticado = false;
                console.log('Autenticação falhou. Deletando token do usuário...');
                deletarTokenUsuario();
            }
        }
        return response;
    },
    function (error) {
        console.error('Erro na interceptação da resposta:', error);
        return Promise.reject(error);
    },
);

function deletarTokenUsuario() {
    console.log('Deletando token do usuário...');
    // Implementar a lógica para deletar o token do usuário
}

export { api };
