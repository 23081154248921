import {
  Box,
  Container,
  TextField,
  Button,
  Typography,
  Alert,
  Snackbar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import SideHeader from "./Header";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export const theme = createTheme({
  typography: {
    fontSize: 16,
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
});
const MainCalc = () => {
  const [formData, setFormData] = useState({
    total: "",
    taxa: "",
    parcelas: "",
    carencia: "",
    vencimentoDia: "",
    vencimentoMes: "",
    vencimentoAno: "",
    taxaEmissao: "750",
  });

  const navigation = useNavigate();

  useEffect(() => {
    // Carregar os dados salvos no localStorage
    const savedData = localStorage.getItem("formData");
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      // Verifique se todos os campos esperados estão presentes e se são do tipo correto
      const loadedData = {
        total: parsedData.total || "",
        taxa: parsedData.taxa || "",
        parcelas: parsedData.parcelas || "",
        carencia: parsedData.carencia || "",
        vencimentoDia: parsedData.vencimentoDia || "",
        vencimentoMes: parsedData.vencimentoMes || "",
        vencimentoAno: parsedData.vencimentoAno || "",
        taxaEmissao: "750" || "750",
      };
      setFormData(loadedData);
    }
  }, []);

  const handleChange = (e) => {
    const fieldName = e.target.name;
    let updatedValue = e.target.value;

    // Se o campo for "parcelas", limita o valor a 6
    if (fieldName === "parcelas") {
      updatedValue = Math.min(updatedValue, 6);
    }
    if (fieldName === "carencia") {
      updatedValue = Math.min(updatedValue, 5);
    }

    let updatedFormData = { ...formData, [fieldName]: updatedValue };
    setFormData(updatedFormData);
    localStorage.setItem("formData", JSON.stringify(updatedFormData));
  };
  const handleSubmit = async () => {
    const {
      parcelas,
      taxa,
      taxaEmissao,
      total,
      vencimentoAno,
      vencimentoDia,
      vencimentoMes,
    } = formData;
    if (
      Object.values({
        parcelas,
        taxa,
        taxaEmissao,
        total,
        vencimentoAno,
        vencimentoDia,
        vencimentoMes,
      }).some((value) => value === "" || !value)
    ) {
      return alert("Todos os dados obrigatórios devem ser preenchidos!");
    }

    console.log(formData);
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/c2quick/",
        formData
      );
      console.log(response.data);
      localStorage.setItem(
        "calcData",
        JSON.stringify(response.data.simulationRes.html),
        localStorage.setItem(
          "allInfoArray",
          JSON.stringify([
            ...Object.values(formData),
            formData.total + response.data.simulationRes.totalJuros,
          ])
        )
      );
      <Alert severity="success">This is a success alert — check it out!</Alert>;
      navigation("/resultado");
    } catch (error) {
      console.error("Erro na requisição:", error);
    }
  };

  const savedVencimento =
    formData.vencimentoAno +
    "-" +
    formData.vencimentoMes +
    "-" +
    formData.vencimentoDia;

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <SideHeader selected="calculo" />
      <ThemeProvider theme={theme}>
        <Container
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            backgroundColor: "#fff",
            borderRadius: "15px",
            paddingY: "60px",
            marginY: "20px",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              m: 1,
              width: "100%",
              textAlign: "center",
              color: "#1b2d66",
              fontWeight: "600",
            }}
          >
            Insira os dados para Simulação
          </Typography>
          <NumericFormat
            required
            value={formData.total}
            name="total"
            label="Valor da Operação"
            customInput={TextField}
            thousandSeparator="."
            decimalSeparator=","
            prefix="R$ "
            onValueChange={(values) => {
              const { floatValue } = values;
              if (floatValue <= 100000) {
                let updatedFormData = { ...formData, total: floatValue };
                setFormData({ ...formData, total: floatValue });
                localStorage.setItem(
                  "formData",
                  JSON.stringify(updatedFormData)
                );
              } else {
                window.alert("O valor deve ser menor ou igual a 100.000,00");
                let updatedFormData = { ...formData, total: 100000 };
                setFormData({ ...formData, total: 100000 });
                localStorage.setItem(
                  "formData",
                  JSON.stringify(updatedFormData)
                );
              }
            }}
            variant="outlined"
            sx={{ m: 1, width: "45%" }}
            style={{
              background: "#D9D9D9",
              borderRadius: "5px",
            }}
          />

          <NumericFormat
            required
            value={formData.taxa}
            name="taxa"
            label="Taxa de Juros"
            customInput={TextField}
            isNumericString
            decimalSeparator=","
            fixedDecimalScale={true}
            decimalScale={2}
            suffix="%"
            onValueChange={(values) => {
              const { value } = values;
              let updatedFormData = { ...formData, taxa: value };
              setFormData({ ...formData, taxa: value });
              localStorage.setItem("formData", JSON.stringify(updatedFormData));
            }}
            variant="outlined"
            sx={{ m: 1, width: "45%" }}
            style={{
              background: "#D9D9D9",
              borderRadius: "5px",
            }}
          />

          <TextField
            required
            name="parcelas"
            value={formData.parcelas}
            label="Quantidade de Parcelas"
            variant="outlined"
            type="number"
            onChange={handleChange}
            sx={{ m: 1, width: "45%" }}
            inputProps={{ max: 6 }}
            style={{
              background: "#D9D9D9",
              borderRadius: "5px",
            }}
          />
          <TextField
            name="carencia"
            label="Carência"
            variant="outlined"
            type="number"
            value={formData.carencia}
            onChange={handleChange}
            sx={{ m: 1, width: "45%" }}
            style={{
              background: "#D9D9D9",
              borderRadius: "5px",
            }}
          />

          <NumericFormat
            required
            value={formData.taxaEmissao}
            name="taxaEmissao"
            label="Taxa De Emissão"
            disabled
            customInput={TextField}
            thousandSeparator="."
            decimalSeparator=","
            prefix="R$ "
            onValueChange={(values) => {
              const { floatValue } = values;
              let updatedFormData = { ...formData, taxaEmissao: floatValue };
              setFormData({ ...formData, taxaEmissao: floatValue });
              localStorage.setItem("formData", JSON.stringify(updatedFormData));
            }}
            variant="outlined"
            sx={{ m: 1, width: "45%" }}
            style={{
              background: "#D9D9D9",
              borderRadius: "5px",
            }}
          />
          <TextField
            required
            id="date"
            label="Vencimento da Primeira Parcela"
            variant="outlined"
            type="date"
            sx={{ m: 1, width: "45%" }}
            value={savedVencimento ? savedVencimento : ""}
            onChange={(e) => {
              const date = new Date(e.target.value);

              let updatedFormData = {
                ...formData,
                vencimentoDia:
                  date.getDate() + 1 < 10
                    ? `0${date.getDate() + 1}`
                    : date.getDate() + 1,
                vencimentoMes:
                  date.getMonth() + 1 < 10
                    ? `0${date.getMonth() + 1}`
                    : date.getMonth() + 1,
                vencimentoAno: date.getFullYear(),
              };
              setFormData({
                ...formData,
                vencimentoDia:
                  date.getDate() + 1 < 10
                    ? `0${date.getDate() + 1}`
                    : date.getDate() + 1,
                vencimentoMes:
                  date.getMonth() + 1 < 10
                    ? `0${date.getMonth() + 1}`
                    : date.getMonth() + 1,
                vencimentoAno: date.getFullYear(),
              });

              localStorage.setItem("formData", JSON.stringify(updatedFormData));
            }}
            InputLabelProps={{ shrink: true }}
            style={{
              background: "#D9D9D9",
              borderRadius: "5px",
            }}
          />
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
              m: 1,
              width: "45%",
              height: "57px",
              backgroundColor: "#1b2d66",
              fontWeight: "bolder",
              fontSize: "2rem",
            }}
          >
            Calcular
          </Button>
        </Container>
      </ThemeProvider>
    </Box>
  );
};

export default MainCalc;
