import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FaPlus, FaTrashCan } from "react-icons/fa6";
import { GrDocumentUpdate } from "react-icons/gr";
import EmissorModal from "../Modals/UpdateEmissor";
import { IoIosCloudUpload } from "react-icons/io";
import { BsArrowLeft, BsEye } from "react-icons/bs";
import DetailsEmissor from "../Modals/EmissorModal";
import { FaEdit } from "react-icons/fa";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Close, Delete, Edit, VisibilityOutlined } from "@mui/icons-material";
import { red } from "@mui/material/colors";
import { formatCnpj } from "../utils/tools";
const Emissors = () => {
  const [emissores, setEmissores] = useState([]);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null); // Estado para controlar o item selecionado
  const [selectedEmissor, setSelectedEmissor] = useState(null); // Estado para armazenar os detalhes do emissor selecionado
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [img, setImg] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const [documentFields, setDocumentFields] = useState([]);
  const [modalEmissor, setModalEmissor] = useState(false);
  const [modalEmissorIsOpen, setModalEmissorIsOpen] = useState(false);
  const [detailsEmissor, setDetailsEmissor] = useState(false);

  const url = process.env.REACT_APP_BASE_URL;
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const totalPages = Math.ceil(emissores.length / itemsPerPage);
  const goToNextPage = () =>
    setCurrentPage((page) => Math.min(page + 1, totalPages));
  const goToPreviousPage = () =>
    setCurrentPage((page) => Math.max(page - 1, 1));
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = emissores.slice(startIndex, endIndex);
  const toggleModal = () => {
    setModalEmissor(!modalEmissor);
  };
  const openEmissorModal = (emissor) => {
    console.log(emissor);
    setSelectedEmissor(emissor);
    setModalEmissorIsOpen(true);
  };

  const closeEmissorModal = () => {
    setModalEmissorIsOpen(false);
  };

  const user = JSON.parse(localStorage.getItem("user"));
  const userRef = user.data.email;

  const headers = {
    "ngrok-skip-browser-warning": "69420",
  };
  useEffect(() => {
    axios
      .get(`${url}/emissor?userRef=${user.data.email}`, {
        headers,
      })
      .then((response) => {
        setEmissores(response.data);
      })
      .catch((error) => {
        console.error("Erro ao fazer a solicitação:", error);
      });
  }, []);

  const removeLastDocumentField = () => {
    const updatedFields = [...documentFields];
    updatedFields.pop(); // Remove o último elemento do array
    setDocumentFields(updatedFields);
  };

  useEffect(() => {
    if (selectedItemIndex !== null) {
      const selectedEmissorData = emissores[selectedItemIndex];
      setSelectedEmissor(selectedEmissorData);
    } else {
      setSelectedEmissor(null);
    }
  }, [selectedItemIndex, emissores]);

  const handleDeleteInfos = (index) => {
    setSelectedItemIndex(index);
    setShowConfirmation(true);
  };

  const toggleDetails = (emissor) => {
    setSelectedEmissor(emissor);
    setDetailsEmissor(true);
  };

  const handleDelete = (index) => {
    if (index !== null) {
      const emissorId = index._id;
      console.log(emissorId);
      axios
        .delete(`${url}/emissor?id=${emissorId}`, headers)
        .then((response) => {
          if (response.status === 204) {
            setDeleteSuccess(true);
            setTimeout(() => {
              setDeleteSuccess(false);
            }, 3000);
            setSelectedItemIndex(null); // Limpa a seleção após a exclusão
            setShowConfirmation(false);

            // Após a exclusão bem-sucedida, obtenha a lista atualizada
            axios
              .get(`${url}/emissor?userRef=${user.data.email}`, headers)
              .then((response) => {
                const updatedEmissores = response.data;
                setEmissores(
                  updatedEmissores.length > 0 ? updatedEmissores : []
                ); // Verifica se a lista ficará vazia e ajusta emissores
              })
              .catch((error) => {
                console.error("Erro ao fazer a solicitação:", error);
              });
          } else {
            setDeleteError(true);
            setTimeout(() => {
              setDeleteError(false);
            }, 3000);
          }
        })
        .catch((error) => {
          console.error("Erro ao fazer a solicitação:", error);
        });
    }
  };
  const handleDeleteConfirmation = (index) => {
    setSelectedItemIndex(index);
    setShowConfirmation(true);
  };

  const navigate = useNavigate();
  const handleUseEmissor = () => {
    console.log(selectedEmissor);
    localStorage.setItem("emissorPreenchido", true);
    localStorage.setItem("emissor", JSON.stringify(selectedEmissor));
    window.alert("Emissor Selecionado!");
    navigate("/minuta");
  };

  const getCurrentEmissores = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return emissores.slice(startIndex, endIndex);
  };

  // Função para lidar com a mudança de página
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  return (
    <Container>
      <Container>
        {emissores.length > 0 ? (
          <Container>
            <h3 className="confirmationMessage1">
              Selecione um Emissor para sua Nota Comercial
            </h3>

            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                style={{
                  backgroundColor: "rgb(213, 213, 213)",
                  borderRadius: "10px 10px 10px 10px",
                }}
              >
                <TableHead>
                  <TableRow style={{ backgroundColor: "#1b2d66" }}>
                    <TableCell
                      style={{
                        color: "white",
                        borderRight: "2px solid white",
                        borderBottom: "2px solid white",
                        textAlign: "center",
                      }}
                    >
                      Razão Social
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        borderRight: "2px solid white",
                        borderBottom: "2px solid white",
                        textAlign: "center",
                      }}
                      align="right"
                    >
                      CNPJ
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        borderRight: "2px solid white",
                        borderBottom: "2px solid white",
                        textAlign: "center",
                      }}
                      align="right"
                    >
                      Representante
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        borderRight: "2px solid white",
                        borderBottom: "2px solid white",
                        textAlign: "center",
                      }}
                      align="right"
                    >
                      E-mail
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        borderRight: "2px solid white",
                        borderBottom: "2px solid white",
                        textAlign: "center",
                      }}
                      align="right"
                    >
                      Função
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        borderRight: "2px solid white",
                        borderBottom: "2px solid white",
                        textAlign: "center",
                      }}
                      align="right"
                    >
                      Ações
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentItems.map((emissor, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        style={{
                          borderRight: "2px solid white",
                          borderBottom: "2px solid white",
                          textAlign: "center",
                        }}
                      >
                        {emissor.razaoSocial.length > 20
                          ? `${emissor.razaoSocial.substring(0, 20)}...`
                          : emissor.razaoSocial}
                      </TableCell>
                      <TableCell
                        style={{
                          borderRight: "2px solid white",
                          borderBottom: "2px solid white",
                          textAlign: "center",
                        }}
                        align="right"
                      >
                        {formatCnpj(emissor.cnpj)}
                      </TableCell>
                      <TableCell
                        style={{
                          borderRight: "2px solid white",
                          borderBottom: "2px solid white",
                          textAlign: "center",
                        }}
                        align="right"
                      >
                        {emissor.representante.length > 20
                          ? `${emissor.representante.substring(0, 20)}...`
                          : emissor.representante}
                      </TableCell>
                      <TableCell
                        style={{
                          borderRight: "2px solid white",
                          borderBottom: "2px solid white",
                          textAlign: "center",
                        }}
                        align="right"
                      >
                        {emissor.email}
                      </TableCell>
                      <TableCell
                        style={{
                          borderRight: "2px solid white",
                          borderBottom: "2px solid white",
                          textAlign: "center",
                        }}
                        align="right"
                      >
                        {emissor.cargo}
                      </TableCell>
                      <TableCell
                        style={{
                          borderRight: "2px solid white",
                          borderBottom: "2px solid white",
                          textAlign: "center",
                        }}
                        align="center"
                      >
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <Tooltip title="Visualizar">
                            <IconButton
                              onClick={() => toggleDetails(emissor)}
                              color="primary"
                            >
                              <VisibilityOutlined />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Excluir">
                            <IconButton
                              onClick={() => {
                                setShowConfirmation(true);
                                setSelectedEmissor(emissor);
                                console.log(emissor);
                                handleDeleteConfirmation(index);
                              }}
                              sx={{ color: red[500] }}
                            >
                              <Delete />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Editar">
                            <IconButton
                              onClick={() => openEmissorModal(emissor)}
                              color="secondary"
                            >
                              <Edit />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <Dialog
                          open={showConfirmation}
                          onClose={() => setShowConfirmation(false)}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                            {"Confirmar Exclusão"}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              Tem certeza de que deseja excluir este investidor?
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={() => setShowConfirmation(false)}
                              color="primary"
                            >
                              Cancelar
                            </Button>
                            <Button
                              onClick={() => handleDelete(selectedEmissor)}
                              color="primary"
                              autoFocus
                            >
                              Confirmar
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        ) : (
          <div className="investidorDiv1">Nenhum emissor encontrado</div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Button onClick={goToPreviousPage} disabled={currentPage === 1}>
            Anterior
          </Button>
          <span>
            Página {currentPage} de {totalPages}
          </span>
          <Button onClick={goToNextPage} disabled={currentPage === totalPages}>
            Próxima
          </Button>
        </div>
        {deleteSuccess && (
          <p className="successMessage">Emissor Excluído com sucesso!</p>
        )}
        {deleteError && (
          <p className="errorMessage">Falha ao Excluir emissor.</p>
        )}
      </Container>
      {detailsEmissor && (
        <DetailsEmissor
          selectedEmissor={selectedEmissor}
          handleSave={handleUseEmissor}
          onClose={() => setDetailsEmissor(false)}
        />
      )}
      {modalEmissorIsOpen && (
        <EmissorModal
          isOpen={modalEmissorIsOpen}
          onClose={closeEmissorModal}
          emissorData={selectedEmissor}
        />
      )}
    </Container>
  );
};

export default Emissors;
