export const formatCpf = (text) => {
  if (typeof text === "string") {
    const cpf = text.replace(/\D/g, "");
    let formattedCpf = "";

    for (let i = 0; i < cpf.length; i++) {
      if (i === 3 || i === 6) {
        formattedCpf += ".";
      } else if (i === 9) {
        formattedCpf += "-";
      }
      formattedCpf += cpf[i];
    }

    return formattedCpf;
  }
};

export const formatCnpj = (text) => {
  if (text) {
    const cnpj = text
      ?.replace(/[^0-9a-zA-Z]/g, "")
      .toUpperCase()
      .replace(/\D/g, "")
      .match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
    const formattedCnpj = !cnpj[2]
      ? cnpj[1]
      : cnpj[1] +
        "." +
        cnpj[2] +
        "." +
        cnpj[3] +
        "/" +
        cnpj[4] +
        (cnpj[5] ? "-" + cnpj[5] : "");
    return formattedCnpj;
  }
};

export const formatFileSize = (bytes) => {
  const kb = bytes / 1024;
  if (kb < 1024) {
    return kb.toFixed(2) + " KB";
  } else {
    const mb = kb / 1024;
    return mb.toFixed(2) + " MB";
  }
};

export const isValidEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

export const isValidCPF = (cpf) => {
  // Remove caracteres não numéricos
  cpf = cpf.replace(/[^\d]/g, "");

  // Verifica se o CPF tem 11 dígitos
  if (cpf.length !== 11) {
    return false;
  }

  // Verifica se todos os dígitos são iguais
  if (/^(\d)\1{10}$/.test(cpf)) {
    return false;
  }

  // Calcula o primeiro dígito verificador
  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i)) * (10 - i);
  }
  let remainder = 11 - (sum % 11);
  let firstDigit = remainder === 10 || remainder === 11 ? 0 : remainder;

  // Calcula o segundo dígito verificador
  sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i)) * (11 - i);
  }
  remainder = 11 - (sum % 11);
  let secondDigit = remainder === 10 || remainder === 11 ? 0 : remainder;

  // Verifica se os dígitos verificadores estão corretos
  if (
    parseInt(cpf.charAt(9)) !== firstDigit ||
    parseInt(cpf.charAt(10)) !== secondDigit
  ) {
    return false;
  }

  return true;
};

export const formatRg = (text) => {
  if (text) {
    const rg = text.replace(/\D/g, "");
    let formattedRg = "";

    for (let i = 0; i < rg.length; i++) {
      if (i === 1 || i === 4 || i === 7) {
        formattedRg += ".";
      }
      formattedRg += rg[i];
    }

    return formattedRg;
  }
};

export const formatDocument = (text) => {
  if (text) {
    const cleanedText = text.replace(/\D/g, ""); // Remove non-numeric characters
    let formattedText = "";

    if (cleanedText.length <= 11) {
      // Format as CPF
      for (let i = 0; i < cleanedText.length; i++) {
        if (i === 3 || i === 6) {
          formattedText += ".";
        } else if (i === 9) {
          formattedText += "-";
        }
        formattedText += cleanedText[i];
      }
    } else {
      // Format as CNPJ
      for (let i = 0; i < cleanedText.length; i++) {
        if (i === 2 || i === 5) {
          formattedText += ".";
        } else if (i === 8) {
          formattedText += "/";
        } else if (i === 12) {
          formattedText += "-";
        }
        formattedText += cleanedText[i];
      }
    }

    return formattedText;
  }
  return text; // Return the input text if it's empty
};

export const formatDate = (inputDate) => {
  const months = [
    "janeiro",
    "fevereiro",
    "março",
    "abril",
    "maio",
    "junho",
    "julho",
    "agosto",
    "setembro",
    "outubro",
    "novembro",
    "dezembro",
  ];

  const year = inputDate.getFullYear();
  const month = months[inputDate.getMonth()];
  const day = inputDate.getDate();

  return `${day} de ${month} de ${year}`;
};

export function formatDate2(dateString) {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();

  return `${day}/${month}/${year}`;
}

export function parseDateString(str) {
  const meses = [
    "janeiro",
    "fevereiro",
    "março",
    "abril",
    "maio",
    "junho",
    "julho",
    "agosto",
    "setembro",
    "outubro",
    "novembro",
    "dezembro",
  ];

  const partes = str.split(" ");

  if (partes.length !== 5) {
    throw new Error("Formato de data inválido");
  }

  const dia = parseInt(partes[0], 10);
  const mes = meses.indexOf(partes[2]) + 1;
  const ano = parseInt(partes[4], 10);

  if (isNaN(dia) || isNaN(mes) || isNaN(ano)) {
    throw new Error("Formato de data inválido");
  }

  return new Date(ano, mes - 1, dia);
}

export function parseDate(str) {
  const parts = str.split("/");
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const year = parseInt(parts[2], 10);
  return new Date(year, month - 1, day); // Mês é base 0, subtrai 1
}

export function convertToNumber(currencyString) {
  const numberString = currencyString
    .replace("R$", "")
    .replace(/\./g, "")
    .replace(",", ".");

  return parseFloat(numberString);
}

export function apenasNumeros(cnpj) {
  return parseInt(cnpj.replace(/\D/g, ""));
}

export function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  var byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var arrayBuffer = new ArrayBuffer(byteString.length);
  var _ia = new Uint8Array(arrayBuffer);
  for (var i = 0; i < byteString.length; i++) {
    _ia[i] = byteString.charCodeAt(i);
  }

  var dataView = new DataView(arrayBuffer);
  var blob = new Blob([dataView], { type: mimeString });
  return blob;
}
