import React from "react";
import { GrDocumentUpdate } from "react-icons/gr";
import { FaFolderClosed } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { cadastrarEmissorRequest } from "../redux/actions";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import { formatCEP } from "./UpdateInvestor";
import { formatCnpj } from "../utils/tools";
import { IoIosDownload } from "react-icons/io";

const DetailsEmissor = ({ selectedEmissor, handleSave, onClose }) => {
  const dispatch = useDispatch();

  const handleTeste = () => {
    const emissorLaqus = {
      cnpj: selectedEmissor.cnpj,
      razaoSocial: selectedEmissor.razaoSocial,
      complemento: selectedEmissor.complemento,
      cep: selectedEmissor.cep,
      logradouro: selectedEmissor.rua,
      numero: selectedEmissor.numero,
      bairro: selectedEmissor.bairro,
      cidade: selectedEmissor.cidade,
      uf: selectedEmissor.estado,
      atividadePrincipal: selectedEmissor.cargo,
      faturamentoMedioMensal12Meses: "2",
      razaoSocialBancoLiquidante: selectedEmissor.banco,
      ispbBancoLiquidante: "0",
      agenciaBancoLiquidante: selectedEmissor.agency,
      contaBancoLiquidante: selectedEmissor.conta,
      telefones: ["00000000000"],
    };
    console.log(emissorLaqus, "enviado pro teste em sandbox");
    dispatch(cadastrarEmissorRequest(emissorLaqus));
  };
  return (
    <Dialog open={!!selectedEmissor} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Detalhes do Emissor Selecionado
        <Button
          onClick={onClose}
          style={{ position: "absolute", right: 0, top: 0 }}
        >
          X
        </Button>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Razão Social:</Typography>
            <Typography variant="body1">
              {selectedEmissor?.razaoSocial}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">CNPJ:</Typography>
            <Typography variant="body1">
              {formatCnpj(selectedEmissor?.cnpj)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Representante:</Typography>
            <Typography variant="body1">
              {selectedEmissor?.representante}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Endereço:</Typography>
            <Box>
              <Typography variant="body1" component="span">
                CEP: {formatCEP(selectedEmissor?.cep)}
                <br />
              </Typography>
              <Typography variant="body1" component="span">
                Rua: {selectedEmissor?.rua}
                <br />
              </Typography>
              <Typography variant="body1" component="span">
                Bairro: {selectedEmissor?.bairro}
                <br />
              </Typography>
              <Typography variant="body1" component="span">
                Cidade: {selectedEmissor?.cidade}
                <br />
              </Typography>
              <Typography variant="body1" component="span">
                UF: {selectedEmissor?.estado}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Email:</Typography>
            <Typography variant="body1">{selectedEmissor?.email}</Typography>
          </Grid>
          <Grid item xs={12}>
            <div className="investidorField">
              <a
                href={
                  selectedEmissor.faturamento
                    ? selectedEmissor.faturamento
                    : "#"
                }
                className={`customFileInput2 ${
                  selectedEmissor.faturamento ? "" : "opacoButton"
                }`}
                download={selectedEmissor.faturamento ? "CNH" : ""}
              >
                <IoIosDownload
                  style={{ marginLeft: "20px" }}
                  className="downloadIcon"
                />
                <span className="downloadText">
                  {`Baixar Relatório de Faturamento`}{" "}
                </span>
              </a>
            </div>
            <div className="investidorField">
              <a
                href={selectedEmissor.social ? selectedEmissor.social : "#"}
                className={`customFileInput2 ${
                  selectedEmissor.social ? "" : "opacoButton"
                }`}
                download={selectedEmissor.social ? "Social" : ""}
              >
                <IoIosDownload
                  style={{ marginLeft: "20px" }}
                  className="downloadIcon"
                />
                <span className="downloadTeYxt">
                  {`Baixar Contrato Social`}{" "}
                </span>
              </a>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Avalistas:</Typography>
            <Box>
              {selectedEmissor?.avalistas.map((avalista, index) => (
                <>
                  <h3 className="per1">Avalista {index + 1}</h3>
                  <div className="investidorField">
                    <div className="invesP">Nome: {avalista.aNome}</div>
                  </div>
                  <div className="investidorField">
                    <a
                      href={avalista.aCNH ? avalista.aCNH : "#"}
                      className={`customFileInput2 ${
                        avalista.aCNH ? "" : "opacoButton"
                      }`}
                      download={avalista.aCNH ? "CNH" : ""}
                    >
                      <IoIosDownload
                        style={{ marginLeft: "20px" }}
                        className="downloadIcon"
                      />
                      <span className="downloadText">
                        {`Baixar Carteira Nacional de Habilitação`}{" "}
                      </span>
                    </a>
                  </div>
                  <div className="investidorField">
                    <a
                      href={avalista.aCasamento ? avalista.aResidencia : "#"}
                      className={`customFileInput2 ${
                        avalista.aResidencia ? "" : "opacoButton"
                      }`}
                      download={avalista.aResidencia ? "Residencia" : ""}
                    >
                      <IoIosDownload
                        style={{ marginLeft: "20px" }}
                        className="downloadIcon"
                      />
                      <span className="downloadText">
                        {`Baixar Comprovante de Residência`}{" "}
                      </span>
                    </a>
                  </div>
                  <div className="investidorField">
                    <a
                      href={avalista.aCasamento ? avalista.aCasamento : "#"}
                      className={`customFileInput2 ${
                        avalista.aCasamento ? "" : "opacoButton"
                      }`}
                      download={avalista.aCasamento ? "Casamento" : ""}
                    >
                      <IoIosDownload
                        style={{ marginLeft: "20px" }}
                        className="downloadIcon"
                      />
                      <span className="downloadText">
                        {`Baixar Certidão de Casamento`}{" "}
                      </span>
                    </a>
                  </div>
                </>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <h3 className="per1">Fiel Depositário</h3>
            <div className="investidorContainer">
              <div className="investidorField">
                <div className="invesP">
                  Nome: {selectedEmissor?.fielDepositario.nome}
                </div>
              </div>
              <div className="investidorField">
                <div className="invesP">
                  CPF: {selectedEmissor?.fielDepositario.fCpf}
                </div>
              </div>
              <div className="investidorField">
                <a
                  href={
                    selectedEmissor.fielDepositario.fCNH
                      ? selectedEmissor.fielDepositario.fCNH
                      : "#"
                  }
                  className={`customFileInput2 ${
                    selectedEmissor.fielDepositario.fCNH ? "" : "opacoButton"
                  }`}
                  download={selectedEmissor.fielDepositario.fCNH ? "CNH" : ""}
                >
                  <IoIosDownload
                    style={{ marginLeft: "20px" }}
                    className="downloadIcon"
                  />
                  <span className="downloadText">
                    {`Baixar Carteira Nacional de Habilitação`}{" "}
                  </span>
                </a>
              </div>
              <div className="investidorField">
                <a
                  href={
                    selectedEmissor.fielDepositario.fResidencia
                      ? selectedEmissor.fielDepositario.fResidencia
                      : "#"
                  }
                  className={`customFileInput2 ${
                    selectedEmissor.fielDepositario.fResidencia
                      ? ""
                      : "opacoButton"
                  }`}
                  download={
                    selectedEmissor.fielDepositario.fResidencia
                      ? "Residencica"
                      : ""
                  }
                >
                  <IoIosDownload
                    style={{ marginLeft: "20px" }}
                    className="downloadIcon"
                  />
                  <span className="downloadText">
                    {`Baixar Comprovante de Residência`}{" "}
                  </span>
                </a>
              </div>
              <div className="investidorField">
                <a
                  href={
                    selectedEmissor.fielDepositario.fCasamento
                      ? selectedEmissor.fielDepositario.fCasamento
                      : "#"
                  }
                  className={`customFileInput2 ${
                    selectedEmissor.fielDepositario.fCasamento
                      ? ""
                      : "opacoButton"
                  }`}
                  download={
                    selectedEmissor.fielDepositario.fCasamento ? "Certidao" : ""
                  }
                >
                  <IoIosDownload
                    style={{ marginLeft: "20px" }}
                    className="downloadIcon"
                  />
                  <span className="downloadText">
                    {`Baixar Certidão de Casamento`}{" "}
                  </span>
                </a>
              </div>
              <div className="investidorField"></div>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <Box textAlign="center" mt={2}>
        <Button variant="contained" onClick={handleTeste}>
          Cadastrar Emissor
        </Button>
      </Box>
      <Box textAlign="center" mt={2} mb={2}>
        <Button variant="contained" onClick={handleSave}>
          Selecionar Emissor
        </Button>
      </Box>
    </Dialog>
  );
};

export default DetailsEmissor;
