import React from 'react';
import { Box, Typography } from "@mui/material";
import SideHeader from './Header';

const Perfil = () => {
    // Informações do perfil
    return (
        <Box>
            <SideHeader selected="perfil" />
            <Typography variant="h5">Perfil do Usuário</Typography>
            {/* Detalhes do perfil */}
        </Box>
    );
}

export default Perfil;
